import { bidlClient } from 'src/api/bidl/client'

export const AGGREGATOR_CONFIG_BASE = 'api/aggregator/config'

export const configRequests = {
  getCommoditySubgroups: () =>
    bidlClient
      .post(`${AGGREGATOR_CONFIG_BASE}/v1/GetCommoditySubgroups`, { json: {} })
      .json<CommoditySubgroupResponse>(),

  findOrCreateTenantBySlug: () =>
    bidlClient
      .post(`${AGGREGATOR_CONFIG_BASE}/v1/FindOrCreateTenantBySlug`, { json: {} })
      .json<FindOrCreateTenantBySlugResponse>(),

  findOrCreateTenantConfigBySlug: () =>
    bidlClient
      .post(`${AGGREGATOR_CONFIG_BASE}/v1/FindOrCreateTenantConfigBySlug`, { json: {} })
      .json<FindOrCreateTenantConfigBySlugResponse>(),

  getAllStaffUsers: () =>
    bidlClient
      .post(`${AGGREGATOR_CONFIG_BASE}/v1/GetAllStaffUsers`, { json: {} })
      .json<
        AggregatorResponse<StaffUser[], 'api.aggregator.config.v1.response#GetAllStaffUsersSuccess'>
      >(),
}
