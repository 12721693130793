export enum Step {
  START,

  MISSING_DATA_FEATURE,
  MISSING_DATA_SUBMIT,

  INCORRECT_DATA_FEATURE,
  INCORRECT_DATA_SUBMIT,

  OTHER_ISSUE_SUBMIT,

  CANT_LOG_IN,

  SUBMIT_SUCCESS,
  SUBMIT_ERROR,
}
