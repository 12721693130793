import { useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import { isServer, logger } from 'src/utils'
import { ErrorState } from 'src/components/resource/NonIdealStates'

function Error({ resetError }) {
  useErrorReset({ resetError })

  logger.debug({
    message: 'src/_error - render',
    context: { resetError: !!resetError },
  })

  return <ErrorState />
}

Error.getInitialProps = (ctx) => {
  const { res, err } = ctx
  const statusCode = res ? res.statusCode : err ? err.statusCode : 500

  logger.debug({
    message: 'src/_error - gip',
    context: { statusCode, isServer: isServer(), err_message: err?.message },
  })

  // Only report error if we're on the server, client side errors are handled by ErrorBoundary
  if (isServer() && err) {
    logger.error({
      message: err?.message,
      error: err,
      context: {
        // TODO: What context info can we easily and reliably put here?
      },
    })
  }

  return { statusCode }
}

Error.requireAuth = false

export default Error

/**
 * The Rollbar ErrorBoundary has state for "is currently in error".
 * We can reset this by calling `resetError()`.
 * Reset the error when navigation occurs.
 */
function useErrorReset({ resetError }) {
  const router = useRouter()
  const isMountedRef = useRef(false)

  useEffect(() => {
    if (!isMountedRef.current) {
      // Do nothing on initial mount
      isMountedRef.current = true
      return
    }

    if (resetError) resetError()
  }, [resetError, router.route])
}
