import { createIcon } from '@chakra-ui/react'

export const PenIcon = createIcon({
  defaultProps: {
    'aria-hidden': true,
    fill: 'currentColor',
  },
  displayName: 'PenIcon',
  viewBox: '0 0 20 20',
  d: 'M15.07 3.12c.2 0 .39.08.53.22l1.06 1.06a.75.75 0 0 1 0 1.06l-13.02 13a.75.75 0 0 1-.19.13l-2.1 1.08a.75.75 0 0 1-1.02-1.01l1.08-2.11a.75.75 0 0 1 .14-.19L14.54 3.34a.75.75 0 0 1 .53-.22ZM17.19.69a1.5 1.5 0 0 1 2.12 0 1.5 1.5 0 0 1 0 2.12l-1.06 1.06a.75.75 0 0 1-1.06 0l-1.06-1.06a.75.75 0 0 1 0-1.06L17.19.69Z',
})
