import { useAuth } from 'src/auth'
import { Box, Button, Divider, Text } from 'src/components/designsystem'
import { NavigateToExternalLink } from 'src/components/utils/NavigateToExternalLink'
import { useConfig } from 'src/data/config'
import { ENV } from 'src/utils'
import getClient from 'src/utils/clients/get-client'
import { useNativeAppVersion } from 'src/components/utils/use-native-app-version'

export function AccountPlaceholder() {
  const { slug, handleNextAuthSignIn } = useAuth()
  const { isFeatureEnabled } = useConfig()
  const nativeAppVersion = useNativeAppVersion()

  return (
    <Box textAlign="center">
      {isFeatureEnabled('authentication') && (
        <>
          <Button variant="primary" size="md" my={6} onClick={() => handleNextAuthSignIn()}>
            Sign in
          </Button>
          <Divider />
        </>
      )}
      <Text fontSize="sm" py={4}>
        <NavigateToExternalLink
          href={`${ENV.CENTRE_API}/api/v1/legal/terms?app-company=${slug}`}
          mr={2.5}
          data-testid="terms-and-conditions-account-link"
        >
          Terms &amp; Conditions
        </NavigateToExternalLink>
        &bull;
        <NavigateToExternalLink
          href={`${ENV.CENTRE_API}/api/v1/legal/privacy/?app-company=${slug}`}
          ml={2.5}
          data-testid="privacy-policy-account-link"
        >
          Privacy Policy
        </NavigateToExternalLink>
        <br />
        {getClient().isNativeApp && nativeAppVersion}
      </Text>
    </Box>
  )
}
