import { getUserGroupId } from 'src/utils/push-notifications/group-id/user-group-id'
import { getAnonymousGroupId } from 'src/utils/push-notifications/group-id/anonymous-group-id'

export default async function getCurrentGroupId(
  authenticated: boolean
): Promise<string | undefined> {
  if (authenticated === true) {
    return getUserGroupId()
  } else {
    return getAnonymousGroupId()
  }
}
