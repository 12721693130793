import { Flex, Divider } from 'src/components/designsystem'
import { ReactNode } from 'react'
import { NotificationCellProps } from './NotificationCell'

interface NotificationCellContainerProps extends NotificationCellProps {
  children: ReactNode
}

export function NotificationCellContainer({
  notification,
  arr,
  idx,
  isBackToTopButtonShown,
  children,
}: Readonly<NotificationCellContainerProps>) {
  const { readAt } = notification

  return (
    <>
      <Flex
        data-testid="notification-cell"
        flexDirection="column"
        gap={1}
        p="0.5rem 1.5rem"
        width="100%"
        background={readAt === null ? 'gray.100' : 'white'}
        borderRadius={readAt === null ? 'lg' : 'none'}
      >
        {children}
      </Flex>
      {/* if it's the last element of array and the back to top button is shown, then hide the divider */}
      {arr.length - 1 === idx && isBackToTopButtonShown ? '' : <Divider color="gray.200" />}
    </>
  )
}
