import { Badge as ChakraBadge, BadgeProps as ChakraBadgeProps } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'

export function Badge({
  fontSize = 'sm',
  children,
  ...props
}: PropsWithChildren<ChakraBadgeProps>) {
  return (
    <ChakraBadge fontSize={fontSize} {...props}>
      {children}
    </ChakraBadge>
  )
}
