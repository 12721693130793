import { Collapse, FormErrorMessage } from '@chakra-ui/react'
//Switched over to importing from chakra directly instead of designsystem for now, FormErrorMessage causes errors from designsystem currently.

export default function AnimatedFormErrorMessage({ message }: Readonly<{ message?: string }>) {
  return (
    <Collapse in={!!message} animateOpacity>
      <FormErrorMessage>{message}</FormErrorMessage>
    </Collapse>
  )
}
