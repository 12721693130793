import React, { useMemo } from 'react'
import {
  ChakraModal,
  ChakraModalHeader,
  ModalBody,
  ModalContent,
  ModalFooter,
} from 'src/components/designsystem'
import { BackButtonBehavior } from 'src/components/resource/BackButtonBehavior'
import BackButton from 'src/components/shared/BackButton'
import getClient from 'src/utils/clients/get-client'

type FullScreenModalProps = {
  onBack: () => void
  header: React.ReactNode
  body: React.ReactNode
  footer: React.ReactNode
}

export function FullScreenModal({ onBack, header, body, footer }: Readonly<FullScreenModalProps>) {
  const isAndroid = getClient().isAndroid
  const backStackItems = useMemo(() => [{ callback: onBack }], [onBack])

  return (
    <>
      {isAndroid && <BackButtonBehavior backStackItems={backStackItems} />}

      <ChakraModal onClose={onBack} isOpen size="full" scrollBehavior="inside" motionPreset="none">
        <ModalContent>
          <ChakraModalHeader
            marginTop="env(safe-area-inset-top)"
            paddingX={6}
            paddingTop={4}
            paddingBottom={0}
          >
            <BackButton onClick={onBack} paddingX={0} paddingBottom={8} margin={0} />
            {header}
          </ChakraModalHeader>

          <ModalBody paddingX={6} paddingTop={0} paddingBottom={6}>
            {body}
          </ModalBody>

          <ModalFooter margin={0} paddingBottom={8} boxShadow="lg">
            {footer}
          </ModalFooter>
        </ModalContent>
      </ChakraModal>
    </>
  )
}
