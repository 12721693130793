import { useQuery } from '@tanstack/react-query'
import * as api from 'src/api'
import { UseQueryOptionsTyped, useQueryTyped } from './utils'

export function useUserSearch({
  enabled,
  query,
  userId,
}: {
  enabled: boolean
  query?: string
  userId?: string
}) {
  return useQuery<{ data: SearchUser[] }, api.HTTPError>({
    queryKey: ['user-search', { query, userId }, userId?.length],
    queryFn: async () => {
      // If userId is a email or phone number use old phone number / email queries
      if (userId?.includes('@') || (!Number.isNaN(Number(userId)) && userId?.length === 10)) {
        query = userId
        userId = null
      }
      const results = await api.centre.userSearch({ query, userId })
      return { data: mapGroupsToSearchUsers(groupResults(results.data)) }
    },
    enabled,
    staleTime: Infinity,
  })
}

export function useStaffUserSearch(
  { query },
  options: UseQueryOptionsTyped<{ data: SearchUser[] }> = {}
) {
  return useQueryTyped<{ data: SearchUser[] }, api.HTTPError>({
    queryKey: ['staff-user-search', { query }],
    queryFn: async () => {
      const results = await api.centre.userSearch({ query })
      return { data: mapGroupsToSearchUsers(groupResults(results.data)) }
    },
    staleTime: Infinity,
    ...options,
  })
}

export function useGetAccountsByUserId({ userId }) {
  return useQuery({
    queryKey: ['get-accounts-by-user-id', { userId }],
    queryFn: () => {
      return api.centre.getAccountsByUserId({ userId })
    },
    select: (data) => data.data,
    staleTime: Infinity,
  })
}

type GroupedUserAccounts = { [identifier: string]: SearchUserAccount[] }

function groupResults(results: SearchUserAccount[]): GroupedUserAccounts {
  return results.reduce((acc, user) => {
    if (!user.user_id) return acc
    if (!user.phone && !user.idp_id) return acc

    if (!acc[user.user_id]) {
      acc[user.user_id] = [user]
    } else {
      acc[user.user_id].push(user)
    }

    return acc
  }, {})
}

function mapGroupsToSearchUsers(groupedResults: GroupedUserAccounts): SearchUser[] {
  return Object.keys(groupedResults).map((userId) => {
    const allPhones = Array.from(
      new Set(
        groupedResults[userId].filter((account) => account.phone).map((account) => account.phone)
      )
    )

    return {
      phone: groupedResults[userId].find((result) => !!result.phone)?.phone,
      all_phones: allPhones,
      idp_id: groupedResults[userId].find((result) => !!result.idp_id)?.idp_id,
      all_idp_ids: Array.from(
        new Set(
          groupedResults[userId]
            .filter((account) => account.idp_id && !allPhones.includes(account.idp_id))
            .map((account) => account.idp_id)
        )
      ),
      id: groupedResults[userId].find((result) => !!result.id)?.id,
      accounts: groupedResults[userId],
      userId: Number(userId),
    }
  })
}
