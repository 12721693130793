import {
  As,
  forwardRef,
  ResponsiveValue,
  Text as ChakraText,
  TextProps as ChakraTextProps,
} from '@chakra-ui/react'

type TextStyles =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'body'
  | 'body-bold'
  | 'small'
  | 'small-bold'
  | 'extra-small'
  | 'extra-small-bold'
  | 'preTitle'
  | 'subtitle'
  | 'button'
  | 'link'
  | 'link-bold'

export interface TextProps extends ChakraTextProps {
  as?: As
  textStyle?: ResponsiveValue<TextStyles>
}

export const Text = forwardRef<TextProps, 'div'>(
  ({ as = 'div', textStyle = 'body', children, ...props }, ref) => (
    <ChakraText ref={ref} as={as} textStyle={textStyle} {...props}>
      {children}
    </ChakraText>
  )
)
