import { switchAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  switchAnatomy.keys
)

const baseStyle = definePartsStyle({
  container: {},
  thumb: {},
  track: {
    _checked: {
      bg: 'blue.400',
    },
  },
})

export const SwitchStyleConfig = defineMultiStyleConfig({ baseStyle })
