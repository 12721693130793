import { AlertCircleIcon, CollapsableDetailTable, Tooltip } from 'src/components/designsystem'
import DisplayConfig, { DisplayConfigItem } from 'src/components/designsystem/display-config'
import { DetailGrid, FormattedDate, useStackedDetailModal } from 'src/components/resource'
import { useSettlementAssociatedTickets } from 'src/data/queries'

const DISPLAY_CONFIG: DisplayConfigItem<SettlementAssociatedTicket>[] = [
  {
    label: 'Ticket',
    key: 'display_id',
    ValueCellComponent: DisplayConfig.BoldTextValue,
    getValue: () => true, // Don't want to ever em-dash this value
    renderValue: ({ data }) => {
      if (data.id) return data.display_id

      return (
        <>
          {data.display_id}
          <Tooltip label="Ticket unavailable" hasArrow placement="top">
            <AlertCircleIcon color="red.500" fontSize="lg" ml={1} mb={0.5} />
          </Tooltip>
        </>
      )
    },
  },
  {
    label: 'Application Date',
    key: 'application_date',
    ValueCellComponent: DisplayConfig.BoldTextValue,
    getValue: ({ data }) => (!data.id ? null : data.application_date),
    renderValue: ({ data }) => <FormattedDate date={data.application_date} localize={false} />,
  },
  {
    label: 'Gross Quantity',
    key: 'gross_quantity',
    textAlign: 'right',
    ValueCellComponent: DisplayConfig.BoldTextValue,
    getValue: ({ data }) => (!data.id ? null : data.gross_quantity),
  },
  {
    label: 'Net Quantity',
    key: 'net_quantity',
    textAlign: 'right',
    ValueCellComponent: DisplayConfig.BoldTextValue,
    getValue: ({ data }) => (!data.id ? null : data.net_quantity),
  },
]

export interface AssociatedTicketsViaTicketAppsProps {
  settlementId: number
}

export function AssociatedTicketsViaTicketApps({
  settlementId,
}: AssociatedTicketsViaTicketAppsProps) {
  const { setItem } = useStackedDetailModal()

  const { data: ticketsViaApps } = useSettlementAssociatedTickets({ id: settlementId })

  if (!ticketsViaApps || ticketsViaApps.length === 0) return null

  return (
    <>
      <DetailGrid.Title>Associated Scale Tickets</DetailGrid.Title>
      <CollapsableDetailTable
        rowItems={ticketsViaApps}
        rowItemTitle="Tickets"
        displayConfig={DISPLAY_CONFIG}
        analyticsCategory="Settlements"
        analyticsAction="Associated Tickets List"
        setItem={(item) => setItem({ type: 'ticket', item })}
        shouldShowViewButton={(ticket) => !!ticket.id}
      />
    </>
  )
}
