import { Flex } from '@chakra-ui/react'
import Modal from './Modal'
import { Tile } from './Tile'

export function TileModal({ children }) {
  return (
    <Modal>
      <Flex
        justifyContent="center"
        height="100%"
        pt={{ base: 'calc(env(safe-area-inset-top) - 8px)', sm: 0 }}
      >
        <Tile>{children}</Tile>
      </Flex>
    </Modal>
  )
}
