import { bidlClient } from 'src/api/bidl/client'

export const locationFavoritesBaseUrl = 'api/aggregator/location/favorites'

export const locationFavoritesRequests = {
  getLocationFavorites: () =>
    bidlClient
      .post(`${locationFavoritesBaseUrl}/v1/GetLocationFavorites`, { json: {} })
      .json<GetLocationsFavoriteResponse>(),
  addLocationFavorites: (body: AddLocationsFavoriteRequest) =>
    bidlClient
      .post(`${locationFavoritesBaseUrl}/v1/AddLocationFavorites`, { json: body })
      .json<AddLocationsFavoriteResponse>(),
  removeLocationFavorites: (body: RemoveLocationsFavoriteRequest) =>
    bidlClient
      .post(`${locationFavoritesBaseUrl}/v1/RemoveLocationFavorites`, { json: body })
      .json<RemoveLocationsFavoriteResponse>(),
}
