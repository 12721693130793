import { useMemo } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import * as api from 'src/api'
import { flattenPages, getNextPageParam } from 'src/data/queries/utils'
import { useAuth } from 'src/auth'

export function useBinSamples() {
  const { slug } = useAuth()
  const query = useInfiniteQuery({
    queryKey: ['bin-samples', { slug }],
    queryFn: ({ pageParam }) => api.centre.binSamples({ companySlug: slug, page: pageParam }),
    initialPageParam: 1,
    getNextPageParam,
  })

  const mergedPageData = useMemo(() => flattenPages(query.data), [query.data])

  return { query, data: mergedPageData }
}

export function useChecks() {
  const { slug } = useAuth()
  const query = useInfiniteQuery({
    queryKey: ['checks', { slug }],
    queryFn: ({ pageParam }) => api.centre.checks({ companySlug: slug, page: pageParam }),
    initialPageParam: 1,
    getNextPageParam,
  })

  const mergedPageData = useMemo(() => flattenPages(query.data), [query.data])

  return { query, data: mergedPageData }
}

export function useCoopInvestments() {
  const { slug } = useAuth()
  const query = useInfiniteQuery({
    queryKey: ['coop-investments', { slug }],
    queryFn: ({ pageParam }) => api.centre.coopInvestments({ companySlug: slug, page: pageParam }),
    initialPageParam: 1,
    getNextPageParam,
  })

  const mergedPageData = useMemo(() => flattenPages(query.data), [query.data])

  return { query, data: mergedPageData }
}
