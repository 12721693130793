import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { HTTPError } from 'src/api'
import { farmFieldsService } from 'src/api/farm-fields/farm-fields-service'
import { FarmField } from 'src/types/tickets/FarmField'

export const farmFieldsQueries = {
  useGetFarmFields,
  useCreateFarmField,
  useDeleteFarmField,
  useEditFarmField,
}

export function useGetFarmFields() {
  return useQuery<FarmField[], HTTPError>({
    queryKey: ['get-farm-fields'],
    queryFn: () => farmFieldsService.getFarmFields(),
  })
}

export function useCreateFarmField() {
  const client = useQueryClient()

  return useMutation<FarmField, HTTPError, { name: string }>({
    mutationFn: ({ name }) => farmFieldsService.createFarmField(name),
    onSuccess: () => client.invalidateQueries({ queryKey: ['get-farm-fields'] }),
  })
}

export function useDeleteFarmField() {
  const client = useQueryClient()

  return useMutation<unknown, HTTPError, { id: number }>({
    mutationFn: ({ id }) => farmFieldsService.deleteFarmField(id),
    onSuccess: async () => {
      await client.invalidateQueries({ queryKey: ['get-farm-fields'] })
      await client.invalidateQueries({ queryKey: ['ticket-detail'] })
    },
  })
}

export function useEditFarmField() {
  const client = useQueryClient()

  return useMutation<unknown, HTTPError, { id: number; name: string; revision: string }>({
    mutationFn: ({ id, name, revision }) => farmFieldsService.editFarmField(id, name, revision),
    onSuccess: async () => {
      await client.invalidateQueries({ queryKey: ['get-farm-fields'] })
      await client.invalidateQueries({ queryKey: ['ticket-detail'] })
    },
  })
}
