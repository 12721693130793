import {
  APIContractFilterOptions,
  ContractFiltersForQuery,
} from 'src/components/contracts/ContractFilters'
import { centreClient } from './client'

interface ContractResponse {
  data: Contract[]
  meta: { pagination: Pagination }
}

export const contractsRequests = {
  contracts: async ({ filter, page }: { filter?: ContractFiltersForQuery; page?: number } = {}) => {
    let options = {}

    if (page) {
      options = { ...options, searchParams: { page } }
    }

    if (filter && Object.keys(filter).length > 0) {
      options = { ...options, json: { filter } }
    } else {
      options = { ...options, json: { filter: {} } }
    }

    const response = await centreClient
      .post('api/v4/contracts/standard', options)
      .json<ContractResponse>()

    return response
  },

  contractsExport: async (filter: { [key: string]: any } = {}) => {
    const response = await centreClient.post('api/v3/contracts/standard/export', {
      json: { filter },
    })

    return response
  },

  contract: async (id: number | string) => {
    const response = await centreClient
      .get(`api/v3/contracts/${id}`)
      .json<{ data: ContractDetail }>()

    return response.data
  },

  contractFilterOptions: async () => {
    const response = await centreClient
      .get('api/v2/filters/contracts/standard')
      .json<{ data: APIContractFilterOptions }>()

    return response.data
  },
}
