import { notifyService } from 'src/api/notify-service/notify-service'
import getRegistrantId from 'src/utils/push-notifications/registrant-id/get-registrant-id'
import { nativePushNotifications } from 'src/utils/clients/native/native-push-notifications'
import getClient from 'src/utils/clients/get-client'

const unsupportedBrowserError = new Error('Web browsers are not supported.')

export async function registerWithNotify(groupId: string): Promise<void> {
  if (getClient().isWebBrowser) {
    return Promise.reject(unsupportedBrowserError)
  }

  await notifyService.register({
    groupId: groupId,
    registrantId: await getRegistrantId(),
    token: await nativePushNotifications.getToken(),
  })
}
