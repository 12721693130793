import { paymentsUsersServiceClient } from './client'
import buildPaymentSearchParams from 'src/api/payments-service/buildPaymentSearchParams'
import { DirectDepositFiltersForQuery } from 'src/components/payments/direct-deposit/DirectDepositFilters'
import {
  type DirectDepositEnrollment,
  type DirectDepositAchInfo,
} from 'src/types/payments/direct-deposit'
import useDirectDepositColumnSort from 'src/components/payments/direct-deposit/useDirectDepositColumnSort'

export type PaymentsGetAccountsResponse = PaymentAccount[]
export type PaymentsUpdateLinkedAccountsResponse = PaymentAccount[]

export interface PaymentsWalletStatusSuccessResponse {
  bushelId: string
  transactable: boolean
  walletBusinessName: string
  onboarded: boolean
  hasLinkedAccount: boolean
}

export interface PaymentsWalletLinkResponse {
  url: string
}

export type PaymentsFundingSource = {
  id: string
  name: string
  financialInstitutionName: string
  lastFour: string
  balance: string
}

export type PaymentsFundingSourceResponse = {
  fundingSources: PaymentsFundingSource[]
}

export type PaymentAccountLinkAction = 'LINK' | 'UNLINK'

export type PaymentsUsersDirectDepositEnrollmentsRawResponse = {
  elements: DirectDepositEnrollment[]
  pagination: {
    page: number
    size: number
    totalRecords: number
  }
}

export type PaymentsUsersDirectDepositEnrollmentsResponse = {
  data: DirectDepositEnrollment[]
  meta: {
    pagination: Pagination
  }
}

export const paymentsUsersRequests = {
  paymentsUserGetAccounts: () =>
    paymentsUsersServiceClient.get('v1/users/accounts').json<PaymentsGetAccountsResponse>(),
  paymentsUserUpdateLinkedAccounts: (
    accountId: string,
    agreementLanguage: string,
    action: PaymentAccountLinkAction
  ) =>
    paymentsUsersServiceClient
      .post('v1/users/accounts', { json: [{ id: accountId, action, agreementLanguage }] })
      .json<PaymentsUpdateLinkedAccountsResponse>(),
  paymentsUserWalletStatus: (bushelId: string) =>
    paymentsUsersServiceClient
      .post('v1/users/wallet', { json: [{ bushelId }] })
      .json<PaymentsWalletStatusSuccessResponse[]>(),
  paymentsUserWalletLink: (redirectURL: string) =>
    paymentsUsersServiceClient
      .post('v1/user/wallet-link', { json: { customRedirect: redirectURL } })
      .json<PaymentsWalletLinkResponse>(),
  paymentsUserFundingSources: () =>
    paymentsUsersServiceClient.get('v1/user/funding-source').json<PaymentsFundingSourceResponse>(),

  paymentsUsersDirectDepositEnrollments: async ({
    page = null,
    filter = null,
    sort = null,
  }: {
    page?: {
      page?: number
      size?: number
    }
    filter?: DirectDepositFiltersForQuery
    sort: ReturnType<typeof useDirectDepositColumnSort>
  }) => {
    const searchParams = buildPaymentSearchParams({ page, filter, sort })

    if (filter?.erpStatus) {
      searchParams.append('filter.erpStatus', filter.erpStatus.join(','))
    }

    const rawResponse = await paymentsUsersServiceClient
      .get('v1/users/accounts/linked-wallets', {
        searchParams,
      })
      .json<PaymentsUsersDirectDepositEnrollmentsRawResponse>()

    return {
      data: rawResponse.elements,
      meta: {
        pagination: {
          total: rawResponse.pagination.totalRecords,
          count: rawResponse.elements.length,
          per_page: rawResponse.pagination.size,
          current_page: page.page || 1,
          total_pages: Math.ceil(rawResponse.pagination.totalRecords / rawResponse.pagination.size),
        },
      },
    } as PaymentsUsersDirectDepositEnrollmentsResponse
  },

  paymentsUsersDirectDepositAchInfo: async ({
    id,
    agreementLanguage,
  }: {
    id: string
    agreementLanguage: string
  }) => {
    return paymentsUsersServiceClient
      .post(`v1/users/accounts/linked-wallets/${id}`, {
        json: { agreement_language: agreementLanguage },
      })
      .json<DirectDepositAchInfo>()
  },

  paymentsUsersDirectDepositStatusUpdate: async ({
    id,
    status,
  }: {
    id: string
    status: string
  }) => {
    return paymentsUsersServiceClient
      .put('v1/users/accounts/linked-wallets/status', {
        json: { id, status },
      })
      .json()
  },
}
