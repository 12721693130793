import { useEffect, useRef, useState } from 'react'
import { Box, useBreakpoint } from 'src/components/designsystem'
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryLabel,
  VictoryTheme,
  VictoryTooltip,
} from 'victory'
import { Flyout, ChartFlyoutProps } from 'src/components/designsystem/charts/utils'

export default function HorizontalBar({ chartData, flyoutModal, height }: HorizontalBarChartProps) {
  const parentRef = useRef(null)
  const [parentWidth, setParentWidth] = useState(0)
  const { isDesktop } = useBreakpoint()

  useEffect(() => {
    // Get initial size
    setParentWidth(parentRef?.current?.offsetWidth)

    // Watch for any other window size changes
    addEventListener('resize', () => {
      setParentWidth(0)
      setTimeout(() => {
        setParentWidth(parentRef?.current?.offsetWidth)
      }, 1) // Ensure always executes after parent width is 0
    })
  }, [])

  return (
    <Box width="100%" ref={parentRef}>
      <VictoryChart
        theme={VictoryTheme.material}
        domainPadding={{ x: 50, y: 100 }}
        padding={{
          left: 100,
          top: 50,
          bottom: 50,
        }}
        height={height}
        width={parentWidth}
        horizontal
        containerComponent={<VictoryContainer responsive={false} />}
      >
        <VictoryAxis
          style={{
            tickLabels: {
              fontSize: '12px',
              fontWeight: 'bold',
            },
          }}
          tickLabelComponent={<VictoryLabel textAnchor="end" />}
          tickFormat={(t) => t.replace(' ', '\n')}
        />
        <VictoryAxis
          dependentAxis
          tickLabelComponent={
            <VictoryLabel
              style={{
                fontSize: '12px',
                fontWeight: '600',
              }}
            />
          }
        />
        <VictoryBar
          key={chartData.label}
          data-testid={chartData.label}
          barWidth={40}
          labels={(_data) => undefined}
          y={(datum) => Math.max(datum.y, 20)}
          cornerRadius={4}
          data={chartData.data}
          labelComponent={
            <VictoryTooltip
              flyoutComponent={
                !!flyoutModal && (
                  <HorizontalBarFlyout
                    chartData={chartData}
                    ModalBody={flyoutModal}
                    isDesktop={isDesktop}
                  />
                )
              }
            />
          }
          style={{
            data: {
              fill: chartData.color,
            },
          }}
        />
      </VictoryChart>
    </Box>
  )
}

export function HorizontalBarFlyout(props: Partial<ChartFlyoutProps>) {
  const { chartData, ModalBody, isDesktop } = props
  const orientArrowRight = props.datum._y > Math.max(...chartData.data.map((d) => d.y)) / 2
  const xAxisValue = isDesktop ? (orientArrowRight ? props.x - 300 : props.x - 50) : 0
  return (
    <Flyout
      width="285"
      height="335"
      xAxisValue={xAxisValue}
      yAxisValue={isDesktop ? props.y - 100 : props.y - 10}
      orientArrowRight={isDesktop && orientArrowRight}
      arrowTopMargin="45px"
      hideArrow={!isDesktop}
    >
      <ModalBody {...props} />
    </Flyout>
  )
}
