import { ChevronDownIcon } from 'src/components/designsystem/Icons'
import { forwardRef, Button } from '..'

export interface FilterButtonProps {
  variant?: 'unselected' | 'selected'
  children?: any
  icon?: React.ReactElement
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const FilterButton = forwardRef(
  ({ variant, icon = <ChevronDownIcon fontSize="xl" />, ...rest }: FilterButtonProps, ref) => {
    return (
      <Button
        ref={ref}
        data-testid="filter-button"
        borderRadius="1rem"
        fontWeight="normal"
        fontSize="md"
        px={2}
        pl={3} // the additional padding on the left is to offset the padding on the icon
        py={1}
        h={8}
        variant={variant === 'unselected' ? 'secondary' : 'primary'}
        rightIcon={icon}
        iconSpacing={1}
        {...rest}
      />
    )
  }
)
