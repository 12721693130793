import { APITicketFilterOptions, TicketFiltersForQuery } from 'src/components/tickets/TicketFilters'
import { centreClient } from './client'
import { TicketSummary } from 'src/types/tickets/TicketSummary'
import { Ticket } from 'src/types/tickets/Ticket'
import { AssociatedContract } from 'src/types/tickets/AssociatedContract'

interface TicketResponse {
  data: Ticket[]
  meta: { pagination: Pagination }
}

export const ticketRequests = {
  tickets: async ({
    filter,
    page,
    useSimplePagination = false,
  }: { filter?: TicketFiltersForQuery; page?: number; useSimplePagination?: boolean } = {}) => {
    let options: Record<string, any> = {}

    if (page) {
      options = { ...options, searchParams: { page } }
    }

    if (useSimplePagination) {
      options = { ...options, searchParams: { ...options.searchParams, 'simple-paging': '1' } }
    }

    if (filter && Object.keys(filter).length > 0) {
      options = { ...options, json: { filter } }
    } else {
      options = { ...options, json: { filter: {} } }
    }

    return centreClient.post('api/v3/tickets', options).json<TicketResponse>()
  },

  ticketsExport: async (filter: { [key: string]: any } = {}) => {
    const response = await centreClient.post('api/v2/tickets/export', {
      json: { filter },
    })

    return response
  },

  ticket: async (id: number | string) => {
    const response = await centreClient
      .get(`api/v2/tickets/${id}?include-ticket-applications=false`)
      .json<{ data: Ticket }>()

    return response.data
  },

  ticketFilterOptions: async () => {
    const response = await centreClient
      .get('api/v2/filters/tickets')
      .json<{ data: APITicketFilterOptions }>()

    return response.data
  },

  ticketSummaries: async ({ filter }: { filter?: TicketFiltersForQuery }) => {
    let options = {}

    if (filter && Object.keys(filter).length > 0) {
      options = { ...options, json: { filter } }
    } else {
      options = { ...options, json: { filter: {} } }
    }

    const response = await centreClient
      .post(`api/v1/summaries/tickets`, options)
      .json<{ data: TicketSummary[] }>()

    return response.data
  },

  ticketAssociatedSettlements: async (id: number) => {
    const response = await centreClient
      .get(`api/v1/tickets/${id}/associated-settlements`)
      .json<{ data: AssociatedSettlement[] }>()

    return response.data
  },

  ticketAssociatedContracts: (id: number) =>
    centreClient
      .get(`api/v1/tickets/${id}/associated-contracts`)
      .json<{ data: AssociatedContract[] }>()
      .then((response) => response.data),

  setFieldsForTicket: (ticketId: number, farmFieldIds: number[]) =>
    centreClient.put(`api/v1/tickets/${ticketId}/user-defined-farm-fields`, {
      json: { user_defined_farm_fields: farmFieldIds.map((id) => ({ id })) },
    }),
}
