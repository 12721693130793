import { NextPageContext } from 'next'
import { v4 as UUIDv4 } from 'uuid'
import { CookieJar } from './storage/cookies/cookie-jar'
import { setCookie } from './storage/cookies/cookies'

export const APP_INSTALLATION_ID_COOKIE = 'bushel-web-installation-id'

function generateInstallationId(ctx: NextPageContext): string {
  const installationId = UUIDv4()
  setCookie(ctx, APP_INSTALLATION_ID_COOKIE, installationId)
  return installationId
}

/**
 * Get the Installation ID from the request, and generate a new UUID if needed and save as a cookie
 *
 * @param ctx - Next page context from request
 * @param cookies - cookies sent with request from the browser, already parsed
 * @returns the 128 bit UUID that represents the installation ID in the browser
 */
export function getInstallationId(ctx: NextPageContext, cookies: CookieJar): string {
  return cookies[APP_INSTALLATION_ID_COOKIE] ?? generateInstallationId(ctx)
}
