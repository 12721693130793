import { Box, Flex, IconButton, Select } from '@chakra-ui/react'
import { DatepickerResult } from 'src/components/designsystem/date-picker/hooks'
import { ChevronBackIcon, ChevronForwardIcon } from 'src/components/designsystem/Icons'
import dayjs from 'dayjs'

interface HeaderProps {
  monthOptions?: string[]
  yearOptions?: number[]
  previousMonthProps: DatepickerResult['previousMonthProps']
  nextMonthProps: DatepickerResult['nextMonthProps']
  monthSelectProps: DatepickerResult['monthSelectProps']
  yearSelectProps: DatepickerResult['yearSelectProps']
}

export default function Header({
  monthOptions = defaultMonths,
  yearOptions = defaultYears,
  previousMonthProps,
  nextMonthProps,
  monthSelectProps,
  yearSelectProps,
}: HeaderProps) {
  return (
    <Flex alignItems="center" py={4} px={6}>
      <Box flex={1} mr={2}>
        <IconButton
          aria-label="Toggle"
          icon={<ChevronBackIcon />}
          variant="ghostSecondary"
          size="sm"
          w={8}
          minW="auto"
          isRound
          {...previousMonthProps}
        />
      </Box>

      <Box mr={2}>
        <Select size="sm" borderRadius="8px" aria-label="Current Month" {...monthSelectProps}>
          {monthOptions.map((month, index) => {
            return (
              <option key={month} value={index}>
                {month}
              </option>
            )
          })}
        </Select>
      </Box>

      <Box flex="0 0 80px" mr={2}>
        <Select size="sm" borderRadius="8px" aria-label="Current Year" {...yearSelectProps}>
          {yearOptions.map((year) => {
            return (
              <option key={year} value={year}>
                {year}
              </option>
            )
          })}
        </Select>
      </Box>

      <Flex flex={1} justifyContent="flex-end">
        <IconButton
          icon={<ChevronForwardIcon />}
          variant="ghostSecondary"
          size="sm"
          w={8}
          minW="auto"
          isRound
          {...nextMonthProps}
        />
      </Flex>
    </Flex>
  )
}

const defaultMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const defaultYears = getDefaultFilterYears()

function getDefaultFilterYears() {
  const years = []
  for (let i = 2010; i <= dayjs().year() + 5; i++) {
    years.push(i)
  }
  return years
}
