import { centreClient } from './client'
import {
  APIDeliveryTicketFilterOptions,
  DeliveryTicketFiltersForQuery,
} from 'src/components/delivery-tickets/DeliveryTicketsFilters'

interface DeliveryTicketResponse {
  data: DeliveryTicket[]
  meta: { pagination: Pagination }
}

export const deliveryTicketRequests = {
  deliveryTickets: async ({
    filter,
    page,
  }: { filter?: DeliveryTicketFiltersForQuery; page?: number } = {}) => {
    let options = {}

    if (page) {
      options = { ...options, searchParams: { page } }
    }

    if (filter && Object.keys(filter).length > 0) {
      options = { ...options, json: { filter } }
    } else {
      options = { ...options, json: { filter: {} } }
    }

    return centreClient.post('api/v3/delivery-tickets', options).json<DeliveryTicketResponse>()
  },

  deliveryTicket: async (id: string | number) => {
    const response = await centreClient
      .get(`api/v1/delivery-tickets/${id}`)
      .json<{ data: DeliveryTicketDetail }>()

    return response.data
  },

  deliveryTicketFilterOptions: async () => {
    const response = await centreClient
      .get('api/v2/filters/delivery-tickets')
      .json<{ data: APIDeliveryTicketFilterOptions }>()

    return response.data
  },
}
