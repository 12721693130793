import { ChevronForwardIcon, HStack } from 'src/components/designsystem'

export function ResourceRowRightChevron({ children }: Readonly<{ children: React.ReactNode }>) {
  return (
    <HStack w="full">
      {children}
      <ChevronForwardIcon />
    </HStack>
  )
}
