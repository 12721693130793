import { Box, Divider, SimpleGrid, useBreakpoint } from 'src/components/designsystem'
import { DetailGrid, SkeleKeyValue, renderOrEmDash } from 'src/components/resource'
import { DataColors } from 'src/themes/colors'
import { ContractProgressBarQtyDelivered } from 'src/components/contracts/contracts-v3/v3-contract-visuals/ContractProgressBarQtyDelivered'
import { ContractProgressBarQtyPriced } from 'src/components/contracts/contracts-v3/v3-contract-visuals/ContractProgressBarQtyPriced'
import {
  generateContractDeliveredFacts,
  generateContractPricedFacts,
} from './generate-contract-facts'

export interface QuantityProgressChartsProps {
  contractDetail: ContractDetailV3
  isLoaded?: boolean
}

export function QuantityProgressCharts({ contractDetail, isLoaded }: QuantityProgressChartsProps) {
  const { isMobile } = useBreakpoint()

  if (!contractDetail) return null

  const { isClosed: isDeliveredClosed } = generateContractDeliveredFacts(contractDetail)
  const { isClosed: isPricedClosed } = generateContractPricedFacts(contractDetail)

  return (
    <>
      <SimpleGrid columns={isMobile ? 1 : 2} spacing={10}>
        <Box width="100%">
          <Box borderRadius="0.75rem" width="100%" border="1px solid" borderColor="gray.300">
            <DetailGrid templateColumns={['1fr']}>
              <DetailGrid.List>
                <DetailGrid.ListCard heading="Quantity Delivered">
                  <ContractProgressBarQtyDelivered contract={contractDetail} />
                  <SkeleKeyValue
                    isLoaded={isLoaded}
                    label="Qty Delivered"
                    dotColor={DataColors[isDeliveredClosed ? 'green' : 'yellow']}
                  >
                    {renderOrEmDash({ value: contractDetail?.quantity_delivered })}
                  </SkeleKeyValue>
                  <Divider />
                  <SkeleKeyValue
                    isLoaded={isLoaded}
                    label="Qty Remaining"
                    dotColor={DataColors['lightgray']}
                  >
                    {renderOrEmDash({ value: contractDetail?.quantity_remaining })}
                  </SkeleKeyValue>
                  <Divider />
                  <SkeleKeyValue
                    isLoaded={isLoaded}
                    label="Qty Canceled"
                    dotColor={DataColors[isDeliveredClosed ? 'green' : 'red']}
                  >
                    {renderOrEmDash({ value: contractDetail?.quantity_canceled })}
                  </SkeleKeyValue>
                </DetailGrid.ListCard>
              </DetailGrid.List>
            </DetailGrid>
          </Box>
        </Box>
        <Box width="100%">
          <Box borderRadius="0.75rem" width="100%" border="1px solid" borderColor="gray.300">
            <DetailGrid templateColumns={['1fr']}>
              <DetailGrid.List>
                <DetailGrid.ListCard heading="Quantity Priced">
                  <ContractProgressBarQtyPriced contract={contractDetail} />
                  <SkeleKeyValue
                    isLoaded={isLoaded}
                    label="Qty Priced"
                    dotColor={DataColors[isPricedClosed ? 'green' : 'mediumblue']}
                  >
                    {renderOrEmDash({ value: contractDetail?.quantity_priced })}
                  </SkeleKeyValue>
                  <Divider />
                  <SkeleKeyValue
                    isLoaded={isLoaded}
                    label="Qty Unpriced"
                    dotColor={DataColors['lightgray']}
                  >
                    {renderOrEmDash({ value: contractDetail?.quantity_unpriced })}
                  </SkeleKeyValue>
                  <Divider />
                  <SkeleKeyValue
                    isLoaded={isLoaded}
                    label="Qty Canceled"
                    dotColor={DataColors[isPricedClosed ? 'green' : 'red']}
                  >
                    {renderOrEmDash({ value: contractDetail?.quantity_canceled })}
                  </SkeleKeyValue>
                </DetailGrid.ListCard>
              </DetailGrid.List>
            </DetailGrid>
          </Box>
        </Box>
      </SimpleGrid>
    </>
  )
}
