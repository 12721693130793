import { useCallback } from 'react'
import { usePaymentWalletLink } from 'src/data/queries'
import { useToast } from 'src/components/designsystem'
import { getSsoBridgeUrl } from 'src/auth/keycloak'
import { inAppBrowser } from 'src/utils/clients/native/in-app-browser'
import { useAuth } from 'src/auth'

type useGoToBushelWalletProps = Readonly<{
  isDisabled?: boolean
}>

/**
 * This hook should be used in combination with GoToWalletButton when possible.
 * Using this hook directly breaks when in Safari desktop, which is the problem that GoToWalletButton solves.
 */
export default function useGoToBushelWallet({ isDisabled }: useGoToBushelWalletProps = {}) {
  const { token } = useAuth()
  const { isLoading, data, isError } = usePaymentWalletLink({ enabled: !isDisabled })
  const walletUrl = data?.url
  const toast = useToast()

  const goToBushelWallet = useCallback(async () => {
    if (!walletUrl) return

    try {
      const ssoBridgeUrl = await getSsoBridgeUrl(token, walletUrl)
      inAppBrowser.open(ssoBridgeUrl)
    } catch (error) {
      toast({
        status: 'error',
        title: 'Could not connect with Bushel Wallet. Our team is working to fix this issue',
      })
    }
  }, [toast, token, walletUrl])

  return { isLoading, isError, goToBushelWallet, walletUrl }
}
