import { Dayjs } from 'dayjs'

export function makeMonthArray(date: Dayjs) {
  const numberOfDaysInMonth = date.daysInMonth()
  const daysInMonth = new Array(numberOfDaysInMonth).fill(null).map((_, dayInMonth) => {
    return date.set('date', dayInMonth + 1)
  })

  return daysInMonth
}

function repeat<T>(value: T, length: number): T[] {
  return new Array(length).fill(null).map(() => value)
}

function padStart<T>(array: T[], value: T, length: number): (T | null)[] {
  return [...repeat(value, length - array.length), ...array]
}
function padEnd<T>(array: T[], value: T, length: number): (T | null)[] {
  return [...array, ...repeat(value, length - array.length)]
}

export function splitIntoWeeks(days: Dayjs[]): (Dayjs | null)[][] {
  const { result, buffer } = days.reduce<{
    result: Dayjs[][]
    buffer: Dayjs[]
  }>(
    (acc, day) => {
      const { result, buffer } = acc
      if (day.day() === 6) {
        return { result: [...result, [...buffer, day]], buffer: [] }
      }
      return { result, buffer: [...buffer, day] }
    },
    { result: [], buffer: [] }
  )

  const output: (Dayjs | null)[][] = buffer.length ? [...result, buffer] : result
  output[0] = padStart(output[0], null, 7)
  output[output.length - 1] = padEnd(output[output.length - 1], null, 7)

  return output
}

export function calculateSameDayOfDifferentMonth(fromDate: Dayjs, toMonth: Dayjs) {
  const absoluteIndex = fromDate.startOf('month').day() + fromDate.date()
  const startOfToMonth = toMonth.startOf('month')
  let newDate = absoluteIndex - startOfToMonth.day()
  if (newDate > startOfToMonth.daysInMonth()) {
    newDate -= 7
  }
  if (newDate < 1) {
    newDate += 7
  }
  return startOfToMonth.date(newDate)
}
