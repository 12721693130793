import { useEffect, useCallback } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import DatePicker from 'src/components/designsystem/date-picker'
import {
  useDateRange,
  useDatepicker,
  DatepickerMode,
  RangePreset,
} from 'src/components/designsystem/date-picker/hooks'
import { DateFilterOptionItem, BaseFilterProps } from 'src/utils/filters'
import { OverflowStack } from './Filters'

const dateSetter = (
  setSelectedFilter: (value: DateFilterOptionItem) => void,
  dateFormat = 'YYYY-MM-DDTHH:mm:ssZ'
) => {
  return (value: [Dayjs, Dayjs]) => {
    const [rangeStart, rangeEnd] = value

    // Neither date set, remove filter value
    if (!rangeStart && !rangeEnd) {
      setSelectedFilter(null)
      return
    }

    // One date set, need to wait for both
    if (!rangeStart || !rangeEnd) return

    // Both dates set, update filter value
    setSelectedFilter({
      // TODO: Should these always use `00:00:00` and `23:59:59` for start/end times?
      // TODO: Ideally the date-picker behavior would handle that for us...
      from: rangeStart.format(dateFormat),
      to: rangeEnd.format(dateFormat),
    })
  }
}

export type DateFilterProps = Omit<BaseFilterProps<DateFilterOptionItem>, 'values'> & {
  value: DateFilterOptionItem
  initialShowPresets?: boolean
  dateFormat?: string
}

export function DateFilter({ value, onChange, initialShowPresets, dateFormat }: DateFilterProps) {
  const {
    range,
    setRange,
    dateString: [startDateString, endDateString],
  } = useDateRange({
    format: 'YYYY-MM-DD',
    initialValue: value?.from && value?.to ? [dayjs(value?.from), dayjs(value?.to)] : undefined,
  })

  const setSelectedValue = dateSetter(onChange, dateFormat)

  const {
    months,
    currentView,
    setCurrentView,
    previousMonthProps,
    nextMonthProps,
    yearSelectProps,
    monthSelectProps,
  } = useDatepicker({
    mode: DatepickerMode.range,
    value: range,
    numberOfMonths: 1,
    onChange: (val) => {
      setRange(val)
      setSelectedValue(val)
    },
  })

  useEffect(() => {
    // This component isn't fully controlled, so if the "Clear All" button is clicked
    // we have to reset the current range
    if (!value?.from && !value?.to) {
      if (startDateString && endDateString) setRange([null, null])
    }
  }, [value?.from, value?.to, startDateString, endDateString, setRange])

  const setAndFocusDates = useCallback(
    (start: dayjs.Dayjs, end: dayjs.Dayjs) => {
      setRange([start, end])
      setCurrentView(end)
      setSelectedValue([start, end])
    },
    [setRange, setCurrentView, setSelectedValue]
  )
  return (
    <OverflowStack direction="column" alignItems="flex-start" spacing={0}>
      <DatePicker.Header
        {...{
          currentView,
          setCurrentView,
          previousMonthProps,
          nextMonthProps,
          yearSelectProps,
          monthSelectProps,
        }}
      />
      <DatePicker.Months months={months} />
      <DatePicker.Presets
        collapsible
        presets={getFilterPresets()}
        initialShowPresets={initialShowPresets}
        setAndFocusDates={setAndFocusDates}
      />
    </OverflowStack>
  )
}

function getFilterPresets() {
  return [
    {
      label: 'Today',
      'aria-label': 'Select Preset: Today',
      value: () => [dayjs().startOf('day'), dayjs().endOf('day')],
    },
    {
      label: 'Last 7 Days',
      'aria-label': 'Select Preset: Last 7 Days',
      value: () => [dayjs().subtract(7, 'day').startOf('day'), dayjs().endOf('day')],
    },
    {
      label: 'Last 30 Days',
      'aria-label': 'Select Preset: Last 30 Days',
      value: () => [dayjs().subtract(30, 'day').startOf('day'), dayjs().endOf('day')],
    },
    {
      label: 'Last 6 Months',
      'aria-label': 'Select Preset: Last 6 Months',
      value: () => [dayjs().subtract(6, 'month').startOf('day'), dayjs().endOf('day')],
    },
    {
      label: 'Year-to-Date',
      'aria-label': 'Select Preset: Year-to-Date',
      value: () => [dayjs().startOf('year'), dayjs().endOf('day')],
    },
    {
      label: `${dayjs().year() - 1}`,
      'aria-label': 'Select Preset: Year-to-Date',
      value: () => [
        dayjs()
          .year(dayjs().year() - 1)
          .startOf('year'),
        dayjs()
          .year(dayjs().year() - 1)
          .endOf('year'),
      ],
    },
    {
      label: 'Next 30 Days',
      'aria-label': 'Select Preset: Next 30 Days',
      value: () => [dayjs().startOf('day'), dayjs().add(30, 'days').endOf('day')],
    },
    {
      label: 'Next 6 Months',
      'aria-label': 'Select Preset: Next 6 Months',
      value: () => [dayjs().startOf('day'), dayjs().add(6, 'months').endOf('day')],
    },
    {
      label: `${dayjs().year() + 1}`,
      'aria-label': `Select Preset: ${dayjs().year() + 1}`,
      value: () => [
        dayjs()
          .year(dayjs().year() + 1)
          .startOf('year'),
        dayjs()
          .year(dayjs().year() + 1)
          .endOf('year'),
      ],
    },
  ] as RangePreset[]
}
