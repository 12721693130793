import { Button } from 'src/components/designsystem'
import { useMerchandiser } from 'src/data/merchandiser'
import { MerchandiserAccessLandingState } from 'src/components/resource'

export function UnselectedPage() {
  const { isUnknownId, openSearch } = useMerchandiser()

  return (
    <MerchandiserAccessLandingState
      subHeader={isUnknownId ? `Unable to retrieve this user's information` : undefined}
      action={
        <Button variant="primary" onClick={openSearch}>
          Start Searching
        </Button>
      }
    />
  )
}
