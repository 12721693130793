import { centreClient } from 'src/api/client'

type CustomEntityResponse<T> = {
  data: CustomEntity<T>[]
  meta: {
    file_token: string
    pagination: Pagination
  }
}

export type CustomEntity<T> = {
  company_id: number
  company_id_remote_user_id: string
  created_at: string
  entity_type: string
  id: number
  payload: T
  remote_id: string
  remote_user_id: string
  updated_at: string
}

export type CentreBinSample = {
  grower_bin?: string
  variety?: string
  protein?: string
  moisture?: string
  plump?: string
  thins?: string
  dockage?: string
  test_weight?: string
  's&b'?: string
  greens?: string
  DON?: string
  aubry?: string
  sprout?: string
  frost?: string
  ergot?: string
  mold?: string
  heat?: string
  blight?: string
  other_type?: string
  obj_seed?: string
  rej_reason?: string
}

export type CentreCheck = {
  'Account_#'?: string
  Commodity?: string
  'Check_#'?: string
  Check_Date?: string
  Check_Amt?: string
  Statement_Type?: string
  Check_Type?: string
  Crop_Year?: string
}

export type CentreCoopInvestment = {
  'Account #'?: string
  Commodity?: string
  Description?: string
  Amount?: string
}

export const customEntitiesRequests = {
  binSamples: async ({ companySlug, page }: { companySlug: string; page?: number }) => {
    return centreClient
      .get(`api/v1/custom-entities/${companySlug}~Bin_Sample`, {
        searchParams: page ? { page } : undefined,
      })
      .json<CustomEntityResponse<CentreBinSample>>()
      .then((response) => ({
        ...response,
        data: response.data.map(
          ({ id, payload }): BinSample => ({
            id,
            growerBin: payload.grower_bin ?? 'No Name',
            variety: payload.variety ?? null,
            protein: payload.protein ?? null,
            moisture: payload.moisture ?? null,
            plump: payload.plump ?? null,
            thins: payload.thins ?? null,
            dockage: payload.dockage ?? null,
            testWeight: payload.test_weight ?? null,
            sAndB: payload['s&b'] ?? null,
            greens: payload.greens ?? null,
            don: payload.DON ?? null,
            aubry: payload.aubry ?? null,
            sprout: payload.sprout ?? null,
            frost: payload.frost ?? null,
            ergot: payload.ergot ?? null,
            mold: payload.mold ?? null,
            heat: payload.heat ?? null,
            blight: payload.blight ?? null,
            totalOtherMaterial: payload.other_type ?? null,
            objectionableSeed: payload.obj_seed ?? null,
            dCut: payload.rej_reason ?? null,
          })
        ),
      }))
  },

  checks: async ({ companySlug, page }: { companySlug: string; page?: number }) => {
    return centreClient
      .get(`api/v1/custom-entities/${companySlug}~Checks`, {
        searchParams: page ? { page } : undefined,
      })
      .json<CustomEntityResponse<CentreCheck>>()
      .then((response) => ({
        ...response,
        data: response.data.map(
          ({ id, payload }): Check => ({
            id,
            accountName: payload['Account_#'] ?? null,
            checkAmount: payload.Check_Amt ?? null,
            checkDate: payload.Check_Date ?? null,
            checkNumber: payload['Check_#'] ?? null,
            checkType: payload.Check_Type ?? null,
            commodity: payload.Commodity ?? null,
            cropYear: payload.Crop_Year ?? null,
            statementType: payload.Statement_Type ?? null,
          })
        ),
      }))
  },

  coopInvestments: async ({ companySlug, page }: { companySlug: string; page?: number }) => {
    return centreClient
      .get(`api/v1/custom-entities/${companySlug}~Coop-Investment`, {
        searchParams: page ? { page } : undefined,
      })
      .json<CustomEntityResponse<CentreCoopInvestment>>()
      .then((response) => ({
        ...response,
        data: response.data.map(
          ({ id, payload }): CoopInvestment => ({
            id,
            description: payload.Description ?? null,
            accountName: payload['Account #'] ?? null,
            amount: payload.Amount ?? null,
            commodity: payload.Commodity ?? null,
          })
        ),
      }))
  },
}
