import { Capacitor } from '@capacitor/core'
import { User } from 'configcat-react'
import { Session } from 'next-auth'
import PackageInfo from 'package.json'

/**
 * Returns the standard user object to be used in all ConfigCat evaluations
 * @param session - The NextAuth session
 * @param cookies - The cookies from the request
 * @param custom - Any additional custom properties to add to the user object
 * @returns The ConfigCat user object
 */
export default function getConfigCatUser(
  session: Session | null,
  cookies: Record<string, string>,
  custom: Record<string, any> = {}
) {
  const CompanySlug = cookies?.['bushel-web-company']
  const AppInstallationId = cookies?.['bushel-web-installation-id']

  const customUserProps = {
    CompanySlug,
    AppInstallationId,
    AppVersion: PackageInfo.version,
    AppPlatform: Capacitor.getPlatform(),
    ...custom,
  }

  // Default to using the installation ID if the user is not logged in
  const userIdentifier = session?.user?.bushelId ?? AppInstallationId
  const UserIdSource = session?.user?.bushelId ? 'bushel' : 'installation'

  return new User(userIdentifier, undefined, undefined, {
    UserIdSource,
    ...customUserProps,
  })
}
