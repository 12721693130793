import PullToRefresh from 'react-simple-pull-to-refresh'
import { Box, Spinner } from 'src/components/designsystem'
import getClient from 'src/utils/clients/get-client'

export default function CapacitorPullToRefresh({ onRefreshAction, children }) {
  return getClient().isNativeApp ? (
    <Box
      sx={{
        '.ptr, .ptr__pull-down, .ptr__children': {
          // This prevents the shadows from being clipped
          overflow: 'unset !important',
        },
      }}
    >
      <PullToRefresh
        onRefresh={onRefreshAction}
        pullingContent=""
        refreshingContent={<LoadingIndicator />}
      >
        {children}
      </PullToRefresh>
    </Box>
  ) : (
    <>{children}</>
  )
}

function LoadingIndicator() {
  return (
    <Box pt="8">
      <Spinner />
    </Box>
  )
}
