import { useEffect, MutableRefObject, useRef, useState, UIEvent, useCallback } from 'react'
import { BoxProps, useBreakpoint as useChakraBreakpoint } from '@chakra-ui/react'
import create, { UseStore } from 'zustand'
import createContext from 'zustand/context'
import { TextProps } from 'src/components/designsystem/Text'

export type BoxPropsWithTextStyle = Omit<BoxProps, 'textStyle'> & {
  textStyle?: TextProps['textStyle']
}

const DESKTOP_BREAKPOINTS = ['lg', 'xl', '2xl']
const MOBILE_BREAKPOINTS = ['base', 'xs', 'sm']

type BreakpointState = {
  breakpoint: ChakraBreakpoint
  isDesktop: boolean
  isMobile: boolean
  updateBreakpoint: (value: string) => void
}

const { Provider: BreakpointStoreProvider, useStore: useBreakpoint } =
  createContext<BreakpointState>()

export { BreakpointStoreProvider, useBreakpoint }

/**
 * This manages the updates from Chakra's `useBreakpoint()` hook.
 * It ensures we can reference its value anywhere without worrying about overhead.
 *
 * It also calculates `isDesktop` and `isMobile` in accordance with our breakpoint rules.
 *
 * !! NOTE: DO NOT USE THIS MORE THAN ONCE IN THE APP
 */
export function useBreakpointHelperSingleton({ initBreakpoint }) {
  const isInitialSet = useRef<boolean>(false)
  const breakpoint = useChakraBreakpoint({ ssr: true })
  const { updateBreakpoint } = useBreakpoint()

  useEffect(() => {
    // `breakpoint` can initialize to undefined
    if (!breakpoint) return

    if (!isInitialSet.current && breakpoint === initBreakpoint) {
      isInitialSet.current = true
      return
    }

    isInitialSet.current = true

    updateBreakpoint(breakpoint)
  }, [initBreakpoint, breakpoint, updateBreakpoint])
}

export function createBreakpointStore({
  breakpoint,
}: {
  breakpoint: ChakraBreakpoint
}): UseStore<BreakpointState> {
  return create((set) => ({
    // State
    ...deriveBreakpointState(breakpoint),

    // Handlers
    updateBreakpoint: (value: ChakraBreakpoint) => {
      set(deriveBreakpointState(value))
    },
  }))
}

function deriveBreakpointState(breakpoint: ChakraBreakpoint) {
  return {
    breakpoint,
    isDesktop: DESKTOP_BREAKPOINTS.includes(breakpoint),
    isMobile: MOBILE_BREAKPOINTS.includes(breakpoint),
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Hook that determines if a click was outside one or more elements.
 * Handles both `touchstart` and `mousedown` for desktop/mobile.
 * If click is contained in any of the supplied refs, the handler is not called.
 *
 * @param refs -
 * @param handler -
 * @returns undefined
 */
export function useOnClickOutside(refs: MutableRefObject<HTMLElement>[], handler: () => void) {
  const isTouch = typeof window !== 'undefined' && typeof window.ontouchstart !== 'undefined'
  const eventName = isTouch ? 'touchstart' : 'mousedown'

  useEffect(() => {
    const listener = (event) => {
      const containedInOne = refs.some(
        (extraRef) => extraRef.current && extraRef.current.contains(event.target)
      )

      if (containedInOne) return

      handler()
    }

    document.addEventListener(eventName, listener)

    return () => {
      document.removeEventListener(eventName, listener)
    }
  }, [refs, handler, eventName])
}

/**
 * Hook that tracks an element's Scroll Top attribute
 * Example: https://codesandbox.io/s/add-a-border-on-scroll-forked-0jd164?file=/src/index.js
 */
export function useScrollTop() {
  const [scrollTop, setScrollTop] = useState(0)

  const onScroll = useCallback(
    (event: UIEvent<HTMLDivElement>) => setScrollTop(event.currentTarget.scrollTop),
    []
  )
  return [scrollTop, { onScroll }] as const
}
