export function getWindowNextDataProps(): NextData['props'] & { cookies: Record<string, string> } {
  return typeof window === 'undefined' ? ({} as any) : window.__NEXT_DATA__?.props || {}
}

export function getWindowNextDataQuery(): NextData['query'] & {
  userId: string | undefined
  userIdpId: string | undefined
} {
  return typeof window === 'undefined' ? ({} as any) : window.__NEXT_DATA__?.query || {}
}
