import { Box } from 'src/components/designsystem'
import { RefObject } from 'react'
import { useTheme } from '@chakra-ui/react'

type SwipeableBackdropProps = Readonly<{
  backdropRef: RefObject<HTMLDivElement>
}>

export default function SwipeableBackdrop({ backdropRef }: SwipeableBackdropProps) {
  const theme = useTheme()

  return (
    <Box
      ref={backdropRef}
      inset={0}
      position="fixed"
      zIndex={(theme.zIndices.popover ?? 1500) - 1} // one less than zIndex of Sidebar itself
    />
  )
}
