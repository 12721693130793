import { centreClient } from './client'

interface WorkOrderListResponse {
  data: WorkOrder[]
  meta: { pagination: Pagination }
}

export const workOrderRequests = {
  workOrders: async ({ page }: { page?: number } = {}) => {
    let options = {}

    if (page) {
      options = { ...options, searchParams: { page } }
    }

    // options = { ...options, json: { filter: {} } }

    return centreClient.get('api/v1/work-orders', options).json<WorkOrderListResponse>()
  },

  workOrder: async (id: string | number) => {
    const response = await centreClient
      .get(`api/v1/work-orders/${id}`)
      .json<{ data: WorkOrderDetail }>()

    return response.data
  },
}
