import { Fragment } from 'react'

import { useTheme, Grid, Text, Theme, Wrap, Heading } from 'src/components/designsystem'

export type FontWeightType = 'normal' | 'medium' | 'bold'

export type FontFamilyType = 'Roboto' | 'Montserrat'

const displayFontFamilyWeight = ({ fontSizes, fontFamily, fontWeight }) => (
  <Grid key={`weight-${fontFamily}-${fontWeight}`} m={4} templateColumns="auto auto 1fr">
    <Heading
      type="h6"
      aria-label={`${fontFamily} ${fontWeight}`}
      gridColumn="1 / span 3"
      bgColor="blue.200"
      p={4}
      key={`heading-${fontFamily}-${fontWeight}`}
      textAlign="center"
    >
      {fontFamily} {fontWeight}
    </Heading>
    {Object.keys(fontSizes)
      .map((k) => [k, fontSizes[k]])
      .map((s: [string, string]) => (
        <Fragment key={`${fontFamily}-${fontWeight}-${s[1]}`}>
          <Text key={`a-${fontFamily}-${fontWeight}-${s[1]}`} p={4} my="auto" bgColor="gray.200">
            {s[1]}
          </Text>
          <Text key={`b-${fontFamily}-${fontWeight}-${s[1]}`} p={4} my="auto" bgColor="gray.500">
            {parseFloat(s[1].slice(0, -3)) * 16}px
          </Text>
          <Text
            key={`c-${fontFamily}-${fontWeight}-${s[1]}`}
            borderTop="1px solid white"
            borderTopColor="gray.200"
            p={4}
            {...{ fontFamily, fontWeight }}
            fontSize={s[1]}
            bgColor="gray.50"
          >
            {s[0]}
          </Text>
        </Fragment>
      ))}
  </Grid>
)

const Typography = ({ fontFamilies, ...props }: { fontFamilies?: FontFamilyType[] }) => {
  // type set to get better type auto-complete
  const theme: Theme & Record<string, any> = useTheme()

  return (
    <Wrap justify="center" {...props}>
      {fontFamilies &&
        fontFamilies.includes('Roboto') &&
        ['normal', 'medium', 'bold'].map((fontWeight) =>
          displayFontFamilyWeight({ fontFamily: 'Roboto', fontWeight, fontSizes: theme.fontSizes })
        )}
      {fontFamilies &&
        fontFamilies.includes('Montserrat') &&
        ['bold'].map((fontWeight) =>
          displayFontFamilyWeight({
            fontFamily: 'Montserrat',
            fontWeight,
            fontSizes: theme.fontSizes,
          })
        )}
    </Wrap>
  )
}

export default Typography
