import { centreClient } from './client'

export type CentreListedOutboundTicket = {
  id: number
  remote_user_id: string | null
  display_contract_id: string
  display_id: string
  elevator_name: string
  display_date: string
  net_weight: string | null
}

export type CentreOutboundTicketDetail = CentreListedOutboundTicket & {
  weigh_in_at: string | null
  weigh_out_at: string | null
  commodity_name: string
  unit_price: string | null
  tare_weight: string | null
  gross_weight: string | null
  vehicle_description: string | null
  po_number: string | null
  reference_number: string | null
  buyer_bol: string | null
  destination: string | null
  shipper: string | null
}

export const outboundTicketsRequests = {
  getOutboundTickets: async ({ page }: { page?: number } = {}) => {
    const params = new URLSearchParams(page ? { page: `${page}` } : undefined)
    return centreClient
      .get(`api/v1/outbound-tickets?${params.toString()}`)
      .json<{
        data: CentreListedOutboundTicket[]
        meta: { pagination: Pagination }
      }>()
      .then((response) => ({
        ...response,
        data: response.data.map(
          (ticket): ListedOutboundTicket => ({
            id: ticket.id,
            accountID: ticket.remote_user_id ?? undefined,
            contractNumber: ticket.display_contract_id,
            ticketNumber: ticket.display_id,
            elevatorName: ticket.elevator_name,
            date: ticket.display_date,
            netWeight: ticket.net_weight ?? undefined,
          })
        ),
      }))
  },

  getOutboundTicketDetail: async ({ id }: { id: number }) => {
    return centreClient
      .get(`api/v1/outbound-tickets/${id}`)
      .json<{ data: CentreOutboundTicketDetail }>()
      .then(
        ({ data: ticket }): OutboundTicketDetail => ({
          id: ticket.id,
          accountID: ticket.remote_user_id ?? undefined,
          contractNumber: ticket.display_contract_id,
          ticketNumber: ticket.display_id,
          elevatorName: ticket.elevator_name,
          date: ticket.display_date,
          netWeight: ticket.net_weight ?? undefined,
          weighInAt: ticket.weigh_in_at ?? undefined,
          weighOutAt: ticket.weigh_out_at ?? undefined,
          commodityName: ticket.commodity_name,
          unitPrice: ticket.unit_price ?? undefined,
          tareWeight: ticket.tare_weight ?? undefined,
          grossWeight: ticket.gross_weight ?? undefined,
          vehicleDescription: ticket.vehicle_description ?? undefined,
          poNumber: ticket.po_number ?? undefined,
          referenceNumber: ticket.reference_number ?? undefined,
          buyerBol: ticket.buyer_bol ?? undefined,
          destination: ticket.destination ?? undefined,
          shipper: ticket.shipper ?? undefined,
        })
      )
  },
}
