import dayjs from 'dayjs'
import { Text, Flex } from 'src/components/designsystem'
import { NotificationCellContainer } from './NotificationCellContainer'
import { HistoricalNotification } from 'src/api/notify-service/models/historical-notification'
import { NavigateToExternalLink } from 'src/components/utils/NavigateToExternalLink'
export interface NotificationCellProps {
  notification: HistoricalNotification
  idx: number
  arr: HistoricalNotification[]
  isBackToTopButtonShown: boolean
}
export function NotificationCell({
  notification,
  idx,
  arr,
  isBackToTopButtonShown,
}: Readonly<NotificationCellProps>) {
  const {
    sentAt,
    content: { title, summary, push },
  } = notification

  return (
    <NotificationCellContainer
      notification={notification}
      arr={arr}
      idx={idx}
      isBackToTopButtonShown={isBackToTopButtonShown}
    >
      <TitleAndTimeWrapper>
        <Title title={title} />
        <SentAtTime sentAt={sentAt} />
      </TitleAndTimeWrapper>
      <Summary summary={summary} />
      <NavigateToUrl push={push} />
    </NotificationCellContainer>
  )
}

const formattedSentDate = (sentDate) => {
  const dayJsSentDate = dayjs.utc(sentDate)
  const today = dayjs()
  const yesterday = dayjs().subtract(1, 'days')
  if (today.isSame(dayJsSentDate, 'day')) return dayJsSentDate.local().format('h:mm A')
  if (yesterday.isSame(dayJsSentDate, 'day')) return 'Yesterday'
  return dayJsSentDate.local().format('MM/DD/YYYY')
}

function NavigateToUrl({ push }) {
  return (
    <>
      {push?.data?.navigateToUrl && (
        <NavigateToExternalLink href={push?.data?.navigateToUrl} color="blue.400" fontWeight={600}>
          {push?.data?.urlButtonText}
        </NavigateToExternalLink>
      )}
    </>
  )
}

function Summary({ summary }) {
  return (
    <Text fontSize="md" color="gray.700" whiteSpace="pre-wrap">
      {summary}
    </Text>
  )
}

function TitleAndTimeWrapper({ children }) {
  return (
    <Flex flexDirection="row" justifyContent="space-between" alignItems="flex-start" gap={2.5}>
      {children}
    </Flex>
  )
}

function Title({ title }) {
  return (
    <Text fontSize="md" width="50%" color="gray.700" fontWeight="bold">
      {title}
    </Text>
  )
}

function SentAtTime({ sentAt }) {
  return (
    <Text fontSize="sm" color="gray.500">
      {formattedSentDate(sentAt)}
    </Text>
  )
}
