import { CollapsableDetailTable } from 'src/components/designsystem'
import { DisplayConfigItem } from 'src/components/designsystem/display-config'
import { DetailGrid, FormattedDate, useStackedDetailModal } from 'src/components/resource'
import { useSettlementAssociatedContracts } from 'src/data/queries'

const DISPLAY_CONFIG: DisplayConfigItem<SettlementAssociatedContract>[] = [
  {
    label: 'Contract',
    key: 'display_id',
  },
  {
    label: 'Application Date',
    key: 'application_date',
    renderValue: ({ data }) => <FormattedDate date={data.application_date} localize={false} />,
  },
  {
    label: 'Gross Units',
    key: 'gross_quantity',
  },
  {
    label: 'Net Units',
    key: 'net_quantity',
  },
]

export interface AssociatedTicketsProps {
  settlementId: number
}

export default function AssociatedContracts({ settlementId }: AssociatedTicketsProps) {
  const { data: associatedContracts } = useSettlementAssociatedContracts({ id: settlementId })
  const { setItem } = useStackedDetailModal()

  if (!associatedContracts || associatedContracts.length === 0) return null

  return (
    <>
      <DetailGrid.Title>Associated Contracts</DetailGrid.Title>
      <CollapsableDetailTable
        rowItems={associatedContracts}
        rowItemTitle="Contracts"
        displayConfig={DISPLAY_CONFIG}
        analyticsCategory="Settlements"
        analyticsAction="Associated Contracts List"
        setItem={(item) => setItem({ type: 'contract', item })}
        shouldShowViewButton={(rowItem) => !!rowItem.id}
      />
    </>
  )
}
