import { Box, Heading, IconButton, BackArrowIcon, Text } from 'src/components/designsystem'
import { useStore } from 'src/components/report-issue/helpers'

export function Header({
  showBack = true,
  title,
  subtitle,
  preamble,
}: {
  showBack?: boolean
  title?: string
  subtitle?: string
  preamble?: string
}) {
  const { stepBack } = useStore()

  return (
    <Box>
      <Box minH="2rem">
        {showBack && (
          <IconButton
            icon={<BackArrowIcon />}
            variant="ghostSecondary"
            fontSize="1.5rem"
            aria-label="Back"
            minW="auto"
            onClick={stepBack}
          />
        )}
      </Box>

      {title && (
        <Heading type="h2" fontSize={['md', null, '2xl']} mt={5} mb={5}>
          {title}
        </Heading>
      )}
      {subtitle && (
        <Text fontWeight="bold" mt={5} mb={5}>
          {subtitle}
        </Text>
      )}
      <Text>{preamble}</Text>
    </Box>
  )
}
