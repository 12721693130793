import { useCallback } from 'react'
import { Button, Flex, Spacer, Divider, type ButtonProps } from 'src/components/designsystem'

export interface FilterFooterProps {
  /** the label displayed on the `Clear` Button; defaults to 'CLEAR' */
  clearButtonLabel?: string
  /** onClear is called when the `Clear` Button is clicked */
  onClear: () => void
  /** onSet is called when the `Set` Button is clicked */
  onSet: () => void
  /** the label displayed on the `Set` Button; defaults to 'Set' */
  setButtonLabel?: string
  setButtonProps?: Partial<ButtonProps>
  clearButtonProps?: Partial<ButtonProps>
}

/**
 * FilterFooter renders a clear and set button,
 * to be used on the bottom of each UI grouping of filters.
 */
export const FilterFooter = ({
  clearButtonLabel,
  onClear,
  onSet,
  setButtonLabel,
  setButtonProps,
  clearButtonProps,
}: FilterFooterProps) => {
  const onClearHandler = useCallback(() => onClear(), [onClear])
  const onSetHandler = useCallback(() => onSet(), [onSet])
  return (
    <>
      <Divider />
      <Flex>
        <Button
          flex={1}
          data-testid="filter-clear-button"
          variant="ghostDestructive"
          size="md"
          borderRadius="0"
          borderBottomLeftRadius="md"
          onClick={onClearHandler}
          {...clearButtonProps}
        >
          {clearButtonLabel ?? 'Clear'}
        </Button>
        <Spacer />
        <Button
          flex={1}
          data-testid="filter-set-button"
          variant="ghost"
          size="md"
          borderRadius="0"
          borderBottomRightRadius="md"
          onClick={onSetHandler}
          {...setButtonProps}
        >
          {setButtonLabel ?? 'Set'}
        </Button>
      </Flex>
    </>
  )
}
