import { useFeatureFlag } from 'configcat-react'
import { type FeatureName } from 'src/__fixtures__/featureConfig'
import { useConfig } from 'src/data/config'
import { CONFIGCAT_FLAGS } from 'src/utils/config-cat'

/*
 * @param {FeatureName} featureName - The feature name to check for an alternative name (e.g. "cash_bids", "futures").
 * @param {string} standardFeatureName - The standard feature name to use if no alternative name is found (e.g. "Cash Bids", "Futures")
 * @returns {string} - The alternative feature name if it exists, otherwise the standard feature name.
 */
export default function useAlternateFeatureName(
  featureName: FeatureName,
  standardFeatureName: string
): { featureName: string; isLoading: boolean } {
  const { config } = useConfig()

  const { value, loading } = useFeatureFlag(
    CONFIGCAT_FLAGS.isPageHeadingAlternateFeatureNamesEnabled,
    false
  )

  const alternativeFeatureNames = config?.config?.alternative_feature_names

  return {
    featureName:
      value && alternativeFeatureNames?.[featureName]
        ? alternativeFeatureNames[featureName]
        : standardFeatureName,
    isLoading: loading,
  }
}
