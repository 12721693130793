import { datadogRum } from '@datadog/browser-rum-slim'
import {
  ConfigCatProvider as ConfigCatProviderBase,
  LogLevel,
  PollingMode,
  User,
  createConsoleLogger,
  useConfigCatClient,
} from 'configcat-react'
import { useEffect } from 'react'
import useConfigCatUser from 'src/utils/config-cat/hooks/useConfigCatUser'
import { ENV } from 'src/utils/env'

type ConfigCatProviderProps = Readonly<{
  configCatUser?: User
  children: React.ReactNode
}>

/**
 * Wrapper for ConfigCatProvider to de-clutter the AppProviders component
 * - Optionally accepts a user object to set as the default user from the server
 */
export default function ConfigCatProvider({ configCatUser, children }: ConfigCatProviderProps) {
  return (
    <ConfigCatProviderBase
      pollingMode={PollingMode.AutoPoll}
      sdkKey={ENV.CONFIGCAT_SDK_KEY}
      options={{
        baseUrl: ENV.CONFIGCAT_BASE_URL,
        logger: createConsoleLogger(LogLevel.Error),
        defaultUser: configCatUser,
        setupHooks: (hooks) =>
          hooks.on('flagEvaluated', ({ key, value }) =>
            datadogRum.addFeatureFlagEvaluation(key, value)
          ),
      }}
    >
      <ConfigCatClientUserSync>{children}</ConfigCatClientUserSync>
    </ConfigCatProviderBase>
  )
}

type ConfigCatClientUserSyncProps = Readonly<{
  children: React.ReactNode
}>

/**
 * Custom component to sync the authenticated user with ConfigCat
 * This is intentionally a component since useConfigCatClient relies on the context
 * from ConfigCatProvider, and must be a child of it.
 */
function ConfigCatClientUserSync({ children }: ConfigCatClientUserSyncProps) {
  const client = useConfigCatClient()
  const user = useConfigCatUser()

  useEffect(() => {
    if (user) {
      client.setDefaultUser(user)
    }
  }, [client, user])

  return <>{children}</>
}
