import { useMemo } from 'react'
import { CarrotDownIcon, CarrotUpIcon, Flex, Stack, Text } from 'src/components/designsystem'
import useColumnSortDirection from 'src/components/resource/hooks/useColumnSortDirection'
import { ListHeaderItemProps } from './ListHeaderItem'
import useSyncRouterWithSort from 'src/components/resource/hooks/useSyncRouterWithSort'

export type ListHeaderItemSortButtonProps = Readonly<
  Required<Pick<ListHeaderItemProps, 'label' | 'sortFieldKey'>>
>

export default function SortableListHeaderItemButton({
  label,
  sortFieldKey,
}: ListHeaderItemSortButtonProps) {
  useSyncRouterWithSort()
  const { key, direction, handleOnClick } = useColumnSortDirection()

  const isActiveKey = key === sortFieldKey
  const carrotUpColor = isActiveKey && direction === 'ASC' ? 'blue.500' : 'gray.300'
  const carrotDownColor = isActiveKey && direction === 'DESC' ? 'blue.500' : 'gray.300'

  const nextSortLabel = useMemo(() => {
    switch (direction) {
      case 'ASC':
        return `Sort by ${label} in descending order`
      case 'DESC':
        return `Stop sorting by ${label}`
      default:
        return `Sort by ${label} in ascending order`
    }
  }, [label, direction])

  return (
    <Flex
      role="button"
      cursor="pointer"
      userSelect="none"
      alignItems="center"
      title={nextSortLabel}
      justifyContent="center"
      aria-label={nextSortLabel}
      onClick={() => handleOnClick(sortFieldKey)}
    >
      <Text textStyle="extra-small-bold">{label}</Text>
      <Stack pl={4} spacing={0.5} data-testid={`sort-${sortFieldKey}`}>
        <CarrotUpIcon
          fontSize={13}
          color={carrotUpColor}
          transition="color 0.2s cubic-bezier(0.4, 0, 0.2, 1)"
        />
        <CarrotDownIcon
          fontSize={13}
          color={carrotDownColor}
          transition="color 0.2s cubic-bezier(0.4, 0, 0.2, 1)"
        />
      </Stack>
    </Flex>
  )
}
