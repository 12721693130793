import { v4 as uuidV4 } from 'uuid'
import { registrantIdStorage } from './registrant-id-storage'

export default async function getRegistrantId(): Promise<string> {
  const storedRegistrantId = await registrantIdStorage.get()

  if (storedRegistrantId) {
    return storedRegistrantId
  } else {
    return createAndStoreRegistrantId()
  }
}

function createAndStoreRegistrantId(): string {
  const registrantId = createRegistrantId()
  registrantIdStorage.set(registrantId)
  return registrantId
}

function createRegistrantId(): string {
  return uuidV4()
}
