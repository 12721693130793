import { useEffect } from 'react'
import { useAuth } from 'src/auth'
import { useConfig } from 'src/data/config'
import { trackPageView } from 'src/utils/analytics'

export function useAppData() {
  const auth = useAuth()
  const configData = useConfig()

  return {
    auth,
    ...configData,
  }
}

export function useNavigation({
  pageTitle,
  activeItem,
  analyticsTitle,
}: {
  pageTitle?: string
  activeItem?: string
  analyticsTitle: string
}) {
  const { setNavData } = useAppData()

  useEffect(
    () => {
      if (analyticsTitle) trackPageView(analyticsTitle)

      if (!pageTitle && !activeItem) return
      setNavData({ pageTitle, activeItem })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
}

export * from './exports'
