import { nativePushNotifications } from 'src/utils/clients/native/native-push-notifications'
import { NotificationEnrollmentStatus } from './notification-enrollment-status'

export async function getNotificationEnrollmentStatus(): Promise<NotificationEnrollmentStatus> {
  const permissionStatus = await nativePushNotifications.checkPermissions()

  switch (permissionStatus) {
    case 'granted':
      return 'complete'
    case 'denied':
      return 'complete'
    case 'pending':
      return 'incomplete'
  }
}
