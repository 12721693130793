import { insecureNativeStorage } from 'src/utils/storage/insecure/native/insecure-native-storage'
import { compareIgnoringCase } from 'src/utils/string/compare-ignoring-case'

export const iosApp = {
  isDebugBuild,
}

async function isDebugBuild(): Promise<boolean> {
  const buildConfiguration = await getBuildConfiguration()
  return compareIgnoringCase(buildConfiguration ?? '', 'debug')
}

async function getBuildConfiguration(): Promise<string | undefined> {
  return insecureNativeStorage.get('bwp-ios-build-configuration')
}
