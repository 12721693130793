import { ReactNode, useCallback } from 'react'
import {
  Box,
  Button,
  CloseIcon,
  IconButton,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  UseDisclosureProps,
} from '..'

export interface FilterDrawerProps {
  children: ReactNode
  disclosure: UseDisclosureProps
  footerOnClear: () => void
  footerOnSet: () => void
  setButtonLabel: string
}

/* istanbul ignore next: currently too difficult to test */
export function FilterDrawer({
  children,
  disclosure,
  footerOnClear,
  footerOnSet,
  setButtonLabel,
}: FilterDrawerProps) {
  const { isOpen, onClose } = disclosure
  const footerOnClearHandler = useCallback(() => footerOnClear(), [footerOnClear])
  const footerOnSetHandler = useCallback(() => footerOnSet(), [footerOnSet])

  return (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent>
          <Flex
            flexDir="column"
            width="full"
            height="full"
            pt="calc(env(safe-area-inset-top) - 8px)"
            pb={['calc(env(safe-area-inset-bottom) - 8px)', 'calc(env(safe-area-inset-bottom))']}
          >
            <Flex justifyContent="space-between" py={2} mx={1}>
              <IconButton
                variant="unstyled"
                onClick={onClose}
                aria-label="Close Filter"
                w={10}
                minW="unset"
                borderRadius="full"
                icon={<CloseIcon fontSize="2xl" />}
              />

              <Button variant="ghostDestructive" onClick={footerOnClearHandler}>
                Clear All
              </Button>
            </Flex>

            <Flex direction="column" width="full" height="full" overflow="scroll">
              {children}
            </Flex>

            <Box p={4}>
              <Button
                width="full"
                variant="primary"
                onClick={footerOnSetHandler}
                data-testid="filter-menu-set-button"
              >
                {setButtonLabel}
              </Button>
            </Box>
          </Flex>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
}
