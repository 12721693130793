import { memo } from 'react'
import {
  CheckboxFilter,
  DateFilter,
  Filters,
  MoreFilters,
  useBreakpoint,
} from 'src/components/designsystem'
import { ContractV3Filters } from 'src/components/contracts/contracts-v3/ContractFilters'
import {
  DateFilterOptionItem,
  FilterOptionCollection,
  filterOptionCollectionsToFilterOptionItems,
  FilterOptionItem,
  filterOptionItemsToFilterOptionCollections,
  filterOptionItemsToStringArray,
  FiltersProps,
  stringArrayToFilterOptionItems,
} from 'src/utils/filters'
import { useConfig } from 'src/data/config'

export interface APIContractFilterOptions {
  accounts?: FilterOptionItem[]
  companies?: FilterOptionItem[]
  contracted_locations?: FilterOptionItem[]
  crop_years?: string[]
  crops?: FilterOptionCollection[]
  dates?: DateFilterOptionItem
  delivery_locations?: FilterOptionItem[]
  detailed_status?: string[]
  types?: string[]
}

export interface ContractFiltersForQuery {
  accounts?: string[]
  companies?: string[]
  contracted_locations?: string[]
  crop_years?: string[]
  crops?: string[]
  dates?: {
    from: string
    to: string
  }
  delivery_locations?: string[]
  detailed_status?: string[]
}

export interface APIContractV3FilterOptions {
  accounts?: FilterOptionItem[]
  commodities?: FilterOptionCollection[]
  companies?: FilterOptionItem[]
  crop_years?: string[]
  dates?: DateFilterOptionItem
  locations_contract?: FilterOptionItem[]
  locations_delivery?: FilterOptionItem[]
  statuses_contract?: string[]
  statuses_signature?: string[]
  types?: string[]
}

export interface ContractV3FiltersForQuery {
  accounts?: string[]
  commodities?: string[]
  companies?: string[]
  crop_years?: string[]
  dates?: { from: string; to: string }
  locations_contract?: string[]
  locations_delivery?: string[]
  statuses_contract?: string[]
  statuses_signature?: string[]
  search_query?: string
}

export type ContractFiltersProps = FiltersProps<APIContractFilterOptions>

export const ContractFilters = memo(function ContractFilters({
  filterOptions,
  allSelectedFilters,
  setAllSelectedFilters,
  setFilterById,
  isLoading = false,
}: ContractFiltersProps) {
  const { isDesktop } = useBreakpoint()
  const { contracts } = useConfig()
  const contractsVersion = contracts.contractsVersion()

  if (!isLoading && (!filterOptions || Object.keys(filterOptions).length < 1)) return null

  if (contractsVersion === '3.0.0') {
    return (
      <ContractV3Filters
        {...{
          allSelectedFilters,
          filterOptions,
          isLoading,
          setAllSelectedFilters,
          setFilterById,
          isDesktop,
        }}
      />
    )
  }

  return (
    <Filters
      {...{
        allSelectedFilters,
        filterOptions,
        isLoading,
        skeletonCount: 4,
        setAllSelectedFilters,
        setFilterById,
      }}
    >
      {filterOptions?.crops?.length > 1 && (
        <CheckboxFilter
          filterId="crops"
          label="Commodity"
          options={filterOptionCollectionsToFilterOptionItems(filterOptions.crops)}
          values={filterOptionCollectionsToFilterOptionItems(allSelectedFilters?.crops)}
          onChange={(selected) => {
            setFilterById(
              'crops',
              filterOptionItemsToFilterOptionCollections(selected?.length > 0 ? selected : null),
              ['Contracts', 'Filter/Commodity']
            )
          }}
        />
      )}

      {filterOptions?.dates && (
        <DateFilter
          filterId="dates"
          label="Delivery Date Range"
          value={allSelectedFilters?.dates}
          onChange={(selected) => {
            setFilterById('dates', selected.from || selected.to ? selected : null, [
              'Contracts',
              'Filter/Delivery Date Range',
            ])
          }}
          initialShowPresets={isDesktop}
        />
      )}

      {filterOptions?.detailed_status?.length > 1 && (
        <CheckboxFilter
          filterId="detailed_status"
          label="Status"
          options={stringArrayToFilterOptionItems(filterOptions.detailed_status)}
          values={stringArrayToFilterOptionItems(allSelectedFilters?.detailed_status)}
          onChange={(selected) => {
            setFilterById(
              'detailed_status',
              filterOptionItemsToStringArray(selected?.length > 0 ? selected : null),
              ['Contracts', 'Filter/Status']
            )
          }}
        />
      )}

      {filterOptions?.types?.length > 1 && (
        <CheckboxFilter
          filterId="types"
          label="Contract Type"
          options={stringArrayToFilterOptionItems(filterOptions.types)}
          values={stringArrayToFilterOptionItems(allSelectedFilters?.types)}
          onChange={(selected) => {
            setFilterById(
              'types',
              filterOptionItemsToStringArray(selected?.length > 0 ? selected : null),
              ['Contracts', 'Filter/Type']
            )
          }}
        />
      )}

      {showMore(filterOptions) && (
        <MoreFilters label="More" isMore>
          {filterOptions?.crop_years?.length > 1 && (
            <CheckboxFilter
              filterId="crop_years"
              label="Crop Year"
              options={stringArrayToFilterOptionItems(filterOptions.crop_years)}
              values={stringArrayToFilterOptionItems(allSelectedFilters?.crop_years)}
              onChange={(selected) => {
                setFilterById(
                  'crop_years',
                  filterOptionItemsToStringArray(selected?.length > 0 ? selected : null),
                  ['Contracts', 'Filter/Crop Year']
                )
              }}
              isScrollable={false}
            />
          )}

          {filterOptions.accounts?.length > 1 && (
            <CheckboxFilter
              filterId="accounts"
              label="Account"
              options={filterOptions.accounts}
              values={allSelectedFilters?.accounts}
              isScrollable={false}
              onChange={(selected) => {
                setFilterById('accounts', selected?.length > 0 ? selected : null, [
                  'Contracts',
                  'Filter/Account',
                ])
              }}
            />
          )}

          {filterOptions.contracted_locations?.length > 1 && (
            <CheckboxFilter
              filterId="contracted_locations"
              label="Contracted Location"
              options={filterOptions.contracted_locations}
              values={allSelectedFilters?.contracted_locations}
              isScrollable={false}
              onChange={(selected) => {
                setFilterById('contracted_locations', selected?.length > 0 ? selected : null, [
                  'Contracts',
                  'Filter/Contracted Location',
                ])
              }}
            />
          )}

          {filterOptions.delivery_locations?.length > 1 && (
            <CheckboxFilter
              filterId="delivery_locations"
              label="Delivery Location"
              options={filterOptions.delivery_locations}
              values={allSelectedFilters?.delivery_locations}
              isScrollable={false}
              onChange={(selected) => {
                setFilterById('delivery_locations', selected?.length > 0 ? selected : null, [
                  'Contracts',
                  'Filter/Delivery Location',
                ])
              }}
            />
          )}
        </MoreFilters>
      )}
    </Filters>
  )
})

export function showMore(filterOptions) {
  if (
    filterOptions?.crop_years?.length > 1 ||
    filterOptions?.accounts?.length > 1 ||
    filterOptions?.contracted_locations?.length > 1 ||
    filterOptions?.delivery_locations?.length > 1 ||
    filterOptions?.locations_contract?.length > 1 ||
    filterOptions?.locations_delivery?.length > 1 ||
    filterOptions?.companies?.length > 1
  ) {
    return true
  }
  return false
}
