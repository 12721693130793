import { useMemo } from 'react'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { useMerchandiser } from 'src/data/merchandiser'
import * as api from 'src/api'

export function useCommodityBalances() {
  const { selectedUserIdpId } = useMerchandiser()

  const query = useInfiniteQuery({
    queryKey: ['commodity-balances', { selectedUserIdpId }],
    queryFn: () => api.centre.commodityBalances(),
    initialPageParam: 1,
    meta: {
      errorMessage: 'Unable to retrieve balances, please refresh the page to try again.',
    },
    // This API isn't actually paged, are using `useInfiniteQuery` for consistency
    getNextPageParam: () => undefined,
  })

  const mergedPageData = useMemo(() => {
    // Infinite queries contain an array of page data
    // This reduces them down to just one big list of the items
    return (query.data?.pages || []).reduce((acc, page) => [...acc, ...page.data], [])
  }, [query.data?.pages])

  return { query, data: mergedPageData }
}

export function useCommodityBalanceDetail({
  cropSlug,
  remoteUserId,
  enabled,
}: {
  cropSlug: string
  remoteUserId: string
  enabled: boolean
}) {
  return useQuery<CommodityBalanceDetail, api.HTTPError>({
    queryKey: ['commodity-balance-detail', { cropSlug, remoteUserId }],
    queryFn: async () => api.centre.commodityBalanceDetail({ cropSlug, remoteUserId }),
    enabled,
    staleTime: Infinity,
  })
}
