import { HTTPError } from 'ky'

export class HttpClientRequestError extends Error {
  private request: Request
  private response: Response

  constructor(error: HTTPError) {
    super('HTTP Client Request Error')
    this.name = this.constructor.name
    this.request = error.request
    this.response = error.response
  }

  async getRequestDetails() {
    return {
      method: this.request.method,
      url: this.request.url,
      body: await this.extractRequestBody(),
    }
  }

  private async extractRequestBody() {
    try {
      return await this.request.json()
    } catch (error) {}

    try {
      return await this.request.text()
    } catch (error) {}

    return 'The HTTP client failed to extract the body from the request while preparing this log'
  }

  async getResponseDetails() {
    return {
      status: this.response.status,
      body: await this.extractResponseBody(),
    }
  }

  private async extractResponseBody() {
    try {
      return await this.response.json()
    } catch (error) {}

    try {
      return await this.response.text()
    } catch (error) {}

    return 'The HTTP client failed to extract the body from the response while preparing this log'
  }
}
