import { PluginListenerHandle } from '@capacitor/core'
import {
  RegistrationError,
  pushNotificationsPluginWrapper,
} from 'src/utils/clients/native/wrappers/push-notifications-plugin-wrapper'
import logger from 'src/utils/logger'
import { Optional } from 'src/utils/types/optional'

export const nativePushNotifications = {
  checkPermissions,
  enabled,
  getToken,
}

export type PushNotificationPermissionState = 'granted' | 'denied' | 'pending'

async function checkPermissions(): Promise<PushNotificationPermissionState> {
  const permission = await pushNotificationsPluginWrapper.checkPermissions()

  switch (permission.receive) {
    case 'granted':
      return 'granted'
    case 'denied':
      return 'denied'
    case 'prompt':
      return 'pending'
    case 'prompt-with-rationale':
      return 'pending'
  }
}

async function enabled(): Promise<boolean> {
  const permission = await checkPermissions()
  return permission === 'granted'
}

async function getToken(): Promise<string> {
  const permission = await pushNotificationsPluginWrapper.requestPermissions()

  if (permission.receive === 'granted') {
    return register()
  } else {
    return rejectForDeniedPermission()
  }
}

function rejectForDeniedPermission() {
  const error = new Error('Push notification permission not granted')
  return Promise.reject(error)
}

function register(): Promise<string> {
  let successListener: Optional<PluginListenerHandle> = undefined
  let errorListener: Optional<PluginListenerHandle> = undefined

  return new Promise<string>(async (resolve, reject) => {
    successListener = await addRegistrationSuccessListener(resolve)
    errorListener = await addRegistrationErrorListener(reject)
    await pushNotificationsPluginWrapper.register()
  }).finally(() => {
    successListener?.remove()
    errorListener?.remove()
  })
}

function addRegistrationSuccessListener(resolve: (token: string) => void) {
  return pushNotificationsPluginWrapper.addListener('registration', (token) => {
    resolve(token.value)
  })
}

function addRegistrationErrorListener(reject: (error: RegistrationError) => void) {
  return pushNotificationsPluginWrapper.addListener('registrationError', (error) => {
    logRegistrationErrorIfNeeded(error)
    reject(error)
  })
}

function logRegistrationErrorIfNeeded(error: RegistrationError) {
  const errorShouldBeLogged = checkIfRegistrationErrorShouldBeLogged(error)

  if (errorShouldBeLogged) {
    logRegistrationError(error)
  }
}

function checkIfRegistrationErrorShouldBeLogged(error: RegistrationError): boolean {
  const errorMessage = error.error
  const tooManyAppsReceivingPushes = 'TOO_MANY_REGISTRATIONS'
  const missingGoogleServices = 'MISSING_INSTANCEID_SERVICE'

  if (
    errorMessage.includes(tooManyAppsReceivingPushes) ||
    errorMessage.includes(missingGoogleServices)
  ) {
    // NOTE: We suppress these errors as they seem to arise from Google's testers.
    // Logging them would bury more meaningful errors.
    return false
  } else {
    return true
  }
}

function logRegistrationError(error: RegistrationError) {
  logger.error({
    message: 'Push Notification Registration Error',
    context: error,
  })
}
