import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import * as api from 'src/api'
import { InvoiceFilterResponse, InvoiceReqestBody, InvoiceResponse } from 'src/api/invoices'
import { APIInvoiceFilterOptions } from 'src/components/invoices/InvoiceFilters'
import { useMerchandiser } from 'src/data/merchandiser'
import {
  getNextPageParam,
  UseInfiniteQueryOptionsTyped,
  useInfiniteQueryTyped,
  UseQueryOptionsTyped,
  useQueryTyped,
} from 'src/data/queries/utils'

export function useInvoiceFilterOptions(
  options: UseQueryOptionsTyped<
    InvoiceFilterResponse,
    Record<string, never>,
    APIInvoiceFilterOptions
  > = {}
) {
  const { selectedUserIdpId } = useMerchandiser()

  return useQueryTyped({
    queryKey: ['invoice-filter-options', { selectedUserIdpId }],
    queryFn: () => api.centre.invoiceFilterOptions(),
    select: (data) => data.data,
    staleTime: Infinity,
    ...options,
  })
}

export function useInvoices(
  { filter, userId }: { userId?: string } & InvoiceReqestBody = {},
  options: UseInfiniteQueryOptionsTyped<InvoiceResponse> = {}
) {
  const { selectedUserIdpId: merchandiserSelectedUserIdpId } = useMerchandiser()
  const selectedUserIdpId = userId ?? merchandiserSelectedUserIdpId

  const query = useInfiniteQueryTyped({
    queryKey: ['invoices', { selectedUserIdpId, filter }],
    queryFn: ({ pageParam }) => api.centre.invoices({ filter, page: pageParam ?? 1 }),
    initialPageParam: 1,
    meta: {
      errorMessage: 'Unable to retrieve invoices, please refresh the page to try again.',
    },
    getNextPageParam,
    staleTime: Infinity,
    ...options,
  })

  const mergedPageData: Invoice[] = useMemo(
    () => query.data?.pages.flatMap((page) => page.data) ?? [],
    [query.data?.pages]
  )

  return { query, data: mergedPageData }
}

export function useInvoiceDetail({ id, enabled }: { id: number; enabled: boolean }) {
  return useQuery<InvoiceDetail, api.HTTPError>({
    queryKey: ['invoice-detail', { id }],
    queryFn: async () => api.centre.invoiceDetail(id),
    enabled,
    staleTime: Infinity,
  })
}
