import { bidlClient } from 'src/api/bidl/client'

export const AGGREGATOR_AUTH_CONFIG_BASE = 'api/aggregator/auth'

export const authRequests = {
  getActiveUsersFromIdentifiers: (ids: string[]) =>
    bidlClient
      .post(`${AGGREGATOR_AUTH_CONFIG_BASE}/GetActiveUsersFromIdentifiers`, {
        json: {
          userIdpIds: ids,
        },
      })
      .json<GetActiveUsersFromIdentifiersResponse>(),
}
