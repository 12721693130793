import { centreClient } from './client'

export interface ESignContractsResponse {
  data: ESignContract[]
}

export interface ESignRemindersResponse {
  data: {
    show_reminder: boolean
    unsigned_document_message: string
    unsigned_document_count: number
  }
}

export const eSignRequests = {
  eSignContracts: () => {
    return centreClient.get('api/v1/esign').json<ESignContractsResponse>()
  },

  eSignToken: async () => {
    const response = await centreClient.get('api/v1/esign/token').json<{ data: ESignToken }>()
    return response.data
  },

  eSignReminders: () => {
    return centreClient.get('api/v1/esign-reminders').json<ESignRemindersResponse>()
  },

  eSignRemindersDismiss: () => {
    return centreClient.post('api/v1/esign-reminders/dismiss')
  },
}
