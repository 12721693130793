import { CashBidResponse } from 'src/api/cash-bids/types/CashBidResponse'
import { futuresServiceClient } from './client'

export const cashBidRequests = {
  cashBids: async (limit?: number, client = futuresServiceClient) => {
    let options = {}

    if (limit) {
      options = { ...options, searchParams: { limit } }
    }
    return client.get('api/v1/cash-bids', options).json<CashBidResponse>()
  },
}
