import { Flex, Text } from 'src/components/designsystem'
import { type DisplayConfigItem } from 'src/components/designsystem/display-config'
import SortableListHeaderItemButton from 'src/components/resource/header/SortableListHeaderItemButton'
import { labelToTestId } from 'src/utils/string/label-to-test-id'

export type ListHeaderItemProps = Readonly<
  Pick<DisplayConfigItem<unknown>, 'label' | 'textAlign' | 'sortFieldKey'>
>

export default function ListHeaderItem({ label, textAlign, sortFieldKey }: ListHeaderItemProps) {
  return (
    <Flex
      pr={[0, null, null, 2, 8]}
      justifyContent={textAlign === 'right' ? 'flex-end' : 'unset'}
      data-testid={`column-header-${labelToTestId(label)}`}
    >
      {sortFieldKey ? (
        <SortableListHeaderItemButton {...{ label, sortFieldKey }} />
      ) : (
        <Text textStyle="extra-small-bold">{label}</Text>
      )}
    </Flex>
  )
}
