import { queryOptions, useQuery } from '@tanstack/react-query'
import * as api from 'src/api'
import { allAuthzPermissions } from 'src/api/bidl/iam/authz-permissions'
import { useTenantId } from 'src/data/queries/config'
import { LookupResourcesResponse } from 'src/api/bidl/iam/access-evaluate'
import { HTTPError } from 'src/api'

type LookupResourcesOptionsArgs = {
  permissions?: string[]
  tenantId?: string
}

export function lookupResourcesOptions<T>({
  permissions = allAuthzPermissions,
  tenantId = 'any',
}: LookupResourcesOptionsArgs) {
  return queryOptions<LookupResourcesResponse, HTTPError, T>({
    queryKey: ['lookup-resources', { permissions, tenantId }],
    queryFn: () => api.iamService.lookupResources(permissions, tenantId),
    enabled: !!tenantId,
    gcTime: Infinity,
  })
}

type LookupResourcesQueryOptions<T> = ReturnType<typeof lookupResourcesOptions<T>>

export function useLookupResources<T>(
  { permissions, tenantId }: LookupResourcesOptionsArgs,
  options: Partial<LookupResourcesQueryOptions<T>> = {}
) {
  return useQuery<LookupResourcesResponse, HTTPError, T>({
    ...lookupResourcesOptions({ permissions, tenantId }),
    ...options,
  })
}

export function useListTenantPermissions(
  options: Partial<LookupResourcesQueryOptions<string[]>> = {}
) {
  const { data: tenantId } = useTenantId()

  return useLookupResources<string[]>(
    { permissions: allAuthzPermissions, tenantId },
    {
      ...options,
      select: (data) =>
        data.results
          .filter((result) => {
            return result.root || result.tenants.some((tenant) => tenant.tenantId === tenantId)
          })
          .map((result) => result.permission),
    }
  )
}
