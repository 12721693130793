import getConfigCatUser from 'src/utils/config-cat/getConfigCatUser'
import { ENV } from 'src/utils/env'
import * as configCatSSR from 'configcat-js-ssr'
import { PollingMode } from 'configcat-js-ssr'
import { GetServerSidePropsContext } from 'next'
import { getServerSession } from 'next-auth/next'
import { AppContext } from 'next/app'
import { parseCookies } from 'nookies'
import { authOptions } from 'src/pages/api/auth/[...nextauth].api'
import logger from 'src/utils/logger'
import { datadogRum } from '@datadog/browser-rum-slim'

const SDK_KEY = ENV.CONFIGCAT_SDK_KEY
const BASE_URL = ENV.CONFIGCAT_BASE_URL

/**
 * The ConfigCat SSR client is used to evaluate flags in server side code
 * !Depends on the ConfigCat Proxy being publicly accessible (work is in progress at ConfigCat to make this available server-side without exposing it to the public)
 */
export const configCatSSRClient = configCatSSR.getClient(SDK_KEY, PollingMode.AutoPoll, {
  baseUrl: BASE_URL,
  logger: configCatSSR.createConsoleLogger(configCatSSR.LogLevel.Error),
  setupHooks: (hooks) =>
    hooks.on('flagEvaluated', ({ key, value }) => datadogRum.addFeatureFlagEvaluation(key, value)),
})

/**
 * Evaluates all flags via the ConfigCat SSR client
 * @param context - The Next.js AppContext
 * @returns All flag data from ConfigCat
 */
export async function evaluateAllFlagsSSR(context: AppContext) {
  try {
    const user = await getConfigCatUserFromContext(context)
    return await configCatSSRClient.getAllValuesAsync(user)
  } catch (error) {
    logger.error({
      message: 'Failed to evaluate flags via configCatSSRClient',
      error,
    })
    throw error
  }
}

/**
 * Evaluates a single flag via the ConfigCat SSR client
 * @param context - The Next.js AppContext
 * @param key - The flag key
 * @param defaultValue - The default value to return if the flag is not found
 * @returns
 */
export async function evaluateFlagSSR<T extends configCatSSR.SettingValue>(
  context: AppContext,
  key: string,
  defaultValue: T
) {
  try {
    const user = await getConfigCatUserFromContext(context)
    return await configCatSSRClient.getValueAsync(key, defaultValue, user)
  } catch (error) {
    logger.error({
      message: `Failed to evaluate flag: ${key} via configCatSSRClient`,
      error,
    })
    throw error
  }
}

/**
 * Takes the NextAuth session and cookies and returns the ConfigCat user object
 * @param context - The Next.js AppContext
 * @returns - The ConfigCat user object
 */
async function getConfigCatUserFromContext(context: AppContext) {
  const { req, res } = context.ctx
  const cookies = parseCookies({ req })
  // res is not allowed to be undefined, but the typing from the ctx object allows it
  // @ts-ignore
  const session = await getServerSession(req as GetServerSidePropsContext['req'], res, authOptions)

  return getConfigCatUser(session, cookies)
}
