import { useMemo } from 'react'
import {
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  useSteps,
} from '@chakra-ui/react'

export function useStepper(stepCount: number) {
  const stepper = useSteps({ index: 0, count: stepCount })

  const stepsDisplay = useMemo(
    () =>
      stepCount > 0 && (
        <Stepper index={stepper.activeStep}>
          {new Array(stepCount).fill(0).map((_, index) => (
            <Step key={`step-${index}`}>
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>
              <StepSeparator />
            </Step>
          ))}
        </Stepper>
      ),
    [stepper.activeStep, stepCount]
  )

  return { stepper, stepsDisplay }
}
