import { useState, useCallback } from 'react'

export const getLocalStorageItemByKey = (key: string, defaultValue: any = null) => {
  if (typeof window !== 'undefined') {
    const stored = window.localStorage.getItem(key)
    if (!stored) return defaultValue

    return JSON.parse(stored)
  }
}

export const setLocalStorageItem = (key: string, value: any) => {
  window.localStorage.setItem(key, JSON.stringify(value))
}

export const removeLocalStorageKey = (key: string) => {
  window.localStorage.removeItem(key)
}

export function useLocalStorage<T>(key: string, initialValue?: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    return getLocalStorageItemByKey(key, initialValue)
  })

  const setValue = useCallback(
    (value: T | ((val: T) => T)) => {
      try {
        const valueToStore =
          value instanceof Function ? value(getLocalStorageItemByKey(key, initialValue)) : value
        setStoredValue(valueToStore)
        setLocalStorageItem(key, valueToStore)
      } catch (error) {
        console.log(error)
      }
    },
    [key, initialValue]
  )
  return [storedValue, setValue] as const
}
