import { RefObject, useCallback, useEffect, useRef } from 'react'
import { useBackStack } from 'src/utils/clients/native/android-back-stack'

const triggerAreaWidth = 50
const peakingWidth = 25

const triggerAreaZIndex = 999 // this is one less than any floating Chakra zIndex - see Chakra theme for values

const minimumPercentOfDistanceBeforeActionFinished = 0.25

const swipeThreshold = 5

type useSwipeableSidebarProps = {
  sidebarRef: RefObject<HTMLDivElement>
  backdropRef: RefObject<HTMLDivElement>
  isPinned: boolean
  setIsPinned: (isPinned: boolean) => void
  isEnabled: boolean
}

type TouchState = {
  id: number
  initialX: number
  initialY: number
  currentX: number
  currentY: number
  maxDeltaY: number
  sidebarX: number

  isPeaking: boolean
  isSwiping: boolean
  maybeSwiping: boolean
}

function findZIndex(element: Element): number {
  let zIndex = window.getComputedStyle(element).zIndex

  if (zIndex !== 'auto') return +zIndex

  let parent = element.parentElement
  while (parent) {
    const parentStyle = window.getComputedStyle(parent)
    if (parentStyle.position !== 'static') {
      zIndex = parentStyle.zIndex
      if (zIndex !== 'auto') {
        return +zIndex
      }
    }
    parent = parent.parentElement
  }

  return 0
}

export default function useSwipeableSidebar({
  sidebarRef,
  backdropRef,
  isPinned,
  setIsPinned,
  isEnabled,
}: useSwipeableSidebarProps) {
  const touchState = useRef<TouchState | undefined>(undefined)
  const { push, pop } = useBackStack()

  const isPinnedRef = useRef(isPinned)
  useEffect(() => {
    isPinnedRef.current = isPinned
  }, [isPinned])

  const updateX = useCallback(
    (x: number, shouldTransition: boolean) => {
      const sidebarStyle = sidebarRef.current.style

      if (shouldTransition) {
        sidebarStyle.transitionDuration = '200ms'
        sidebarStyle.transitionTimingFunction = 'ease-out'
      } else {
        sidebarStyle.transitionDuration = ''
        sidebarStyle.transitionTimingFunction = ''
      }

      const isFullyOpen = x >= sidebarRef.current.offsetWidth
      backdropRef.current.style.visibility = isFullyOpen ? 'visible' : 'hidden'
      sidebarStyle.pointerEvents = isFullyOpen ? 'auto' : 'none'

      if (touchState.current) {
        touchState.current.sidebarX = x
      }

      sidebarStyle.transform = `translateX(${x - sidebarRef.current.offsetWidth}px)`
    },
    [backdropRef, sidebarRef]
  )

  const openSidebar = useCallback(() => {
    if (!sidebarRef.current) return
    const sidebarWidth = sidebarRef.current.offsetWidth

    updateX(sidebarWidth, true)
    setIsPinned(true)
  }, [setIsPinned, sidebarRef, updateX])

  const closeSidebar = useCallback(() => {
    updateX(0, true)
    setIsPinned(false)
  }, [setIsPinned, updateX])

  useEffect(() => {
    const onTouchStart = (e: globalThis.TouchEvent) => {
      const isOpen = isPinnedRef.current
      const sidebarWidth = sidebarRef.current.offsetWidth

      if (
        touchState.current &&
        touchState.current.sidebarX > 0 &&
        touchState.current.sidebarX < sidebarWidth
      ) {
        updateX(0, true)
        touchState.current = undefined
        return
      }

      const id = performance.now()
      touchState.current = {
        id,
        initialX: e.touches[0].pageX,
        initialY: e.touches[0].pageY,
        currentX: e.touches[0].pageX,
        currentY: e.touches[0].pageY,
        sidebarX: 0,
        maxDeltaY: 0,
        isPeaking: false,
        isSwiping: false,
        maybeSwiping: false,
      }

      if (e.defaultPrevented) return

      if (!(e.target instanceof Element)) return

      if (!isOpen && findZIndex(e.target) > triggerAreaZIndex) return

      if (e.touches[0].pageX < triggerAreaWidth && !isOpen) {
        touchState.current.maybeSwiping = true

        setTimeout(() => {
          if (
            touchState.current &&
            !touchState.current.isSwiping &&
            touchState.current.id === id &&
            touchState.current.maxDeltaY < swipeThreshold
          ) {
            updateX(peakingWidth, true)
            touchState.current.isPeaking = true
          }
        }, 100)
      } else if (isOpen) {
        touchState.current.maybeSwiping = true
      }
    }

    const onTouchMove = (e: globalThis.TouchEvent) => {
      if (e.defaultPrevented) return
      if (!(e.target instanceof Element)) return
      if (!touchState.current) return

      if (!touchState.current.maybeSwiping) return

      const isOpen = isPinnedRef.current

      touchState.current.currentX = e.touches[0].pageX
      touchState.current.currentY = e.touches[0].pageY

      if (!touchState.current.isSwiping) {
        const dx = Math.abs(touchState.current.currentX - touchState.current.initialX)
        const dy = Math.abs(touchState.current.currentY - touchState.current.initialY)

        touchState.current.maxDeltaY = Math.max(touchState.current.maxDeltaY, dy)

        if (touchState.current.isPeaking && touchState.current.maxDeltaY >= swipeThreshold) {
          touchState.current.isPeaking = false
          updateX(0, true)
        }

        touchState.current.isSwiping =
          dx > dy && dx > swipeThreshold && touchState.current.maxDeltaY < swipeThreshold

        if (touchState.current.isSwiping) {
          touchState.current.initialY = touchState.current.currentY
          touchState.current.initialX = touchState.current.currentX
        }
      }

      if (touchState.current.isSwiping) {
        if (e.cancelable) {
          e.preventDefault()
        }

        const sidebarWidth = sidebarRef.current.offsetWidth

        const touchShift =
          touchState.current.currentX -
          touchState.current.initialX +
          (isOpen ? sidebarWidth : 0) +
          (touchState.current.isPeaking ? peakingWidth : 0)

        updateX(Math.min(touchShift, sidebarWidth), false)
      }
    }

    const onTouchEnd = (e: globalThis.TouchEvent) => {
      if (e.defaultPrevented) return
      if (!(e.target instanceof Element)) return
      if (!touchState.current) return

      const isOpen = isPinnedRef.current
      const sidebarWidth = sidebarRef.current.offsetWidth
      const { isSwiping, isPeaking, sidebarX } = touchState.current
      touchState.current = undefined

      if (!isSwiping) {
        if (isPeaking) {
          updateX(0, true)
        }

        // if clicked outside the sidebar
        if (isOpen && !sidebarRef.current.contains(e.target)) {
          e.preventDefault()
          closeSidebar()
        }

        return
      }

      const minimumPixelDistanceBeforeActionFinished =
        sidebarWidth * minimumPercentOfDistanceBeforeActionFinished
      if (
        (isOpen && sidebarX > sidebarWidth - minimumPixelDistanceBeforeActionFinished) ||
        (!isOpen && sidebarX > minimumPixelDistanceBeforeActionFinished)
      ) {
        openSidebar()
      } else {
        closeSidebar()
      }
    }

    const onMouseDown = (e: globalThis.MouseEvent) => {
      if (!(e.target instanceof Element)) return

      if (isPinnedRef.current && !sidebarRef.current.contains(e.target)) {
        closeSidebar()
      }
    }

    if (isEnabled && sidebarRef.current && backdropRef.current) {
      document.documentElement.addEventListener('touchstart', onTouchStart)
      document.documentElement.addEventListener('touchmove', onTouchMove, { passive: false })
      document.documentElement.addEventListener('touchend', onTouchEnd)
      document.documentElement.addEventListener('mousedown', onMouseDown)
    }

    return () => {
      document.documentElement.removeEventListener('touchstart', onTouchStart)
      document.documentElement.removeEventListener('touchmove', onTouchMove)
      document.documentElement.removeEventListener('touchend', onTouchEnd)
      document.documentElement.removeEventListener('mousedown', onMouseDown)
    }
  }, [setIsPinned, isEnabled, sidebarRef, backdropRef, updateX, closeSidebar, openSidebar])

  useEffect(() => {
    if (!isEnabled) return
    if (!sidebarRef.current) return

    const sidebarWidth = sidebarRef.current.offsetWidth

    if (isPinned) {
      updateX(sidebarWidth, true)

      push({ callback: closeSidebar })
      return pop
    }
    if (!isPinned) {
      updateX(0, true)
    }
  }, [isPinned, isEnabled, sidebarRef, updateX, push, setIsPinned, pop, closeSidebar])
}
