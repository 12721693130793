import {
  APISettlementFilterOptions,
  SettlementFiltersForQuery,
} from 'src/components/settlements/SettlementFilters'
import { centreClient } from './client'

export interface SettlementsResponse {
  data: Settlement[]
  meta: { pagination: Pagination }
}

export const settlementsRequests = {
  settlements: async ({
    filter,
    page,
  }: { filter?: SettlementFiltersForQuery; page?: number } = {}) => {
    let options = {}

    if (page) {
      options = { ...options, searchParams: { page } }
    }

    if (filter && Object.keys(filter).length > 0) {
      options = { ...options, json: { filter } }
    } else {
      options = { ...options, json: { filter: {} } }
    }

    const response = await centreClient
      .post('api/v2/settlements', options)
      .json<SettlementsResponse>()

    return response
  },

  settlement: async (id: number | string) => {
    const response = await centreClient
      .get(`api/v1/settlements/${id}`)
      .json<{ data: SettlementDetail }>()

    return response.data
  },

  settlementFilterOptions: async () => {
    const response = await centreClient
      .get('api/v2/filters/settlements')
      .json<{ data: APISettlementFilterOptions }>()

    return response.data
  },

  settlementAssociatedContracts: async (id: number) => {
    const response = await centreClient
      .get(`api/v1/settlements/${id}/associated-contracts`)
      .json<{ data: SettlementAssociatedContract[] }>()
    return response.data
  },

  settlementAssociatedTickets: async (id: number) => {
    const response = await centreClient
      .get(`api/v1/settlements/${id}/associated-tickets`)
      .json<{ data: SettlementAssociatedTicket[] }>()
    return response.data
  },

  settlementsExport: async (filter: { [key: string]: any } = {}) => {
    const response = await centreClient.post('api/v1/settlements/export', {
      json: { filter },
    })
    return response
  },
}
