type ParsedPhoneNumber = {
  countryCode?: string
  areaCode: string
  exchangeCode: string
  lineNumber: string
}

export function parsePhoneNumber(phoneNumber: string | undefined): ParsedPhoneNumber | undefined {
  if (!phoneNumber) {
    return undefined
  }

  const cleaned = phoneNumber.replace(/\D/g, '')
  const match = /^(1)?(\d{3})(\d{3})(\d{4})$/.exec(cleaned)

  return match
    ? {
        countryCode: match[1] ? '1' : undefined,
        areaCode: match[2],
        exchangeCode: match[3],
        lineNumber: match[4],
      }
    : undefined
}

export function buildTelUrl({
  countryCode,
  areaCode,
  exchangeCode,
  lineNumber,
}: ParsedPhoneNumber) {
  return `tel:+${countryCode ?? '1'}${areaCode}${exchangeCode}${lineNumber}`
}

export function formatPhoneNumber(phoneNumber: string | undefined) {
  const parsed = parsePhoneNumber(phoneNumber)
  if (!parsed) {
    return phoneNumber
  }

  const { countryCode, areaCode, exchangeCode, lineNumber } = parsed

  return `${
    countryCode ? `+${countryCode}` : ''
  } (${areaCode}) ${exchangeCode}-${lineNumber}`.trim()
}
