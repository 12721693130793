import { PropsWithChildren, ComponentType } from 'react'
import Head from 'next/head'
import { AppProvidersProps, AppThemeProvider } from 'src/app/providers'
import ErrorPage from 'src/pages/_error'
import { ClientRedirect } from 'src/utils/navigation'
import { ENV, logger } from 'src/utils'
import { SimpleDebugDrawer } from 'src/components/utils/DebugInformation'
import { Box } from '@chakra-ui/react'
import { GenericNonIdealState } from 'src/components/designsystem/non-ideal-states'
import { ErrorState } from 'src/components/resource/NonIdealStates'
import { useAuth } from 'src/auth'

export function CheckAuthAndAppErrors({
  children,
  error,
  redirectToPath,
  requireAuth = true,
}: PropsWithChildren<{
  error: string
  redirectToPath: `/${string}`
  requireAuth: boolean
}>): JSX.Element {
  const { loading, authenticated, slug } = useAuth()

  if (error !== undefined) return <AppError error={error} />
  if (redirectToPath !== undefined) return <ClientRedirect path={redirectToPath} />

  if (!loading && requireAuth && !authenticated) {
    logger.debug({
      message:
        '[app/errors] CheckAuthAndAppErrors - detected no auth when its required, redirecting...',
    })

    return <ClientRedirect path={['', slug, 'welcome'].join('/')} />
  }

  return <>{children}</>
}

function AppError({ error }: { error: string }) {
  return <ErrorState subHeader={error} />
}

interface ErrorBoundaryFallbackProps {
  providerProps: AppProvidersProps
  error?: any
  resetError: () => void
  MainAppScreen: ComponentType<{ providerProps: AppProvidersProps }>
}

export function ErrorBoundaryFallback({
  providerProps,
  resetError,
  MainAppScreen,
}: ErrorBoundaryFallbackProps) {
  return (
    <MainAppScreen {...{ providerProps }}>
      <ErrorPage resetError={resetError} />
    </MainAppScreen>
  )
}

export function NotEnabledCompanyError() {
  const isMockMode = ENV.MOCK_MODE
  const isDebugDrawerEnabled =
    !isMockMode && ['yeet', 'dev', 'acadia', 'qa', 'local'].includes(ENV.BUSHEL_ENVIRONMENT)

  return (
    <>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <title>Bushel</title>
      </Head>

      <AppThemeProvider>
        <Box position="fixed" width="100vw" height="100vh" bg="gray.50">
          <GenericNonIdealState
            isVerticallyCentered
            illustration="404"
            illustrationAltText="Not Found"
          />
        </Box>
        {isDebugDrawerEnabled && <SimpleDebugDrawer />}
      </AppThemeProvider>
    </>
  )
}
