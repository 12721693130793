import { createIcon } from '@chakra-ui/react'

export const PlusIcon = createIcon({
  defaultProps: {
    'aria-hidden': true,
    fill: 'currentColor',
  },
  displayName: 'PlusIcon',
  viewBox: '0 0 16 16',
  d: 'M8.33.5c.42 0 .75.34.75.75v6h6a.75.75 0 0 1 0 1.5h-6v6a.75.75 0 0 1-1.5 0v-6h-6a.75.75 0 0 1 0-1.5h6v-6c0-.41.34-.75.75-.75Z',
})
