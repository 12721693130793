import { memo, ReactNode } from 'react'
import { useDisclosure } from 'src/components/designsystem'
import { ResourceListItem } from 'src/components/resource'
import { DisplayConfig } from 'src/components/designsystem/display-config'
import { ContractDetail } from 'src/components/contracts'
import ContractDetailV3 from 'src/components/contracts/contracts-v3/ContractDetail'
import { useContractDetail, useContractDetailV3 } from 'src/data/queries'
import { useConfig } from 'src/data/config'
import ResourceListItemActionMenu from 'src/components/resource/ResourceListItemActionMenu'
import { FLAG_KEYS, useGlobalFlag } from 'src/utils/flagr'

export interface ContractProps {
  contract: Contract | ContractV3
  columnConfig: DisplayConfig<Contract> | DisplayConfig<ContractV3>
  setDetailItem?: (item: Contract | ContractV3) => void
  actionMenuConfig?: { key: string; render: (item: Contract | ContractV3) => ReactNode }[]
  actionMenuModalSetItem?: (listItem: any) => void
  actionMenuModalOnOpen?: () => void
}

export interface ContractDetailWrapperProps {
  contract: Contract | ContractV3
  isOpen: boolean
}

const Contract = memo(function Contract({
  contract,
  columnConfig,
  setDetailItem,
  actionMenuConfig,
  actionMenuModalSetItem,
  actionMenuModalOnOpen,
}: ContractProps) {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const { contracts } = useConfig()
  const contractsVersion = contracts.contractsVersion()
  const isPdfEnabled = useGlobalFlag(FLAG_KEYS.WEBAPP_CONTRACTS_PDF)

  return (
    <ResourceListItem
      item={contract}
      analyticsTitle="Contracts"
      detailPrimaryTitle={`Contract ${contract.display_id}`}
      detailSecondaryTitle={
        isPdfEnabled && (
          <ResourceListItemActionMenu
            actionMenuConfig={actionMenuConfig}
            actionMenuModalSetItem={actionMenuModalSetItem}
            actionMenuModalOnOpen={actionMenuModalOnOpen}
            item={contract}
          />
        )
      }
      actionMenuConfig={actionMenuConfig}
      actionMenuModalSetItem={actionMenuModalSetItem}
      actionMenuModalOnOpen={actionMenuModalOnOpen}
      {...{ columnConfig, setDetailItem, isOpen, onToggle, onClose }}
    >
      {contractsVersion === '3.0.0' && (
        <ContractDetailV3Wrapper contract={contract} isOpen={isOpen} />
      )}
      {contractsVersion !== '3.0.0' && (
        <ContractDetailWrapper contract={contract} isOpen={isOpen} />
      )}
    </ResourceListItem>
  )
})

function ContractDetailWrapper({ contract, isOpen }: ContractDetailWrapperProps) {
  const contractDetailQuery = useContractDetail({ id: contract.id, enabled: isOpen })

  return (
    <ContractDetail
      isLoaded={contractDetailQuery.isSuccess}
      error={contractDetailQuery.error}
      contractDetail={contractDetailQuery.data}
    />
  )
}

function ContractDetailV3Wrapper({ contract, isOpen }: ContractDetailWrapperProps) {
  const contractDetailQuery = useContractDetailV3({ id: contract.id, enabled: isOpen })

  return (
    <ContractDetailV3
      isLoaded={contractDetailQuery.isSuccess}
      error={contractDetailQuery.error}
      contractDetail={contractDetailQuery.data}
    />
  )
}

export default Contract
