import {
  PluginListenerHandle,
  appPluginWrapper,
} from 'src/utils/clients/native/wrappers/app-plugin-wrapper'
import { corePluginWrapper } from 'src/utils/clients/native/wrappers/core-plugin-wrapper'
import { Optional } from 'src/utils/types/optional'

export type AppState = 'foreground' | 'background'
export type AppStateChange = (state: AppState) => void
export type AppStateListener = PluginListenerHandle

export interface NativeAppClient {
  getVersion: () => Promise<string>
  addAppStateListener: (onChangeFn: AppStateChange) => Promise<AppStateListener>
}

export function getNativeAppClient(): Optional<NativeAppClient> {
  const isNativeApp = corePluginWrapper.isNativePlatform()

  if (isNativeApp) {
    return getClient()
  } else {
    return undefined
  }
}

function getClient(): NativeAppClient {
  return {
    getVersion,
    addAppStateListener,
  }
}

async function getVersion(): Promise<string> {
  const info = await appPluginWrapper.getInfo()
  return info.version
}

async function addAppStateListener(onChangeFn: AppStateChange): Promise<AppStateListener> {
  return appPluginWrapper.addListener('appStateChange', (appState) => {
    if (appState.isActive) {
      return onChangeFn('foreground')
    } else {
      return onChangeFn('background')
    }
  })
}
