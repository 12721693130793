import { IconButton, ChevronDownIcon, ChevronUpIcon } from 'src/components/designsystem'

export default function ToggleButton({
  isLoading = false,
  isOpen = false,
}: {
  isLoading?: boolean
  isOpen?: boolean
}) {
  return (
    <IconButton
      //TODO: add dynamic aria-label here
      aria-label="Toggle Ticket View"
      icon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
      isLoading={isLoading}
      variant="ghostSecondary"
      size="xs"
      fontSize="xl"
      w={6}
      minW="auto"
      isRound
    />
  )
}
