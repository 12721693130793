import { useQuery } from '@tanstack/react-query'
import * as api from 'src/api'
import { ESignContractsResponse, ESignRemindersResponse } from 'src/api/esign'

export const eSignContractQueryKey = 'esign-contracts'
export const eSignRemindersQueryKey = 'esign-reminders'

export function useESignContracts() {
  return useQuery<ESignContractsResponse, api.HTTPError>({
    queryKey: [eSignContractQueryKey],
    queryFn: async () => {
      const response = await api.centre.eSignContracts()
      response.data.sort((a, b) => {
        //sort awaiting_signature first
        //last_updated sort from API persists because of stable sort
        const statusA = a.signature.status.toLowerCase()
        const statusB = b.signature.status.toLowerCase()
        if (statusA === 'awaiting_signature' && statusB === 'signed') {
          return -1
        }
        if (statusA === 'signed' && statusB === 'awaiting_signature') {
          return 1
        }

        // same status
        return 0
      })
      return response
    },
    meta: {
      errorMessage: 'Unable to retrieve esign contracts, please refresh the page to try again.',
    },
  })
}

export function useESignReminders({ isEnabled }: { isEnabled: boolean }) {
  return useQuery<ESignRemindersResponse, api.HTTPError>({
    queryKey: [eSignRemindersQueryKey],
    queryFn: () => {
      return api.centre.eSignReminders()
    },
    enabled: isEnabled,
    staleTime: Infinity,
    meta: {
      errorMessage: 'Unable to retrieve esign reminders, please refresh the page to try again.',
    },
  })
}
