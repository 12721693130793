import { ComponentType, Dispatch, PropsWithChildren, SetStateAction, useMemo } from 'react'
import { ErrorBoundary as RollbarErrorBoundary, Provider as RollbarProvider } from '@rollbar/react'
import { ChakraProvider } from '@chakra-ui/react'
import { HydrationBoundary, QueryClientProvider, type DehydratedState } from '@tanstack/react-query'
import dynamic from 'next/dynamic'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { queryClient } from 'src/data/queries'
import { AppConfigContext, NavConfig } from 'src/data/config'
import { ErrorBoundaryFallback } from 'src/app/errors'
import { ENV, flagr, rollbarConfig } from 'src/utils'
import { Theme } from 'src/themes'
import { AppConfig } from 'src/api/centre-service/data-models/app-config'
import { BreakpointStoreProvider, createBreakpointStore } from 'src/components/designsystem/utils'

export function AppThemeProvider({ children }) {
  return (
    <ChakraProvider resetCSS theme={Theme}>
      {children}
    </ChakraProvider>
  )
}

const MockProvider = ENV.MOCK_MODE
  ? dynamic(() => import('src/app/MockProvider'))
  : ({ children }) => children

///////////////////////////////////////////////////////////////////////////////////////////////////

const { FlagrContext } = flagr

export interface AppProvidersProps {
  cookies: Record<string, string>
  config: AppConfig
  flags: Record<string, boolean>
  initConfig: AppConfig
  initBreakpoint: ChakraBreakpoint
  pageTitle: string
  activeItem: string
  setConfig: Dispatch<SetStateAction<AppConfig>>
  setFlags: Dispatch<SetStateAction<Record<string, boolean | string>>>
  setNavData: Dispatch<React.SetStateAction<NavConfig>>
  dehydratedState?: DehydratedState
}

export function AppProviders({
  cookies,
  config,
  initConfig,
  setConfig,
  initBreakpoint,
  flags,
  setFlags,
  pageTitle,
  activeItem,
  setNavData,
  children,
  dehydratedState,
}: PropsWithChildren<AppProvidersProps>) {
  const appConfigContextValue = useMemo(
    () => ({
      cookies,
      config: config ?? initConfig,
      setConfig,
      pageTitle,
      activeItem,
      setNavData,
    }),
    [cookies, config, initConfig, setConfig, pageTitle, activeItem, setNavData]
  )
  const flagContextValue = useMemo(() => ({ flags, setFlags }), [flags, setFlags])

  return (
    <MockProvider>
      <QueryClientProvider client={queryClient}>
        <HydrationBoundary state={dehydratedState}>
          <FlagrContext.Provider value={flagContextValue}>
            <BreakpointProvider initBreakpoint={initBreakpoint}>
              <AppConfigContext.Provider value={appConfigContextValue}>
                <AppThemeProvider>{children}</AppThemeProvider>
              </AppConfigContext.Provider>
            </BreakpointProvider>
          </FlagrContext.Provider>
          <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-right" />
        </HydrationBoundary>
      </QueryClientProvider>
    </MockProvider>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////

export function BreakpointProvider({
  initBreakpoint,
  children,
}: PropsWithChildren<{ initBreakpoint: ChakraBreakpoint }>) {
  return (
    <BreakpointStoreProvider
      createStore={() => createBreakpointStore({ breakpoint: initBreakpoint })}
    >
      {children}
    </BreakpointStoreProvider>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////

interface RollbarProvidersProps {
  user: Me
  providerProps: AppProvidersProps
  MainAppScreen: ComponentType<{ providerProps: AppProvidersProps }>
}

export function RollbarProviders({
  user,
  providerProps,
  MainAppScreen,
  children,
}: PropsWithChildren<RollbarProvidersProps>) {
  return (
    <RollbarProvider
      config={
        // The useless fallback to an empty object squealches an annoying PropTypes warning in tests
        rollbarConfig({ user }) ?? {}
      }
    >
      <RollbarErrorBoundary
        fallbackUI={({ error, resetError }) => (
          <ErrorBoundaryFallback {...{ providerProps, error, resetError, MainAppScreen }} />
        )}
      >
        {children}
      </RollbarErrorBoundary>
    </RollbarProvider>
  )
}
