import { useBackStackStore } from './android-back-stack'
import { appPluginWrapper } from './wrappers/app-plugin-wrapper'

export async function initBackButtonListener() {
  await appPluginWrapper.addListener('backButton', ({ canGoBack }) => {
    const { stack } = useBackStackStore.getState()
    if (stack.length > 0) {
      const currentItem = stack.slice(-1)[0]
      currentItem.callback()
    } else if (canGoBack) {
      window.history.back()
    } else {
      appPluginWrapper.exitApp()
    }
  })
}
