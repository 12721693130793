import {
  Box,
  Divider,
  CollapsableDetailTable,
  GenericNonIdealState,
  useBreakpoint,
  SimpleGrid,
  Text,
} from 'src/components/designsystem'
import DisplayConfig, { DisplayConfigItem } from 'src/components/designsystem/display-config'
import {
  DetailGrid,
  ErrorState,
  SkeleKeyValue,
  renderOrEmDash,
  LoadingState,
} from 'src/components/resource'
import { HTTPError } from 'src/api'
import { renderDeliveryPeriod } from 'src/components/contracts/contracts-v3/v3-contract-visuals/PricingDeliveryTable'

const HISTORY_DISPLAY_CONFIG: DisplayConfigItem<ContractPricingHistory>[] = [
  { label: 'Last Updated', key: 'last_update', ValueCellComponent: DisplayConfig.BoldTextValue },
  { label: 'Price', key: 'price', ValueCellComponent: DisplayConfig.BoldTextValue },
  { label: 'Mode', key: 'mode', ValueCellComponent: DisplayConfig.BoldTextValue },
  { label: 'Type', key: 'type', ValueCellComponent: DisplayConfig.BoldTextValue },
]

export interface PricingLineDetailProps {
  isLoaded?: boolean
  error?: HTTPError
  pricingLineDetail: ContractPricingLineDetail
}

function PricingLineDetail({ isLoaded, error, pricingLineDetail }: PricingLineDetailProps) {
  const { isMobile } = useBreakpoint()

  if (error) {
    return (
      <ErrorState
        subHeader={
          error?.response?.payload?.error?.message ||
          "There was an error while retrieving this Pricing Line's details. Please refresh the page to try again."
        }
      />
    )
  }
  return (
    <>
      <DetailGrid.Title>Breakdown</DetailGrid.Title>
      <DetailGrid templateColumns="1fr">
        <DetailGrid.ListCard heading="Details">
          <SimpleGrid columns={isMobile ? 1 : 2} spacing={[null, null, '72px', 20]}>
            <Box>
              <SkeleKeyValue isLoaded={isLoaded} label="Number">
                {renderOrEmDash({ value: pricingLineDetail?.display_id })}
              </SkeleKeyValue>
              <Divider />
              <SkeleKeyValue isLoaded={isLoaded} label="Delivery">
                {renderOrEmDash({
                  value: renderDeliveryPeriod(pricingLineDetail),
                })}
              </SkeleKeyValue>
              <Divider />
              <SkeleKeyValue isLoaded={isLoaded} label="Location">
                {renderOrEmDash({ value: pricingLineDetail?.location_name })}
              </SkeleKeyValue>
              <Divider />
              <SkeleKeyValue isLoaded={isLoaded} label="Amount">
                {renderOrEmDash({ value: pricingLineDetail?.quantity_priced })}
              </SkeleKeyValue>
              <Divider />
              <SkeleKeyValue isLoaded={isLoaded} label="Signature Status">
                {renderOrEmDash({
                  value: pricingLineDetail?.signature_status,
                })}
              </SkeleKeyValue>
            </Box>
            <Box>
              {isMobile && <Divider />}
              <SkeleKeyValue isLoaded={isLoaded} label="Futures Price">
                {renderOrEmDash({ value: pricingLineDetail?.futures_price })}
              </SkeleKeyValue>
              <Divider />
              <SkeleKeyValue isLoaded={isLoaded} label="Basis">
                {renderOrEmDash({ value: pricingLineDetail?.basis_price })}
              </SkeleKeyValue>
              <Divider />
              <SkeleKeyValue isLoaded={isLoaded} label="Adjustment">
                {renderOrEmDash({ value: pricingLineDetail?.adjustment })}
              </SkeleKeyValue>
              <Divider />
              <SkeleKeyValue isLoaded={isLoaded} label="Cash Price">
                {renderOrEmDash({ value: pricingLineDetail?.cash_price })}
              </SkeleKeyValue>
              <Divider />
              <SkeleKeyValue isLoaded={isLoaded} label="Futures Month">
                {renderOrEmDash({ value: pricingLineDetail?.futures_month })}
              </SkeleKeyValue>
            </Box>
          </SimpleGrid>
        </DetailGrid.ListCard>
      </DetailGrid>

      {pricingLineDetail?.comments && (
        <>
          <DetailGrid.Title>Comments</DetailGrid.Title>
          <Box layerStyle="detail-card">
            <Text whiteSpace="pre-line">{pricingLineDetail?.comments}</Text>
          </Box>
        </>
      )}

      <DetailGrid.Title>History</DetailGrid.Title>
      {isLoaded && pricingLineDetail?.pricing_histories?.length > 0 ? (
        <CollapsableDetailTable
          rowItems={pricingLineDetail?.pricing_histories}
          rowItemTitle="pricing"
          displayConfig={HISTORY_DISPLAY_CONFIG}
          analyticsCategory="Contracts V3"
          analyticsAction="Contracts V3 Pricing History List"
        />
      ) : (
        <Box border="1px solid" borderColor="gray.300" borderRadius="8px" py={8}>
          {isLoaded ? (
            <GenericNonIdealState
              illustration="tumbleweed"
              illustrationAltText="Nothing here yet"
              size="sm"
              header="Nothing here yet"
              subHeader="There is no history currently to show"
            />
          ) : (
            <LoadingState />
          )}
        </Box>
      )}
    </>
  )
}

export default PricingLineDetail
