import { centreClient } from './client'

export interface CentreAnalyticsReportResponse {
  data: CentreAnalyticsReportMonth[]
}

export const centreAnalyticsRequests = {
  analyticsReport: () => centreClient.get('api/reports').json<CentreAnalyticsReportResponse>(),

  loggedInUsersExport: async (date: string) => {
    return centreClient.get(`api/reports/logged-in-users-csv/${date}`)
  },

  loggedInUsersByAccountExport: async (date: string) => {
    return centreClient.get(`api/reports/logged-in-users-accounts-csv/${date}`)
  },

  eSignContractsCreatedExport: async (date: string) => {
    return centreClient.get(`api/reports/esign-contracts-created-csv/${date}`)
  },

  eSignContractsSignedExport: async (date: string) => {
    return centreClient.get(`api/reports/esign-contracts-signed-csv/${date}`)
  },
}
