import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  checkboxAnatomy.keys
)

const baseStyle = definePartsStyle({
  control: {
    _disabled: {
      bg: 'none',
      opacity: 0.4,
      borderColor: 'gray.700',
    },
  },
})

// Checkbox variant that is disabled, but visually looks as if it was not.
const readOnlyVariantStyle = defineStyle((props) => {
  const { colorScheme: c } = props

  return {
    control: {
      _checked: {
        _disabled: {
          bg: mode(`${c}.500`, `${c}.200`)(props),
          borderColor: mode(`${c}.500`, `${c}.200`)(props),
          color: mode('white', 'gray.900')(props),
          opacity: 1,
        },
      },
    },
    label: {
      _disabled: { opacity: 1 },
    },
  }
})

export const CheckboxStyleConfig = defineMultiStyleConfig({
  baseStyle,
  variants: { readOnly: readOnlyVariantStyle },
})
