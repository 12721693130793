import { reportingConfigServiceClient } from './config-client'

export interface PoYGetExportConfigBySlugResponse {
  enabled: boolean
  title: string
  type: string
}

export interface TicketApplicationsGetExportConfigBySlugResponse {
  enabled: boolean
  title: string
  type: string
}

export interface ContractsGetExportConfigBySlugResponse {
  enabled: boolean
  title: string
  type: string
}

export const reportingConfigRequests = {
  poyGetExportConfigBySlug: async (slug: string) => {
    return reportingConfigServiceClient
      .post('poy/v1/GetExportConfigBySlug', {
        json: { slug },
      })
      .json<PoYGetExportConfigBySlugResponse>()
  },

  ticketApplicationsExportConfigBySlug: async (slug: string) => {
    return reportingConfigServiceClient
      .post('tickets/v1/GetExportConfigBySlug', {
        json: { slug },
      })
      .json<TicketApplicationsGetExportConfigBySlugResponse>()
  },

  contractsExportConfigBySlug: async (slug: string) => {
    return reportingConfigServiceClient
      .post('contracts/v1/GetExportConfigBySlug', {
        json: { slug },
      })
      .json<ContractsGetExportConfigBySlugResponse>()
  },
}
