import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useBreakpoint } from 'src/components/designsystem'
import { ActiveIconName, StandardIconName } from 'src/utils/icons'
import { type FeatureName } from 'src/__fixtures__/featureConfig'
import { ENV, setCookie, useCompanyLandingFeaturePath } from 'src/utils'
import { useAuth } from 'src/auth'
import { AppConfig } from 'src/api/centre-service/data-models/app-config'
import { CustomEntitiesConfigItem } from 'src/api/centre-service/data-models/custom-entities-config-item'
import { FLAG_KEYS } from 'src/utils/flagr'
import { CONFIGCAT_FLAGS, ConfigCatFlagKey } from 'src/utils/config-cat'
import useHasCrmAccess from 'src/utils/hooks/useHasCrmAccess'
import { AUTHZ_PERMISSIONS, AuthzPermission } from 'src/api/bidl/iam/authz-permissions'

const PINNED_COOKIE_NAME = 'bushel-web-sidebar-pinned'

export function usePinnedSidebar({ cookies }: { cookies: Record<string, string> }) {
  const pinnedRef = useRef(false)
  const sidebarTriggerRef = useRef()
  const isPinnedCookie = cookies?.[PINNED_COOKIE_NAME]
  const isPinnedInitial = isPinnedCookie === undefined ? false : isPinnedCookie === '1'

  const [isPinned, _setIsPinned] = useState(isPinnedInitial)
  const { isDesktop } = useBreakpoint()

  const setIsPinned = useCallback((value) => {
    pinnedRef.current = true
    _setIsPinned(value)
    setPinnedCookie(value ? '1' : '0')
  }, [])

  useEffect(() => {
    if (pinnedRef.current === true) return
    if (isPinnedCookie !== undefined) return
    if (isDesktop === undefined) return
    if (isDesktop === isPinned) return

    pinnedRef.current = true
    setIsPinned(isDesktop)
  }, [setIsPinned, isPinnedCookie, isDesktop, isPinned])

  return { isPinned, setIsPinned, sidebarTriggerRef }
}

function setPinnedCookie(value: string) {
  setCookie(null, PINNED_COOKIE_NAME, value)
}

///////////////////////////////////////////////////////////////////////////////////////////////////

export interface SidebarItem {
  /** iconName from the Icons available */
  standardIcon: StandardIconName
  activeIcon: ActiveIconName
  isActive?: boolean
  ariaLabel: string
  path: string
  featureName?: FeatureName | FeatureName[]
  badgeCount?: number
  isEnabled?: (config?: AppConfig) => boolean
  isExternal?: boolean
  featureFlag?: string
  configCatFeatureFlag?: ConfigCatFlagKey
  staffUserPermission?: AuthzPermission
}

export interface SidebarGroup {
  label?: string
  isPinned?: boolean
  items: SidebarItem[]
}

interface CustomEntitiesFeatureConfig {
  version: string | boolean
  payload?: CustomEntitiesConfigItem[]
}

export const useMakeSidebarGroups = ({
  slug,
  selectedUserId,
  badgeCounts,
  customMenuLinks,
  customEntities = {} as CustomEntitiesFeatureConfig,
}: {
  slug: string
  selectedUserId?: string
  badgeCounts?: { esign: number }
  customMenuLinks?: CustomMenuLink[]
  customEntities?: CustomEntitiesFeatureConfig
}): SidebarGroup[] => {
  const baseUrl = `/${slug}` + (selectedUserId ? `/${selectedUserId}` : '')

  return useMemo(
    () => [
      {
        items: [
          {
            standardIcon: 'ESignStandardIcon',
            activeIcon: 'ESignActiveIcon',
            ariaLabel: 'eSign',
            path: `${baseUrl}/esign`,
            featureName: 'esign',
            badgeCount: badgeCounts?.esign ?? 0,
          },
          {
            standardIcon: 'LocationsStandardIcon',
            activeIcon: 'LocationsActiveIcon',
            ariaLabel: 'Locations & Hours',
            path: `${baseUrl}/locations`,
            featureName: 'locationsAndHours',
            configCatFeatureFlag: CONFIGCAT_FLAGS.isLocationsAndHoursEnabled,
          },
        ],
      },
      {
        label: 'Markets',
        items: [
          {
            standardIcon: 'CashbidStandardIcon',
            activeIcon: 'CashbidActiveIcon',
            ariaLabel: 'Cash Bids',
            path: `${baseUrl}/cash-bids`,
            featureName: 'cash_bids',
          },
          {
            standardIcon: 'OffersStandardIcon',
            activeIcon: 'OffersActiveIcon',
            ariaLabel: 'My Offers',
            path: `${baseUrl}/my-offers`,
            featureName: 'offers',
            featureFlag: FLAG_KEYS.WEBAPP_MY_OFFERS,
          },
          {
            standardIcon: 'FuturesStandardIcon',
            activeIcon: 'FuturesActiveIcon',
            ariaLabel: 'Futures',
            path: `${baseUrl}/futures`,
            featureName: 'futures',
          },
        ],
      },
      {
        label: 'Grain',
        items: [
          {
            standardIcon: 'ScaleTicketsStandardIcon',
            activeIcon: 'ScaleTicketsActiveIcon',
            ariaLabel: 'Scale Tickets',
            path: `${baseUrl}/tickets`,
            featureName: 'tickets',
          },
          {
            standardIcon: 'OutboundTicketsStandardIcon',
            activeIcon: 'OutboundTicketsActiveIcon',
            ariaLabel: 'Outbound Scale Tickets',
            path: `${baseUrl}/outbound-tickets`,
            featureName: 'outbound_tickets',
          },
          {
            standardIcon: 'ContractsStandardIcon',
            activeIcon: 'ContractsActiveIcon',
            ariaLabel: 'Contracts',
            path: `${baseUrl}/contracts`,
            featureName: 'contracts',
          },
          {
            standardIcon: 'CommodityBalanceStandardIcon',
            activeIcon: 'CommodityBalanceActiveIcon',
            ariaLabel: 'Commodity Balances',
            path: `${baseUrl}/commodity-balances`,
            featureName: 'commodity_balances',
          },
          {
            standardIcon: 'BinSamplesStandardIcon',
            activeIcon: 'BinSamplesActiveIcon',
            ariaLabel: 'Bin Samples',
            path: `${baseUrl}/bin-samples`,
            featureName: 'bin_samples',
            configCatFeatureFlag: CONFIGCAT_FLAGS.isBinSamplesFeatureEnabled,
          },
        ],
      },
      {
        label: 'Ag Retail',
        items: [
          {
            standardIcon: 'InvoiceStandardIcon',
            activeIcon: 'InvoiceActiveIcon',
            ariaLabel: 'Invoices',
            path: `${baseUrl}/invoices`,
            featureName: 'transactions',
          },
          {
            standardIcon: 'StatementsStandardIcon',
            activeIcon: 'StatementsActiveIcon',
            ariaLabel: 'Statements',
            path: `${baseUrl}/statements`,
            featureName: 'statements',
          },
          {
            standardIcon: 'DeliveryTicketsStandardIcon',
            activeIcon: 'DeliveryTicketsActiveIcon',
            ariaLabel: 'Delivery Tickets',
            path: `${baseUrl}/delivery-tickets`,
            featureName: 'delivery_tickets',
          },
          {
            standardIcon: 'WorkOrdersStandardIcon',
            activeIcon: 'WorkOrdersActiveIcon',
            ariaLabel: 'Work Orders',
            path: `${baseUrl}/work-orders`,
            featureName: 'work_orders',
          },
          {
            standardIcon: 'PrepaidAndBookingsStandardIcon',
            activeIcon: 'PrepaidAndBookingsActiveIcon',
            ariaLabel: 'Prepaids & Bookings',
            path: `${baseUrl}/prepaids-bookings`,
            featureName: 'prepaid_contracts',
          },
        ],
      },
      {
        label: 'Finance',
        items: [
          {
            standardIcon: 'SettlementStandardIcon',
            activeIcon: 'SettlementActiveIcon',
            ariaLabel: 'Settlements',
            path: `${baseUrl}/settlements`,
            featureName: 'settlements',
          },
          {
            standardIcon: 'PaymentsStandardIcon',
            activeIcon: 'PaymentsActiveIcon',
            ariaLabel: 'Payments',
            path: `${baseUrl}/payments`,
            featureName: ['payments_payable', 'payments_receivable', 'payments_external'],
          },
          {
            standardIcon: 'CoopInvestmentsStandardIcon',
            activeIcon: 'CoopInvestmentsActiveIcon',
            ariaLabel: 'Coop Investments',
            path: `${baseUrl}/coop-investments`,
            featureName: 'coop_investments',
            configCatFeatureFlag: CONFIGCAT_FLAGS.isCoopInvestmentsEnabled,
          },
          {
            standardIcon: 'ChecksStandardIcon',
            activeIcon: 'ChecksActiveIcon',
            ariaLabel: 'Checks',
            path: `${baseUrl}/checks`,
            featureName: 'checks',
            configCatFeatureFlag: CONFIGCAT_FLAGS.isChecksFeatureEnabled,
          },
        ],
      },
      ...makeCustomMenuLinks(customMenuLinks),
    ],
    [badgeCounts?.esign, customMenuLinks, baseUrl]
  )
}

export function useStaffSidebarGroups(): SidebarGroup[] {
  const { slug, hasPermission } = useAuth()
  const baseUrl = [null, slug, 'staff'].join('/')
  const farmerLandingFeaturePath = useCompanyLandingFeaturePath()
  const { isCrmConfigEnabled } = useHasCrmAccess()

  return useMemo(() => {
    return [
      {
        label: 'Admin Tools',
        items: [
          {
            standardIcon: 'CRMStandardIcon',
            activeIcon: 'CRMActiveIcon',
            ariaLabel: 'CRM',
            path: `${baseUrl}/crm`,
            isEnabled: () => isCrmConfigEnabled,
          },
          {
            standardIcon: 'CustomerViewSearchStandardIcon',
            activeIcon: 'CustomerViewSearchActiveIcon',
            ariaLabel: 'Customer Portal',
            path: farmerLandingFeaturePath,
          },
          {
            standardIcon: 'NotificationsBellStandardIcon',
            activeIcon: 'NotificationsBellActiveIcon',
            ariaLabel: 'Notifications',
            isExternal: true,
            path: `${ENV.CENTRE_API}/cms/app-management/notifications/new`,
          },
          {
            standardIcon: 'AnalyticsStandardIcon',
            activeIcon: 'AnalyticsActiveIcon',
            ariaLabel: 'Analytics',
            path: `${baseUrl}/analytics`,
            isEnabled: () => hasPermission(['admin', 'reporting']),
            staffUserPermission: AUTHZ_PERMISSIONS.REPORT_VIEW,
          },
          {
            standardIcon: 'ESignStandardIcon',
            activeIcon: 'ESignActiveIcon',
            ariaLabel: 'eSign',
            isExternal: true,
            path: `${ENV.CENTRE_API}/cms/app-management/esign`,
          },
          {
            standardIcon: 'LocationsStandardIcon',
            activeIcon: 'LocationsActiveIcon',
            ariaLabel: 'Locations & Hours',
            path: `${baseUrl}/locations`,
            featureName: 'locationsAndHours',
            configCatFeatureFlag: CONFIGCAT_FLAGS.isLocationsAndHoursEnabled,
            staffUserPermission: AUTHZ_PERMISSIONS.BUSINESS_LOCATION_MANAGE,
          },
        ],
      },
      {
        label: 'Payments',
        items: [
          {
            standardIcon: 'DirectDepositStandardIcon',
            activeIcon: 'DirectDepositActiveIcon',
            ariaLabel: 'Direct Deposit',
            path: `${baseUrl}/payments/direct-deposit`,
            featureName: 'payments_direct_deposit',
            configCatFeatureFlag: CONFIGCAT_FLAGS.isDirectDepositStaffFeatureEnabled,
            staffUserPermission: AUTHZ_PERMISSIONS.STAFF_PAYMENT_OUTBOUND_VIEW,
          },
          {
            standardIcon: 'SettlementsStandardIcon',
            activeIcon: 'SettlementsActiveIcon',
            ariaLabel: 'Payable',
            path: `${baseUrl}/payments/payable`,
            featureName: 'payments_payable',
            staffUserPermission: AUTHZ_PERMISSIONS.STAFF_PAYMENT_OUTBOUND_VIEW,
          },
          {
            standardIcon: 'InvoicesStandardIcon',
            activeIcon: 'InvoicesActiveIcon',
            ariaLabel: 'Receivable',
            path: `${baseUrl}/payments/receivable`,
            featureName: 'payments_receivable',
            staffUserPermission: AUTHZ_PERMISSIONS.STAFF_PAYMENT_INBOUND_VIEW,
          },
        ],
      },
      {
        label: 'Additional Solutions',
        items: [
          {
            standardIcon: 'FulfillmentStandardIcon',
            activeIcon: 'FulfillmentActiveIcon',
            ariaLabel: 'Commercial Portal',
            path: `${baseUrl}/fulfillment`,
          },
          {
            standardIcon: 'TradeStandardIcon',
            activeIcon: 'TradeActiveIcon',
            ariaLabel: 'Offer Management',
            path: `${baseUrl}/trade`,
          },
          {
            standardIcon: 'WebsiteStandardIcon',
            activeIcon: 'WebsiteActiveIcon',
            ariaLabel: 'Website Management',
            path: `${baseUrl}/website`,
          },
          {
            standardIcon: 'FarmStandardIcon',
            activeIcon: 'FarmStandardIcon' as ActiveIconName,
            ariaLabel: 'Farm',
            path: `https://bushelpowered.com/solutions/farm-management/`,
            isExternal: true,
          },
          {
            standardIcon: 'WalletStandardIcon',
            activeIcon: 'WalletStandardIcon' as ActiveIconName,
            ariaLabel: 'Wallet',
            path: `https://bushelwallet.com/`,
            isExternal: true,
          },
        ],
      },
      {
        label: 'Resources',
        items: [
          {
            standardIcon: 'WebLinkStandardIcon',
            activeIcon: 'WebLinkActiveIcon',
            ariaLabel: 'Knowledge Base',
            path: `https://help.bushelpowered.com/`,
            isExternal: true,
          },
        ],
      },
    ]
  }, [baseUrl, farmerLandingFeaturePath, hasPermission, isCrmConfigEnabled])
}

function makeCustomMenuLinks(customMenuLinks: CustomMenuLink[]): SidebarGroup[] {
  if (!customMenuLinks || customMenuLinks.length === 0) return []
  return [
    {
      label: 'Web Links',
      items: customMenuLinks.map((weblink) => ({
        standardIcon: 'WebLinkStandardIcon' as StandardIconName,
        activeIcon: 'WebLinkActiveIcon' as ActiveIconName,
        ariaLabel: weblink.display_text,
        path: weblink.link,
        isExternal: true,
      })),
    },
  ]
}
