import { TicketFiltersForQuery } from 'src/components/tickets/TicketFilters'
import { reportingServiceClient } from './client'
import {
  ContractFiltersForQuery,
  ContractV3FiltersForQuery,
} from 'src/components/contracts/ContractFilters'

export const proofOfYieldReportRequests = {
  // Returns Content-Type: application/pdf
  proofOfYieldPdfReport: async (
    filter: Omit<TicketFiltersForQuery, 'companies' | 'application_status'>
  ) => {
    const response = await reportingServiceClient.post('api/reports/poy-pdf', {
      json: { filter },
      headers: {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    })
    return response
  },

  ticketApplicationsPdfReport: async (
    filter: Omit<TicketFiltersForQuery, 'companies' | 'application_status'>
  ) => {
    const response = await reportingServiceClient.post('api/reports/ticket-applications-pdf', {
      json: { filter },
      headers: {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    })
    return response
  },

  contractsPdfReport: async (filter: ContractFiltersForQuery | ContractV3FiltersForQuery) => {
    const response = await reportingServiceClient.post('api/reports/contracts-pdf', {
      json: { filter },
      headers: {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    })
    return response
  },

  scaleTicketPdfReport: async (ticketId: string | number) => {
    const response = reportingServiceClient.post(`api/reports/scale-ticket-pdf/${ticketId}`, {
      headers: {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    })
    return response
  },
}
