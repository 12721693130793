import { HTTPError } from 'src/api'
import { useConfig } from 'src/data/config'
import {
  Badge,
  Box,
  Divider,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
  useBreakpoint,
} from 'src/components/designsystem'
import {
  DetailGrid,
  ErrorState,
  FormattedDateTime,
  renderOrEmDash,
  SkeleKeyValue,
} from 'src/components/resource'
import AssociatedTickets from 'src/components/contracts/contracts-v3/AssociatedTickets'
import AssociatedSettlements from './AssociatedSettlements'
import { useContractDetailV3 } from 'src/data/queries'
import { QuantityProgressCharts } from 'src/components/contracts/contracts-v3/v3-contract-visuals/QuantityProgressCharts'
import PricingDeliveryTable from './v3-contract-visuals/PricingDeliveryTable'

export interface ContractDetailPropsV3 {
  isLoaded?: boolean
  error?: HTTPError
  contractDetail: ContractDetailV3
}

export default function ContractDetailV3({
  isLoaded = false,
  error = null,
  contractDetail,
}: ContractDetailPropsV3) {
  const { isMobile } = useBreakpoint()

  const { contracts: contractsConfig } = useConfig()
  const contractsUpScaleEnabled = contractsConfig.contractsUpScale()
  const showPricingHistoryViewButton = contractsConfig.showPricingHistoryViewButton()
  const shouldFetchTicketApplications = contractsConfig.shouldFetchTicketApplications()

  const isClosed = contractDetail?.status === 'Closed'
  const isPriced = contractDetail?.pricing_status && contractDetail?.pricing_status !== 'Unpriced'

  if (error) {
    return (
      <ErrorState
        subHeader={
          error?.response?.payload?.error?.message ||
          "There was an error while retrieving this contract's details. Please refresh the page to try again."
        }
      />
    )
  }

  return (
    <>
      <DetailGrid.Title>Breakdown</DetailGrid.Title>
      <DetailGrid templateColumns={['1fr']}>
        <DetailGrid.List>
          <DetailGrid.ListCard heading="Details">
            <SimpleGrid columns={isMobile ? 1 : 2} spacing={[null, null, '72px', 20]}>
              <Box>
                <SkeleKeyValue isLoaded={isLoaded} label="Contract Number">
                  {renderOrEmDash({ value: contractDetail?.display_id })}
                </SkeleKeyValue>
                <Divider />
                <SkeleKeyValue isLoaded={isLoaded} label="Contract Type">
                  {renderOrEmDash({ value: contractDetail?.type_formatted })}
                </SkeleKeyValue>
                <Divider />
                <SkeleKeyValue isLoaded={isLoaded} label="Account ID">
                  {renderOrEmDash({ value: contractDetail?.account_name })}
                </SkeleKeyValue>
                <Divider />
                <SkeleKeyValue isLoaded={isLoaded} label="Commodity">
                  {renderOrEmDash({ value: contractDetail?.commodity_name })}
                </SkeleKeyValue>
                {(contractsConfig.showContractedLocation() ||
                  contractsConfig.contractHeaderLocation() === 'contract') && (
                  <>
                    <Divider />
                    <SkeleKeyValue isLoaded={isLoaded} label="Contracted Location">
                      {renderOrEmDash({ value: contractDetail?.location_name })}
                    </SkeleKeyValue>
                  </>
                )}
                <Divider />
                <SkeleKeyValue isLoaded={isLoaded} label="Created At">
                  {renderOrEmDash({
                    value: contractDetail?.created_at,
                    itemToRender: <FormattedDateTime date={contractDetail?.created_at} localize />,
                  })}
                </SkeleKeyValue>
              </Box>
              <Stack spacing={0} divider={<Divider />}>
                {isMobile && <Divider />}
                <SkeleKeyValue isLoaded={isLoaded} label="Qty Contracted">
                  {renderOrEmDash({ value: contractDetail?.quantity_contracted })}
                </SkeleKeyValue>
                <SkeleKeyValue isLoaded={isLoaded} label="Contract Status">
                  {renderOrEmDash({
                    value: contractDetail?.status,
                    itemToRender: (
                      <Badge colorScheme={isClosed ? 'green' : 'gray'}>
                        {contractDetail?.status}
                      </Badge>
                    ),
                  })}
                </SkeleKeyValue>
                {!contractsUpScaleEnabled && (
                  <SkeleKeyValue isLoaded={isLoaded} label="Signature Status">
                    {renderOrEmDash({
                      value: contractDetail?.signature_status,
                    })}
                  </SkeleKeyValue>
                )}
                <SkeleKeyValue isLoaded={isLoaded} label="Pricing">
                  {renderOrEmDash({
                    value: contractDetail?.pricing_status,
                    itemToRender: (
                      <Badge colorScheme={isPriced ? 'green' : 'gray'}>
                        {parseFloat(contractDetail?.pricing_status) ? '$' : ''}
                        {contractDetail?.pricing_status}
                      </Badge>
                    ),
                  })}
                </SkeleKeyValue>
                <SkeleKeyValue isLoaded={isLoaded} label="Crop Year">
                  {renderOrEmDash({ value: contractDetail?.crop_year })}
                </SkeleKeyValue>
              </Stack>
            </SimpleGrid>
          </DetailGrid.ListCard>
        </DetailGrid.List>
      </DetailGrid>

      {contractDetail?.comments && (
        <>
          <DetailGrid.Title>Comments</DetailGrid.Title>
          <Box layerStyle="detail-card">
            <Skeleton isLoaded={isLoaded}>
              <Text whiteSpace="pre-line">{contractDetail?.comments}</Text>
            </Skeleton>
          </Box>
        </>
      )}

      <DetailGrid.Title>Progress</DetailGrid.Title>
      <QuantityProgressCharts contractDetail={contractDetail} isLoaded={isLoaded} />
      {contractDetail?.pricing_lines?.length > 0 && (
        <PricingDeliveryTable
          pricingLines={contractDetail?.pricing_lines}
          shouldShowViewButton={showPricingHistoryViewButton}
        />
      )}

      {shouldFetchTicketApplications && (
        <>
          {contractDetail?.ticket_applications?.length > 0 && (
            <AssociatedTickets ticketApps={contractDetail?.ticket_applications} />
          )}
          <AssociatedSettlements contractId={contractDetail?.id} />
        </>
      )}
    </>
  )
}

export function ContractDetailWithDataV3({ id }: { id: number }) {
  const contractDetailQuery = useContractDetailV3({ id })

  return (
    <ContractDetailV3
      isLoaded={contractDetailQuery.isSuccess}
      error={contractDetailQuery.error}
      contractDetail={contractDetailQuery.data}
    />
  )
}
