import { Flex, Progress } from 'src/components/designsystem'
import { DataColorOption, DataColors } from 'src/themes/colors'

export interface ProgressBarProps {
  progressValue: number
  dataColor: DataColorOption
  description: JSX.Element | string
}

function ProgressBar({ progressValue, dataColor, description }: ProgressBarProps) {
  return (
    <Flex direction="column" my={2}>
      <Progress
        value={progressValue}
        _first={{
          borderRadius: 8,
          '& div:first-of-type': {
            bg: `${DataColors[dataColor]}`,
          },
        }}
        w="100%"
        maxW="400px"
        alignSelf="flex-end"
      />

      <Flex
        mt={1}
        fontSize="sm"
        justifyContent={['flex-end', null, null, 'center']}
        alignItems="center"
      >
        {description}
      </Flex>
    </Flex>
  )
}

export default ProgressBar
