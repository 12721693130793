import { CheckboxFilter, DateFilter, Filters, MoreFilters } from 'src/components/designsystem'
import {
  filterOptionCollectionsToFilterOptionItems,
  filterOptionItemsToFilterOptionCollections,
  filterOptionItemsToStringArray,
  stringArrayToFilterOptionItems,
} from 'src/utils/filters'
import { showMore } from 'src/components/contracts/ContractFilters'

export function ContractV3Filters({
  allSelectedFilters,
  filterOptions,
  isLoading,
  setAllSelectedFilters,
  setFilterById,
  isDesktop,
}) {
  return (
    <Filters
      {...{
        allSelectedFilters,
        filterOptions,
        isLoading,
        skeletonCount: 4,
        setAllSelectedFilters,
        setFilterById,
      }}
    >
      {filterOptions?.commodities?.length > 1 && (
        <CheckboxFilter
          filterId="commodities"
          label="Commodity"
          options={filterOptionCollectionsToFilterOptionItems(filterOptions.commodities)}
          values={filterOptionCollectionsToFilterOptionItems(allSelectedFilters?.commodities)}
          onChange={(selected) => {
            setFilterById(
              'commodities',
              filterOptionItemsToFilterOptionCollections(selected?.length > 0 ? selected : null),
              ['Contracts', 'Filter/Commodity']
            )
          }}
        />
      )}
      {filterOptions?.dates && (
        <DateFilter
          filterId="dates"
          label="Delivery Date Range"
          value={allSelectedFilters?.dates}
          onChange={(selected) => {
            setFilterById('dates', selected.from || selected.to ? selected : null, [
              'Contracts',
              'Filter/Delivery Date Range',
            ])
          }}
          initialShowPresets={isDesktop}
        />
      )}

      {filterOptions?.statuses_contract?.length > 1 && (
        <CheckboxFilter
          filterId="statuses_contract"
          label="Status"
          options={stringArrayToFilterOptionItems(filterOptions.statuses_contract)}
          values={stringArrayToFilterOptionItems(allSelectedFilters?.statuses_contract)}
          onChange={(selected) => {
            setFilterById(
              'statuses_contract',
              filterOptionItemsToStringArray(selected?.length > 0 ? selected : null),
              ['Contracts', 'Filter/Status']
            )
          }}
        />
      )}

      {filterOptions?.types?.length > 1 && (
        <CheckboxFilter
          filterId="types"
          label="Contract Type"
          options={stringArrayToFilterOptionItems(filterOptions.types)}
          values={stringArrayToFilterOptionItems(allSelectedFilters?.types)}
          onChange={(selected) => {
            setFilterById(
              'types',
              filterOptionItemsToStringArray(selected?.length > 0 ? selected : null),
              ['Contracts', 'Filter/Type']
            )
          }}
        />
      )}

      {showMore(filterOptions) && (
        <MoreFilters label="More" isMore>
          {filterOptions?.crop_years?.length > 1 && (
            <CheckboxFilter
              filterId="crop_years"
              label="Crop Year"
              options={stringArrayToFilterOptionItems(filterOptions.crop_years)}
              values={stringArrayToFilterOptionItems(allSelectedFilters?.crop_years)}
              onChange={(selected) => {
                setFilterById(
                  'crop_years',
                  filterOptionItemsToStringArray(selected?.length > 0 ? selected : null),
                  ['Contracts', 'Filter/Crop Year']
                )
              }}
              isScrollable={false}
            />
          )}

          {filterOptions.accounts?.length > 1 && (
            <CheckboxFilter
              filterId="accounts"
              label="Account"
              options={filterOptions.accounts}
              values={allSelectedFilters?.accounts}
              isScrollable={false}
              onChange={(selected) => {
                setFilterById('accounts', selected?.length > 0 ? selected : null, [
                  'Contracts',
                  'Filter/Account',
                ])
              }}
            />
          )}

          {filterOptions.companies?.length > 1 && (
            <CheckboxFilter
              filterId="companies"
              label="Company"
              options={filterOptions.companies}
              values={allSelectedFilters?.companies}
              isScrollable={false}
              onChange={(selected) => {
                setFilterById('companies', selected?.length > 0 ? selected : null, [
                  'Contracts',
                  'Filter/Account',
                ])
              }}
            />
          )}

          {filterOptions.locations_contract?.length > 1 && (
            <CheckboxFilter
              filterId="locations_contract"
              label="Contracted Location"
              options={filterOptions.locations_contract}
              values={allSelectedFilters?.locations_contract}
              isScrollable={false}
              onChange={(selected) => {
                setFilterById('locations_contract', selected?.length > 0 ? selected : null, [
                  'Contracts',
                  'Filter/Contracted Location',
                ])
              }}
            />
          )}

          {filterOptions.locations_delivery?.length > 1 && (
            <CheckboxFilter
              filterId="locations_delivery"
              label="Delivery Location"
              options={filterOptions.locations_delivery}
              values={allSelectedFilters?.locations_delivery}
              isScrollable={false}
              onChange={(selected) => {
                setFilterById('locations_delivery', selected?.length > 0 ? selected : null, [
                  'Contracts',
                  'Filter/Delivery Location',
                ])
              }}
            />
          )}

          {filterOptions.statuses_signature?.length > 1 && (
            <CheckboxFilter
              filterId="statuses_signature"
              label="Signature Status"
              options={stringArrayToFilterOptionItems(filterOptions.statuses_signature)}
              values={stringArrayToFilterOptionItems(allSelectedFilters?.statuses_signature)}
              isScrollable={false}
              onChange={(selected) => {
                setFilterById(
                  'statuses_signature',
                  filterOptionItemsToStringArray(selected?.length > 0 ? selected : null),
                  ['Contracts', 'Filter/Signature Status']
                )
              }}
            />
          )}
        </MoreFilters>
      )}
    </Filters>
  )
}
export default ContractV3Filters
