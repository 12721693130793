import { Text } from 'src/components/designsystem'

export default function Timestamp({ children }) {
  return (
    <Text
      backgroundColor="gray.100"
      position="absolute"
      top={0}
      left={0}
      width="100%"
      h={6}
      textAlign="center"
      py={1}
      color="gray.500"
      textStyle="extra-small"
    >
      {children}
    </Text>
  )
}
