import React, { useEffect, useRef, useState } from 'react'
import { Box, Center, useBreakpoint } from 'src/components/designsystem'
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryLabel,
  VictoryPortal,
  VictoryStack,
  VictoryTheme,
  VictoryTooltip,
} from 'victory'
import { ChartLegend, Flyout, ChartFlyoutProps } from 'src/components/designsystem/charts/utils'

export default function StackedBarChart({ chartData, flyoutModal, height }: StackedBarChartProps) {
  const { isMobile } = useBreakpoint()
  const parentRef = useRef(null)
  const [parentWidth, setParentWidth] = useState(0)

  useEffect(() => {
    // Get initial size
    setParentWidth(parentRef?.current?.offsetWidth)

    // Watch for any other window size changes
    addEventListener('resize', () => {
      setParentWidth(0)
      setTimeout(() => {
        setParentWidth(parentRef?.current?.offsetWidth)
      }, 1) // Ensure always executes after parent width is 0
    })
  }, [])

  const labelTextStyle = {
    fontFamily: 'Inter, Roboto, sans-serif',
    fontSize: '12px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: '.08em',
  }

  return (
    <Box width="100%" ref={parentRef}>
      <VictoryChart
        theme={VictoryTheme.material}
        domainPadding={{ x: 50, y: 100 }}
        padding={{
          left: 60,
          bottom: 100,
          top: 40,
        }}
        width={parentWidth}
        height={height ?? 400}
        containerComponent={<VictoryContainer responsive={false} />}
      >
        <VictoryAxis
          tickLabelComponent={
            isMobile ? (
              <VictoryLabel style={labelTextStyle} angle={-45} textAnchor="end" />
            ) : (
              <VictoryLabel style={labelTextStyle} />
            )
          }
        />
        <VictoryAxis
          dependentAxis
          style={{
            tickLabels: labelTextStyle,
          }}
        />
        <VictoryStack
          labelComponent={
            <VictoryTooltip
              flyoutComponent={
                !!flyoutModal && (
                  <VictoryPortal>
                    <StackedBarFlyOut chartDataCollection={chartData} ModalBody={flyoutModal} />
                  </VictoryPortal>
                )
              }
            />
          }
        >
          {chartData.map(({ label, color, data }, i) => (
            <VictoryBar
              key={label}
              barWidth={isMobile ? 20 : 50}
              labels={(_data) => undefined}
              y={(datum) => datum.y}
              cornerRadius={i === chartData.length - 1 ? 4 : null}
              data-testid={label}
              data={data}
              style={{
                data: {
                  fill: color,
                },
              }}
            />
          ))}
        </VictoryStack>
      </VictoryChart>
      <Center width="100%">
        <ChartLegend chartData={chartData} />
      </Center>
    </Box>
  )
}

function StackedBarFlyOut(props: Partial<ChartFlyoutProps>): React.ReactElement {
  const { chartDataCollection, ModalBody } = props
  const currentLabel = props.datum.xName

  const allData = chartDataCollection.flatMap((chart) => chart.data)
  const allDataMax = Math.max(...allData.map((d) => d.y))
  const orientArrowRight = props.datum._group > (allData.length / 2 - 1) / 2
  const yAxisValuesSum = allData
    .filter((val) => val.x === currentLabel)
    .map((data) => data.y)
    .reduce((acc, cur) => acc + cur, 0)
  let yAxisValue = Math.round(
    ((allDataMax * chartDataCollection.length - yAxisValuesSum) /
      (allDataMax * chartDataCollection.length)) *
      200
  )
  if (yAxisValue > 180) yAxisValue = 180

  return (
    <Flyout
      orientArrowRight={orientArrowRight}
      yAxisValue={yAxisValue}
      xAxisValue={orientArrowRight ? props.x - 275 : props.x - 10}
      width="285px"
      height="335px"
      arrowTopMargin="100px"
    >
      <ModalBody {...props} />
    </Flyout>
  )
}
