import { screenOrientationPluginWrapper } from 'src/utils/clients/native/wrappers/screen-orientation-plugin-wrapper'

// NOTE: I think other utilities can be rolled into this.
export const nativeApp = {
  lockOrientation,
  unlockOrientation,
}

async function lockOrientation() {
  await screenOrientationPluginWrapper.lock({ orientation: 'portrait' })
}

async function unlockOrientation() {
  await screenOrientationPluginWrapper.unlock()
}
