import { useState } from 'react'
import {
  Box,
  Button,
  ButtonGroup,
  ErrorIcon,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Text,
  useToast,
} from 'src/components/designsystem'
import { ContentHeavyModal } from 'src/components/designsystem/modals/ContentHeavyModal'
import { farmFieldsQueries } from 'src/data/queries/farm-fields/farm-field-queries'
import { FarmField } from 'src/types/tickets/FarmField'

export function EditFarmFieldModal({
  farmField,
  allFarmFields,
  onBack,
  onDismiss,
}: Readonly<{
  farmField: FarmField
  allFarmFields: FarmField[]
  onBack: () => void
  onDismiss: () => void
}>) {
  // State
  const [inputText, setInputText] = useState(farmField.name)
  const trimmedInputText = inputText.trim()
  const inputError = checkForErrors(trimmedInputText, farmField, allFarmFields)
  const hasInputError = inputError !== undefined

  // Queries
  const editFarmField = farmFieldsQueries.useEditFarmField()

  // Behavior
  const toast = useToast()

  function save() {
    editFarmField.mutate(
      { id: farmField.id, name: trimmedInputText, revision: farmField._revision },
      {
        onSuccess: () => {
          toast({
            title: 'Updated the field name.',
            status: 'success',
            isClosable: true,
          })
          onBack()
        },
        onError: () => {
          toast({
            title: 'Failed to update the field name',
            status: 'error',
            isClosable: true,
          })
        },
      }
    )
  }

  // TSX
  return (
    <ContentHeavyModal
      onBack={onBack}
      onDismiss={onDismiss}
      header={
        <>
          <Text textStyle="h5">Edit field name</Text>
          <Box height={7} />
          <Text>
            Editing this field name will update every instance where this name has been in
            association with a scale ticket.
          </Text>
          <Box height={6} />
          <Text>Field name</Text>
          <Box height={1} />
          <FormControl isInvalid={hasInputError}>
            <FieldNameInput
              inputText={inputText}
              setInputText={setInputText}
              isDisabled={editFarmField.isPending}
            />
            {hasInputError && (
              <HStack marginY={1}>
                <ErrorIcon />
                <FormErrorMessage margin={0}>{inputError}</FormErrorMessage>
              </HStack>
            )}
          </FormControl>
        </>
      }
      body={null}
      footer={
        <ButtonGroup width={{ base: 'full', lg: 'unset' }}>
          <CancelButton onClick={onBack} />
          <SaveButton
            onClick={save}
            isDisabled={hasInputError}
            isLoading={editFarmField.isPending}
          />
        </ButtonGroup>
      }
    />
  )
}

function checkForErrors(
  trimmedInputText: string,
  farmFieldBeingEdited: FarmField,
  allFarmFields: FarmField[]
): string | undefined {
  const isEmpty = trimmedInputText === ''
  const isDuplicate = allFarmFields.some(
    (farmField) => farmField.name === trimmedInputText && farmField.id !== farmFieldBeingEdited.id
  )

  if (isEmpty) {
    return 'Please enter a field name.'
  } else if (isDuplicate) {
    return 'This field name already exists.'
  } else {
    return undefined
  }
}

function FieldNameInput({
  inputText,
  setInputText,
  isDisabled,
}: Readonly<{ inputText: string; setInputText: (text: string) => void; isDisabled: boolean }>) {
  return (
    <Input
      placeholder="Type a field name"
      value={inputText}
      onChange={(event) => setInputText(event.target.value)}
      isDisabled={isDisabled}
    />
  )
}

function CancelButton({
  onClick,
}: Readonly<{
  onClick: () => void
}>) {
  return (
    <Button onClick={onClick} variant="secondary" width={{ base: 'full', lg: '100px' }}>
      Cancel
    </Button>
  )
}

function SaveButton({
  onClick,
  isDisabled,
  isLoading,
}: Readonly<{
  onClick: () => void
  isDisabled: boolean
  isLoading: boolean
}>) {
  return (
    <Button
      onClick={onClick}
      isDisabled={isDisabled}
      isLoading={isLoading}
      variant="primary"
      width={{ base: 'full', lg: '100px' }}
    >
      Save
    </Button>
  )
}
