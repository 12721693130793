import { useEffect } from 'react'
import { initDatadogRUM } from 'src/utils/datadog'

export default function DatadogRumMonitor() {
  useEffect(() => {
    /** Run the Datadog RUM initialization client-side only */
    const init = async () => {
      await initDatadogRUM()
    }
    init()
  }, [])

  return null
}
