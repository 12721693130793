import { useAuth } from 'src/auth'
import { Tooltip } from 'src/components/designsystem'
import { useIsStaffPage } from 'src/utils'
import { useFeatureFlag } from 'configcat-react'
import { CONFIGCAT_FLAGS } from 'src/utils/config-cat'
import BushelWalletButton from './BushelWalletButton'
import { useIsAnyPaymentsFeatureEnabled } from 'src/data/useIsAnyPaymentsFeatureEnabled'

type BushelWalletButtonHub = Readonly<{
  isExpanded: boolean
  showBorder: boolean
}>

/**
 * Eventually will be a dropdown, but for now, just a simple wallet Button on Payment Pages only
 * @returns Bushel Wallet Button if user has permission
 */
export function BushelWalletButtonHub({ isExpanded, showBorder }: BushelWalletButtonHub) {
  const { value: isBushelWalletButtonFeatureFlagEnabled } = useFeatureFlag(
    CONFIGCAT_FLAGS.isBushelWalletButtonEnabled,
    false
  )
  const isAnyPaymentsFeatureEnabled = useIsAnyPaymentsFeatureEnabled()

  const isStaffPage = useIsStaffPage()
  const { isStaff, hasPermission } = useAuth()
  const isWalletAdmin = isStaff && hasPermission('wallet_admin')

  if (!isBushelWalletButtonFeatureFlagEnabled || !isAnyPaymentsFeatureEnabled) return null

  // Show a lo-fi version of the button on payments related merchandiser pages for *all* staff users. Staff Customer View
  if (isStaff && !isStaffPage) {
    return (
      <Tooltip
        hasArrow
        maxW="230px"
        shouldWrapChildren
        placement="bottom"
        textAlign="justify"
        label="Button will open to the customer's Wallet in a new window."
      >
        <BushelWalletButton isDisabled isLoading={false} {...{ isExpanded, showBorder }} />
      </Tooltip>
    )
  }

  // Only show the wallet button to authorized users while viewing staff pages
  if (isStaffPage && !isWalletAdmin) return null

  return <BushelWalletButton {...{ isExpanded, showBorder }} />
}
