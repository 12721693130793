import { useCallback } from 'react'
import { useLocalStorage } from 'src/utils/local-storage'
import { useAuth } from 'src/auth'

// DEPRECATE: Futures is the only thing using this now. It may be worth implementing a dedicated FuturesFavoriting hook,
// but it is out of scope at time of writing.
export function useFavorites(feature: string) {
  const { slug } = useAuth()

  const [storedValue, setValue] = useLocalStorage<Record<string, (string | number)[]>>(
    `bwp-${feature}-favorites`
  )

  const addFavorite = useCallback(
    (favItem: string | number) => {
      setValue((state) => {
        return {
          ...state,
          [slug]: [favItem, ...(state?.[slug] ?? [])],
        }
      })
    },
    [setValue, slug]
  )

  const removeFavorite = useCallback(
    (favItem: string | number) => {
      setValue((state) => {
        return {
          ...state,
          [slug]: state[slug].filter((id) => id !== favItem),
        }
      })
    },
    [setValue, slug]
  )

  const isFavorite = useCallback(
    (favItem: string | number) => storedValue?.[slug]?.includes(favItem),
    [storedValue, slug]
  )

  const sortFavorites = useCallback(
    ({ list = [], favFactor }: { list: any[]; favFactor: string }) => {
      return [...list].sort((a, b) => {
        if (isFavorite(a[favFactor]) && isFavorite(b[favFactor])) return 0
        if (isFavorite(a[favFactor])) return -1
        if (isFavorite(b[favFactor])) return 1
        return 0
      })
    },
    [isFavorite]
  )

  const toggleFavorite = useCallback(
    (favItem: string | number) => {
      storedValue && storedValue[slug]?.includes(favItem)
        ? removeFavorite(favItem)
        : addFavorite(favItem)
    },
    [removeFavorite, addFavorite, storedValue, slug]
  )

  return {
    toggleFavorite,
    isFavorite,
    sortFavorites,
  }
}
