import { insecureClientStorage } from 'src/utils/storage/insecure/insecure-client-storage'

const key = 'bwp-user-group-id'

export function getUserGroupId(): Promise<string | undefined> {
  return insecureClientStorage.get(key)
}

export async function setUserGroupId(value: string) {
  await insecureClientStorage.set(key, value)
}
