import { Flex, Stack, FlexProps } from 'src/components/designsystem'

const PADDING_HORIZONTAL = [4, null, 6]
const BORDER_RADIUS = 6

export function Header({ children }) {
  return (
    <Flex
      alignItems="center"
      px={PADDING_HORIZONTAL}
      py={3}
      borderRadius={BORDER_RADIUS}
      backgroundColor="gray.50"
      textTransform="uppercase"
    >
      {children}
    </Flex>
  )
}

export function Body({ children }) {
  return (
    <Stack spacing={2} py={2}>
      {children}
    </Stack>
  )
}

export function Row({ children, ...props }: FlexProps) {
  return (
    <Flex
      px={PADDING_HORIZONTAL}
      py={4}
      borderRadius={BORDER_RADIUS}
      border="1px solid"
      borderColor="gray.300"
      {...props}
    >
      {children}
    </Flex>
  )
}
