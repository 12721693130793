import { useMerchandiser } from 'src/data/merchandiser'
import { trackEvent } from 'src/utils/analytics'
import {
  Button,
  DocumentIcon,
  Flex,
  MenuItem,
  Spinner,
  useToast,
} from 'src/components/designsystem'
import Link from 'next/link'
import { downloadFile, FileType } from 'src/data'
import { useMutation } from '@tanstack/react-query'
import getClient from 'src/utils/clients/get-client'

export function PdfViewLink({
  isActionMenu,
  slug,
  item,
  itemType,
}: Readonly<{
  isActionMenu?: boolean
  slug: string
  item: Contract | Settlement
  itemType: 'contract' | 'settlement'
}>) {
  const { userId } = useMerchandiser()
  const href = makePdfLink({ slug, userId, item, itemType })
  const pdfTypeCapitalized = itemType.charAt(0).toUpperCase() + itemType.slice(1).toLowerCase()
  const commonProps = {
    colorScheme: 'blue',
    'aria-label': 'View PDF',
    href,
    rel: 'noreferrer',
    target: '_blank',
    onClick: (e) => {
      trackEvent(pdfTypeCapitalized, 'View PDF')
      e.stopPropagation()
    },
  }

  const isNativeApp = getClient().isNativeApp
  const toast = useToast()
  const { selectedUserId } = useMerchandiser()

  const nativeSharePdfMutation = useMutation({
    mutationFn: async () => {
      const response = await fetch(
        [window.location.origin, 'api', `${itemType}s`, 'pdf'].join('/'),
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            companySlug: slug,
            itemId: item.id,
            impersonateId: selectedUserId ?? null,
          }),
        }
      )

      if (!response.ok) {
        throw new Error('Unable to fetch PDF')
      }

      // Get file name from PDF response header -> Content-Disposition: attachment; filename="filename.pdf"
      const responseHeaderFilename = response.headers
        .get('content-disposition')
        ?.split('filename=')[1]
        ?.split('"')
        .join('')

      return downloadFile({
        apiResponse: response,
        fileType: FileType.PDF,
        fileNameOverride: responseHeaderFilename || null,
      })
    },
    onError: (error: any) => {
      toast({
        status: 'error',
        title: error?.message ?? 'Failed to export',
      })
    },
  })

  if (isActionMenu) {
    return (
      <MenuItem
        {...(!isNativeApp
          ? {
              as: 'a',
              href,
              target: '_blank',
              rel: 'noreferrer',
            }
          : {})}
        onClick={async (e) => {
          trackEvent(pdfTypeCapitalized, 'View PDF')
          e.stopPropagation()

          if (isNativeApp) return nativeSharePdfMutation.mutate()
        }}
        aria-label="View PDF"
        textStyle="body"
        icon={<DocumentIcon fontSize="xl" />}
      >
        View PDF
      </MenuItem>
    )
  } else {
    return (
      <Button
        {...(isNativeApp
          ? {
              onClick: () => {
                nativeSharePdfMutation.mutate()
              },
            }
          : {
              as: Link,
              href,
              prefetch: false,
              ...commonProps,
            })}
        leftIcon={<DocumentIcon fontSize="2xl" />}
        textStyle="body"
        fontWeight="normal"
        _active={{ backgroundColor: 'blue.400', color: 'white' }}
        variant="ghostSecondary"
        width="100%"
        justifyContent="flex-start"
      >
        <Flex flexGrow={1} justifyContent="space-between">
          View PDF
          {nativeSharePdfMutation.isPending && <Spinner size="sm" />}
        </Flex>
      </Button>
    )
  }
}

export function makePdfLink({
  slug,
  userId,
  item,
  itemType,
}: {
  slug: string
  userId?: string
  item: Contract | Settlement
  itemType: 'contract' | 'settlement'
}) {
  const base = userId ? `/${slug}/${userId}` : `/${slug}`
  return `${base}/${itemType}s/${item.id}/pdf`
}
