import { useMemo } from 'react'
import {
  AUTHZ_PERMISSIONS_TO_CENTRE_ROLES,
  type AuthzPermission,
} from 'src/api/bidl/iam/authz-permissions'
import { useAuth } from 'src/auth'
import { useListTenantPermissions } from 'src/data/queries/authorization'
import { CONFIGCAT_FLAGS, configCatSSRClient } from 'src/utils/config-cat'

export function useHasStaffPermission(permission: AuthzPermission) {
  const { hasPermission: hasRole } = useAuth()

  const configCatSnapshot = configCatSSRClient.snapshot()

  const isAuthzPermissionsFlagEnabled = configCatSnapshot.getValue(
    CONFIGCAT_FLAGS.isAuthzPermissionsEnabled,
    false
  )
  const {
    data: listOfTenantPermissions,
    isLoading,
    isError,
  } = useListTenantPermissions({ enabled: isAuthzPermissionsFlagEnabled })

  const hasPermission = useMemo(() => {
    if (isAuthzPermissionsFlagEnabled) {
      return listOfTenantPermissions?.includes(permission)
    } else {
      return hasRole(AUTHZ_PERMISSIONS_TO_CENTRE_ROLES[permission])
    }
  }, [isAuthzPermissionsFlagEnabled, listOfTenantPermissions, hasRole, permission])

  return { hasPermission, isLoading, isError }
}
