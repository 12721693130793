import { parseCookies } from 'nookies'
import { useAuth } from 'src/auth'
import getConfigCatUser from 'src/utils/config-cat/getConfigCatUser'

/**
 * Custom hook to get the ConfigCat user object for use in SSR flag evaluations
 * !You probably want to use `useFeatureFlag` directly, instead
 * @returns The ConfigCat user object
 */
export default function useConfigCatUser() {
  const { session, user } = useAuth()
  const cookies = parseCookies()

  return getConfigCatUser(session, cookies, { UserType: user?.user_type })
}
