import { Flex } from '@chakra-ui/react'

export function Tile({ children }) {
  return (
    <Flex
      alignSelf="center"
      width={['90%', '80%', null, '70%', '40%', '30%']}
      backgroundColor="white"
      boxShadow={['none', 'xs']}
      borderRadius={['none', 'lg', '2xl']}
      paddingY={[8, 12]}
      paddingX={[null, 24, null, 12]}
      flexDirection="column"
      alignItems="center"
    >
      {children}
    </Flex>
  )
}
