import { Box, Heading, Stack, StackDivider, Text, VStack } from 'src/components/designsystem'

interface SummaryRowProps {
  values: {
    label: string
    value: string | number
  }[]
  title?: string
}

export default function SummaryRow({ title, values }: SummaryRowProps) {
  return (
    <VStack key={title} w="full">
      {title && (
        <Heading type="h5" ml={0} mr="auto">
          <Box as="span" textStyle="h6">
            {title}
          </Box>
        </Heading>
      )}
      <Stack
        borderColor="gray.300"
        borderWidth="1px"
        borderRadius="lg"
        divider={<StackDivider borderColor="gray.300" />}
        w="full"
        px={2}
        py={4}
        direction="row"
      >
        {values.map(({ label, value }) => (
          <Stack
            key={`${title}-${label}`}
            w={((1 / values.length) * 100).toFixed(2) + '%'}
            direction="column"
            justify="space-between"
          >
            <SummaryItemText label={label} value={value} />
          </Stack>
        ))}
      </Stack>
    </VStack>
  )
}

function SummaryItemText({ label, value }: { label: string; value: string | number }) {
  return (
    <>
      <Text
        textColor="gray.500"
        textTransform="uppercase"
        textAlign="center"
        textStyle="preTitle"
        whiteSpace={[null, 'nowrap', 'normal']}
      >
        {label}
      </Text>
      <Text textAlign="center" textStyle="h5">
        {value}
      </Text>
    </>
  )
}
