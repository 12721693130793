import { useCallback, Fragment, ReactNode } from 'react'
import {
  ActionMenuIcon,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  useBreakpoint,
} from 'src/components/designsystem'

export interface ResourceListItemActionMenuProps<Item> {
  actionMenuConfig: {
    key: string
    render?: (item: any) => ReactNode
    setMobileActionMenuItem?: (item: Item) => void
    onMobileActionMenuClose?: () => void
  }[]
  actionMenuModalSetItem: (item: Item) => void
  actionMenuModalOnOpen: () => void
  item: Item
}

export const sharedActionMenuButtonProps = {
  size: 'xs',
  variant: 'ghostSecondary',
  'aria-label': 'More Actions',
  fontSize: 'lg',
  icon: <ActionMenuIcon />,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minW: 'auto',
  w: 6,
  isRound: true,
  'data-testid': 'kebab-button',
}

export default function ResourceListItemActionMenu({
  actionMenuConfig,
  actionMenuModalSetItem,
  actionMenuModalOnOpen,
  item,
}: ResourceListItemActionMenuProps<any>) {
  const { isMobile } = useBreakpoint()
  const onClickHandler = useCallback(() => {
    actionMenuModalSetItem(item)
    actionMenuModalOnOpen()
  }, [item, actionMenuModalSetItem, actionMenuModalOnOpen])

  return (
    <Flex justify="right">
      {isMobile ? (
        <IconButton {...sharedActionMenuButtonProps} onClick={onClickHandler} />
      ) : (
        <Menu autoSelect={false}>
          <MenuButton
            as={IconButton}
            {...sharedActionMenuButtonProps}
            onClick={(e) => {
              // Do not expand element underneath if action menu is clicked
              e.stopPropagation()
            }}
          />

          <MenuList>
            {actionMenuConfig?.map((config) => {
              return <Fragment key={config.key}>{config.render(item)}</Fragment>
            })}
          </MenuList>
        </Menu>
      )}
    </Flex>
  )
}
