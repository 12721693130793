import { createIcon } from '@chakra-ui/react'

export const TrashBin = createIcon({
  defaultProps: {
    'aria-hidden': true,
    fill: 'currentColor',
  },
  displayName: 'TrashBin',
  viewBox: '0 0 18 21',
  d: 'M17.25 3.5h-4.5V2.37A1.87 1.87 0 0 0 10.87.5H7.13a1.88 1.88 0 0 0-1.88 1.88V3.5H.75a.75.75 0 0 0 0 1.5h.8l.89 14.3a2.26 2.26 0 0 0 2.25 2.2h8.62c1.23 0 2.17-.93 2.25-2.2l.9-14.3h.79a.75.75 0 1 0 0-1.5Zm-11.22 15H6a.75.75 0 0 1-.75-.72l-.38-10.5a.75.75 0 0 1 1.5-.06l.38 10.5a.75.75 0 0 1-.72.78Zm3.72-.75a.75.75 0 1 1-1.5 0V7.25a.75.75 0 0 1 1.5 0v10.5Zm1.5-14.25h-4.5V2.37A.37.37 0 0 1 7.13 2h3.75a.37.37 0 0 1 .37.38V3.5Zm1.5 14.28a.75.75 0 0 1-.75.72h-.03a.75.75 0 0 1-.72-.78l.38-10.5a.75.75 0 0 1 1.5.06l-.38 10.5Z',
})
