import { Box, Divider, Flex, Skeleton, Spacer, Text, VStack } from 'src/components/designsystem'
import {
  DetailGrid,
  ErrorState,
  FormattedDate,
  renderOrEmDash,
  SkeleKeyValue,
} from 'src/components/resource'
import { useInvoiceDetail } from 'src/data/queries'
import * as ProductTable from 'src/components/invoices/ProductTable'
import { HTTPError } from 'src/api'
import { useConfig } from 'src/data/config'
import PaymentStatusBadge from 'src/components/admin/payments/PaymentStatusBadge'
import dayjs from 'dayjs'

export interface InvoiceDetailWithDataProps {
  id: number
  isOpen: boolean
}

export function InvoiceDetailWithData({ id, isOpen }: InvoiceDetailWithDataProps) {
  const invoiceDetailQuery = useInvoiceDetail({ id, enabled: isOpen })

  return (
    <InvoiceDetail
      isLoaded={invoiceDetailQuery.isSuccess}
      error={invoiceDetailQuery.error}
      invoiceDetail={invoiceDetailQuery.data}
    />
  )
}

interface InvoiceDetailProps {
  isLoaded?: boolean
  error?: HTTPError
  invoiceDetail: InvoiceDetail
}

export function InvoiceDetail({ isLoaded, error, invoiceDetail }: InvoiceDetailProps) {
  const { config } = useConfig()
  const isStatusColumnEnabled = config.config.show_invoice_status

  if (error) {
    return (
      <ErrorState
        subHeader={
          error?.response?.payload?.error?.message ||
          "There was an error while retrieving this invoice's details. Please refresh the page to try again."
        }
      />
    )
  }

  if (!isLoaded) {
    return <InvoiceSkeleton />
  }

  return (
    <>
      <DetailGrid.Title>Breakdown</DetailGrid.Title>
      <DetailGrid.TopHeading minH={['40px', null, '48px']} ml={[4, 5]}>
        Details
      </DetailGrid.TopHeading>
      <DetailGrid px={[4, null, 0]} templateColumns={['1fr', null, 'repeat(2, 1fr)']}>
        <DetailGrid.List>
          <Box width="100%" px={[0, null, 6]} borderRadius={8}>
            <SkeleKeyValue isLoaded={isLoaded} label="Account ID">
              {renderOrEmDash({ value: invoiceDetail?.remote_user_id })}
            </SkeleKeyValue>
            <Divider />
            <SkeleKeyValue isLoaded={isLoaded} label="Invoice Date">
              {renderOrEmDash({
                value: invoiceDetail?.invoice_date,
                itemToRender: <FormattedDate date={invoiceDetail?.invoice_date} localize={false} />,
              })}
            </SkeleKeyValue>
            <Divider />
            <SkeleKeyValue isLoaded={isLoaded} label="Invoice Due Date">
              {renderOrEmDash({
                value: invoiceDetail?.invoice_due_date,
                itemToRender: (
                  <FormattedDate
                    date={invoiceDetail?.invoice_due_date}
                    localize={false}
                    color={
                      dayjs().isAfter(invoiceDetail.invoice_due_date) &&
                      invoiceDetail.status?.toUpperCase() === 'UNPAID'
                        ? 'red'
                        : undefined
                    }
                  />
                ),
              })}
            </SkeleKeyValue>
          </Box>
        </DetailGrid.List>
        <Divider display={['unset', 'unset', 'none']} />
        <DetailGrid.List>
          <Box width="100%" px={[0, null, 6]} borderRadius={8}>
            <SkeleKeyValue isLoaded={isLoaded} label="Document ID">
              {renderOrEmDash({ value: invoiceDetail?.display_id })}
            </SkeleKeyValue>
            {isStatusColumnEnabled && (
              <>
                <Divider />
                <SkeleKeyValue isLoaded={isLoaded} label="Status">
                  {renderOrEmDash({
                    value: invoiceDetail?.status,
                    itemToRender: <PaymentStatusBadge status={invoiceDetail?.status} />,
                  })}
                </SkeleKeyValue>
              </>
            )}
          </Box>
        </DetailGrid.List>
      </DetailGrid>

      {invoiceDetail?.comments?.length > 0 && (
        <>
          <DetailGrid.Title>Comments</DetailGrid.Title>
          <Box layerStyle="detail-card">
            <Skeleton isLoaded={isLoaded}>
              <VStack spacing={4} align="stretch">
                {invoiceDetail?.comments.map((comment, index) => {
                  return (
                    <Text key={index} whiteSpace="pre-line">
                      {comment}
                    </Text>
                  )
                })}
              </VStack>
            </Skeleton>
          </Box>
        </>
      )}

      {!!invoiceDetail?.invoice_products && invoiceDetail?.invoice_products.length > 0 && (
        <>
          <DetailGrid.Title>Products</DetailGrid.Title>
          <ProductTable.Header>
            <Text textStyle="preTitle">Description</Text>
            <Spacer />
            <Text textStyle="preTitle">Total</Text>
          </ProductTable.Header>
          <ProductTable.Body>
            {invoiceDetail?.invoice_products?.map(
              ({
                id: productId,
                prepaid_applied,
                pricing_details,
                product_name,
                product_total,
                tax,
              }) => {
                const hasAdditionalDetails = !!prepaid_applied || !!tax
                return (
                  <ProductTable.Row key={productId} flexDir="column">
                    <Flex w="100%">
                      <Flex flexDir="column">
                        <Text fontWeight="bold">{product_name}</Text>
                        <Text fontSize="sm">{pricing_details}</Text>
                      </Flex>
                      <Spacer />
                      <Text textStyle="body-bold">{product_total}</Text>
                    </Flex>
                    {hasAdditionalDetails && (
                      <>
                        <Box mt={4} />
                        {!!prepaid_applied && (
                          <SkeleKeyValue
                            py={0}
                            minH="auto"
                            isLoaded={isLoaded}
                            label="Prepaid Applied"
                            fontWeight="normal"
                          >
                            {renderOrEmDash({ value: prepaid_applied })}
                          </SkeleKeyValue>
                        )}
                        {!!tax && (
                          <SkeleKeyValue
                            py={0}
                            minH="auto"
                            isLoaded={isLoaded}
                            label="Tax"
                            fontWeight="normal"
                          >
                            {renderOrEmDash({ value: tax })}
                          </SkeleKeyValue>
                        )}
                      </>
                    )}
                  </ProductTable.Row>
                )
              }
            )}
          </ProductTable.Body>
        </>
      )}

      <Flex w="100%" justifyContent="flex-end" mt={4}>
        <VStack
          spacing={0}
          borderRadius={8}
          px={[4, null, 6]}
          width={['100%', null, '50%']}
          divider={<Divider />}
        >
          {!!invoiceDetail?.invoice_totals.subtotal && (
            <SkeleKeyValue isLoaded label="Product Subtotal">
              {invoiceDetail?.invoice_totals.subtotal}
            </SkeleKeyValue>
          )}
          {!!invoiceDetail?.invoice_totals.product_tax && (
            <SkeleKeyValue isLoaded label="Total Product Tax">
              {invoiceDetail?.invoice_totals.product_tax}
            </SkeleKeyValue>
          )}
          {!!invoiceDetail?.invoice_totals.other_tax && (
            <SkeleKeyValue isLoaded label="Other Tax">
              {invoiceDetail?.invoice_totals.other_tax}
            </SkeleKeyValue>
          )}
          {!!invoiceDetail?.invoice_totals.product_prepaid_applied && (
            <SkeleKeyValue isLoaded label="Total Product Prepaid">
              {invoiceDetail?.invoice_totals.product_prepaid_applied}
            </SkeleKeyValue>
          )}
          {!!invoiceDetail?.invoice_totals.other_prepaid_applied && (
            <SkeleKeyValue isLoaded label="Other Prepaid">
              {invoiceDetail?.invoice_totals.other_prepaid_applied}
            </SkeleKeyValue>
          )}
          {!!invoiceDetail?.invoice_totals.payments_applied && (
            <SkeleKeyValue isLoaded label="Payments Applied">
              {invoiceDetail?.invoice_totals.payments_applied}
            </SkeleKeyValue>
          )}
        </VStack>
      </Flex>
      <TotalBlock amountDue={invoiceDetail?.invoice_totals?.amount_due} />
    </>
  )
}

const TotalBlock = ({ amountDue }: { amountDue: InvoiceTotals['amount_due'] }) => (
  <Flex w={['calc(100% - 8px)', null, 'calc(100% - 16px)']} justifyContent="flex-end" mt={8}>
    <Flex alignItems="center" px={2} py={1} borderRadius={8} fontWeight="bold">
      Total:
      <Box as="span" fontSize={24} ml={3}>
        {amountDue}
      </Box>
    </Flex>
  </Flex>
)

const InvoiceSkeleton = () => (
  <>
    <DetailGrid.Title>Breakdown</DetailGrid.Title>
    <DetailGrid.TopHeading minH={['40px', null, '48px']} ml={[4, 5]}>
      Details
    </DetailGrid.TopHeading>
    <DetailGrid px={[4, null, 0]} templateColumns={['1fr', null, 'repeat(2, 1fr)']}>
      <DetailGrid.List>
        <Box width="100%" px={[0, null, 6]} borderRadius={8}>
          <SkeleKeyValue isLoaded={false} label="Account ID">
            abc123
          </SkeleKeyValue>
          <Divider />
          <SkeleKeyValue isLoaded={false} label="Invoice Date">
            abc123
          </SkeleKeyValue>
        </Box>
      </DetailGrid.List>
      <Divider display={['unset', 'unset', 'none']} />
      <DetailGrid.List>
        <Box width="100%" px={[0, null, 6]} borderRadius={8}>
          <SkeleKeyValue isLoaded={false} label="Invoice Due Date">
            abc123
          </SkeleKeyValue>
          <Divider />
          <SkeleKeyValue isLoaded={false} label="Document ID">
            abc123
          </SkeleKeyValue>
        </Box>
      </DetailGrid.List>
    </DetailGrid>
    <DetailGrid.Title>Products</DetailGrid.Title>
    <ProductTable.Header>
      <Text textStyle="preTitle">Description</Text>
      <Spacer />
      <Text textStyle="preTitle">Total</Text>
    </ProductTable.Header>
    <ProductTable.Body>
      <ProductTable.Row flexDir="column">
        <Flex w="100%" mb={8}>
          <Flex flexDir="column">
            <Skeleton isLoaded={false}>abc123</Skeleton>
          </Flex>
          <Spacer />
          <Skeleton isLoaded={false}>abc123</Skeleton>
        </Flex>
        <SkeleKeyValue
          py={0}
          mb={2}
          minH="auto"
          isLoaded={false}
          label="Prepaid Applied"
          fontWeight="normal"
        >
          abc123
        </SkeleKeyValue>
        <SkeleKeyValue py={0} minH="auto" isLoaded={false} label="Tax" fontWeight="normal">
          abc123
        </SkeleKeyValue>
      </ProductTable.Row>
    </ProductTable.Body>
    <Flex mt={6} w="100%" justifyContent="flex-end">
      <Box width={['100%', null, '50%']} px={[4, null, 6]} borderRadius={8}>
        <SkeleKeyValue isLoaded={false} label="Product Subtotal">
          abc123
        </SkeleKeyValue>
        <Divider />
        <SkeleKeyValue isLoaded={false} label="Total Product Tax">
          abc123
        </SkeleKeyValue>
        <Divider />
        <SkeleKeyValue isLoaded={false} label="Other Tax">
          abc123
        </SkeleKeyValue>
        <Divider />
        <SkeleKeyValue isLoaded={false} label="Total Product Prepaid">
          abc123
        </SkeleKeyValue>
        <Divider />
        <SkeleKeyValue isLoaded={false} label="Other Prepaid">
          abc123
        </SkeleKeyValue>
      </Box>
    </Flex>
    <Flex w="calc(100% + 8px)" justifyContent="flex-end" my={8}>
      <Skeleton isLoaded={false} w="150px" mt={6} mr={6}>
        abc123
      </Skeleton>
    </Flex>
  </>
)
