import { FlyoutProps as VictoryFlyoutProps } from 'victory'
import { Box, Circle, Flex, Square, Text, useToken } from 'src/components/designsystem'

interface ChartLegendProps {
  chartData: Partial<BarChartDataCollection>[]
  vertical?: boolean
}

export function ChartLegend({ chartData, vertical = false }: ChartLegendProps) {
  return (
    <Flex
      py={4}
      direction={vertical ? 'column-reverse' : 'row-reverse'}
      align={vertical ? 'left' : 'center'}
    >
      {chartData.map((d) => (
        <Flex px={3} key={d.label} py={vertical ? 2 : null}>
          <Square size={6}>
            <Circle size={2} bg={d.color} data-testid="color-dot" />
          </Square>
          <Text key={d.label} textStyle="body-bold" textTransform="uppercase">
            {d.label}
          </Text>
        </Flex>
      ))}
    </Flex>
  )
}

export interface ChartFlyoutProps extends VictoryFlyoutProps {
  chartData: BarChartDataCollection
  chartDataCollection?: BarChartDataCollection[]
  cornerRadius: number
  hideArrow: boolean
  isDesktop: boolean
  ModalBody: any
  datum?: {
    x?: number
    xName?: string
    y?: number
    yName?: string
    _group?: number
    _x?: number
    _y?: number
  }
}

interface FlyoutProps {
  yAxisValue: number
  xAxisValue: number
  width: string
  height: string
  orientArrowRight: boolean
  arrowTopMargin: string
  hideArrow?: boolean
}

export function Flyout({
  children,
  yAxisValue,
  xAxisValue,
  width,
  height,
  orientArrowRight,
  arrowTopMargin,
  hideArrow,
}: React.PropsWithChildren<FlyoutProps>) {
  return (
    <g style={{ pointerEvents: 'none' }}>
      <foreignObject x={xAxisValue} y={yAxisValue} width={width} height={height}>
        <FlyoutTooltipWrapper
          orientArrowRight={orientArrowRight}
          top={arrowTopMargin}
          hideArrow={hideArrow}
        >
          {children}
        </FlyoutTooltipWrapper>
      </foreignObject>
    </g>
  )
}

export function FlyoutTooltipWrapper({ orientArrowRight, children, top, hideArrow = false }) {
  const boxShadow = useToken('shadows', 'lg')

  return (
    <Box position="relative" data-testid="flyout">
      {!hideArrow && (
        <Box
          p={1}
          position="absolute"
          top={top}
          left={orientArrowRight ? undefined : '30px'}
          right={orientArrowRight ? '30px' : undefined}
          transform={orientArrowRight ? `rotate(180deg)` : undefined}
        >
          <svg width="10" height="20" viewBox="0 0 10 20" fill="none">
            <path d="M0 10L10 0L10 20L0 10Z" fill="white" />
          </svg>
        </Box>
      )}
      <Box width="200px" mx="auto" mt={12}>
        <Box
          bgColor="white"
          borderRadius={8}
          style={{
            boxShadow,
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  )
}
