import { StatementFiltersResponse } from 'src/components/statements/StatementFilters'
import { ENV } from 'src/utils/env'
import { bidlClient } from 'src/api/bidl/client'

const statementsBaseUrl = 'api/aggregator/statement'

export const statementsRequests = {
  getAllStatements: (options?: StatementListOptions) => {
    return bidlClient
      .post(`${statementsBaseUrl}/v1/GetAllStatements`, {
        json: options,
      })
      .json<StatementsResponse>()
  },
  getStatementsFilters: ({ filters }: { filters?: StatementFilterOptions }) =>
    bidlClient
      .post(`${statementsBaseUrl}/v1/GetStatementsFilters`, { json: filters ?? {} })
      .json<StatementFiltersResponse>(),
  getStatementsExport: (options?: StatementListOptions) => {
    // CSV export does not follow standard BIDL aggregator path
    const extendedAggregatorClient = bidlClient.extend({
      prefixUrl: ENV.AGGREGATOR_API,
    })

    return extendedAggregatorClient.post('api/v1/statements/export-csv', {
      json: options,
    })
  },
}
