import { DetailGrid, useDetailModal } from 'src/components/resource'
import { CollapsableDetailTable, CollapsableDetailTableProps } from 'src/components/designsystem'
import DisplayConfig, { DisplayConfigItem } from 'src/components/designsystem/display-config'
import PricingLineDetail from 'src/components/contracts/contracts-v3/v3-contract-visuals/PricingLineDetail'
import { usePricingLineDetail } from 'src/data/queries'
import { FloatingModalWithBackButtonBehavior } from 'src/components/resource/FloatingModalWithBackButtonBehavior'

const DISPLAY_CONFIG: DisplayConfigItem<ContractPricingLine>[] = [
  {
    label: 'No.',
    key: 'display_id',
    ValueCellComponent: DisplayConfig.BoldTextValue,
  },
  {
    label: 'Delivery',
    getValue: () => true,
    ValueCellComponent: DisplayConfig.BoldTextValue,
    columnWidth: '20%',
    renderValue: ({ data }) => renderDeliveryPeriod(data),
  },
  {
    label: 'Location',
    key: 'location_name',
    ValueCellComponent: DisplayConfig.BoldTextValue,
    columnWidth: '10%',
  },
  {
    label: 'Amount',
    key: 'quantity_priced',
    ValueCellComponent: DisplayConfig.BoldTextValue,
    textAlign: 'right',
  },
  {
    label: 'Futures Price',
    key: 'futures_price',
    ValueCellComponent: DisplayConfig.BoldTextValue,
    textAlign: 'right',
  },
  {
    label: 'Basis',
    key: 'basis_price',
    ValueCellComponent: DisplayConfig.BoldTextValue,
    textAlign: 'right',
  },
  {
    label: 'Adj.',
    key: 'adjustment',
    ValueCellComponent: DisplayConfig.BoldTextValue,
    textAlign: 'right',
  },
  {
    label: 'Cash Price',
    key: 'cash_price',
    ValueCellComponent: DisplayConfig.BoldTextValue,
    textAlign: 'right',
  },
  {
    label: 'Futures Month',
    key: 'futures_month',
    ValueCellComponent: DisplayConfig.BoldTextValue,
  },
]

export function renderDeliveryPeriod(data: ContractPricingLine | ContractPricingLineDetail) {
  if (data?.delivery_period_start?.length && data?.delivery_period_end?.length) {
    return `${data.delivery_period_start} - ${data.delivery_period_end}`
  }

  return 'No Delivery Dates Available'
}

type PricingDeliveryTableProps = {
  pricingLines: ContractPricingLine[]
} & Pick<CollapsableDetailTableProps<ContractPricingLine>, 'shouldShowViewButton'>

function PricingDeliveryTable({ pricingLines, shouldShowViewButton }: PricingDeliveryTableProps) {
  const { item, setItem, onClose } = useDetailModal<ContractPricingLine>()

  return (
    <>
      <DetailGrid.Title>Pricing &amp; Delivery</DetailGrid.Title>
      <CollapsableDetailTable
        rowItems={pricingLines}
        rowItemTitle="pricing"
        displayConfig={DISPLAY_CONFIG}
        analyticsCategory="Contracts V3"
        analyticsAction="Contracts V3 Pricing Line List"
        {...{ setItem, shouldShowViewButton }}
      />
      {item && (
        <PricingLineDetailModal
          name={`Pricing Details - ${item.display_id}`}
          pricingLine={item}
          onClose={onClose}
        />
      )}
    </>
  )
}

export default PricingDeliveryTable

function PricingLineDetailModal({
  name,
  pricingLine,
  onClose,
}: {
  name: string
  pricingLine: ContractPricingLine
  onClose: () => void
}) {
  const { isSuccess, error, data: pricingLineDetail } = usePricingLineDetail({ id: pricingLine.id })

  return (
    <FloatingModalWithBackButtonBehavior
      isOpen={!!pricingLine}
      primaryTitle={name}
      onClose={onClose}
      backAction={onClose}
    >
      <PricingLineDetail isLoaded={isSuccess} error={error} pricingLineDetail={pricingLineDetail} />
    </FloatingModalWithBackButtonBehavior>
  )
}
