import { Box, Button, ButtonGroup, Text, useToast } from 'src/components/designsystem'
import { ContentHeavyModal } from 'src/components/designsystem/modals/ContentHeavyModal'
import { farmFieldsQueries } from 'src/data/queries/farm-fields/farm-field-queries'
import { FarmField } from 'src/types/tickets/FarmField'

export function DeleteFarmFieldModal({
  farmField,
  onBack,
  onDismiss,
}: Readonly<{
  farmField: FarmField
  onBack: () => void
  onDismiss: () => void
}>) {
  // Queries
  const deleteFarmFieldQuery = farmFieldsQueries.useDeleteFarmField()

  // Behavior
  const toast = useToast()

  function deleteFarmField() {
    deleteFarmFieldQuery.mutate(
      { id: farmField.id },
      {
        onSuccess: () => {
          toast({
            title: 'Deleted the field name.',
            status: 'success',
            isClosable: true,
          })
          onBack()
        },
        onError: () => {
          toast({
            title: 'Failed to delete the field name',
            status: 'error',
            isClosable: true,
          })
        },
      }
    )
  }

  // TSX
  return (
    <ContentHeavyModal
      onBack={onBack}
      onDismiss={onDismiss}
      header={
        <>
          <Text textStyle="h5">Delete field name</Text>
          <Box height={7} />
          <Text>
            This location name will be removed from the location name list and any tickets it was
            previously applied to.
          </Text>
        </>
      }
      body={null}
      footer={
        <ButtonGroup width={{ base: 'full', lg: 'unset' }}>
          <CancelButton onClick={onBack} />
          <DeleteButton onClick={deleteFarmField} isLoading={deleteFarmFieldQuery.isPending} />
        </ButtonGroup>
      }
    />
  )
}

function CancelButton({
  onClick,
}: Readonly<{
  onClick: () => void
}>) {
  return (
    <Button onClick={onClick} variant="secondary" width={{ base: 'full', lg: '100px' }}>
      Cancel
    </Button>
  )
}

function DeleteButton({
  onClick,
  isLoading,
}: Readonly<{ onClick: () => void; isLoading: boolean }>) {
  return (
    <Button
      onClick={onClick}
      isLoading={isLoading}
      variant="destructive"
      width={{ base: 'full', lg: '100px' }}
    >
      Delete
    </Button>
  )
}
