import { useCallback, useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { Button, Center, Img, Modal, ModalProps, useDisclosure } from 'src/components/designsystem'
import { trackEvent } from 'src/utils/analytics'
import { useESignReminders, queryClient } from 'src/data/queries'
import { useConfig } from 'src/data/config'
import { useAuth } from 'src/auth'
import * as api from 'src/api'

export function useEsignReminder() {
  const router = useRouter()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isFeatureEnabled } = useConfig()
  const { authenticated, user, isStaff } = useAuth()
  const isAuthRoute = router.pathname.indexOf('/auth') === 0
  const isESignEnabled = isFeatureEnabled('esign')
  const isESignPage = router.asPath?.includes('/esign') && !router.asPath?.includes('/esign/')
  const isQueryEnabled =
    authenticated && !isAuthRoute && isESignEnabled && !isStaff && user?.account_ids?.length > 0

  const reminderQuery = useESignReminders({ isEnabled: isQueryEnabled })
  const showReminder = !!reminderQuery.data?.data?.show_reminder
  const unsignedCount = reminderQuery.data?.data?.unsigned_document_count
  const badgeCounts = useMemo(() => ({ esign: unsignedCount }), [unsignedCount])

  useEffect(() => {
    if (showReminder) onOpen()
  }, [showReminder, onOpen])

  return {
    isOpen,
    onOpen,
    onClose,
    isESignPage,
    isFeatureEnabled: isESignEnabled,
    isSuccess: reminderQuery.isSuccess,
    data: reminderQuery.data?.data,
    handleDismiss: useCallback(async () => api.centre.eSignRemindersDismiss(), []),
    badgeCounts,
  }
}

export interface ESignReminderModalProps {
  message: string
  onClose?: () => void
  handleDismiss?: any
  isOpen: boolean
}

export default function ESignReminderModal({
  message,
  onClose,
  handleDismiss,
  isOpen,
}: ESignReminderModalProps & Partial<ModalProps>) {
  const router = useRouter()
  const company = router?.query?.company
  const eSignPath = `/${company}/esign`
  const title = 'Ready to sign!'
  const trackingTitle = 'eSign'

  const executeDismiss = useCallback(async () => {
    await handleDismiss()
    await queryClient.invalidateQueries({ queryKey: ['esign-reminders'] })
  }, [handleDismiss])

  function handleGoToEsignButton() {
    trackEvent(trackingTitle, 'Modal go to eSign')
    onClose()
    executeDismiss()
  }

  function handleClose() {
    trackEvent(trackingTitle, 'Modal Dismiss')
    onClose()
    executeDismiss()
  }

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={handleClose}
      body={
        <>
          <Center mt={8}>
            <Img src="/img/illustrations/sign.png" width={['220px', null, '275px']} />
          </Center>
          <Center fontSize="3xl" fontWeight="bold" mt={6}>
            {title}
          </Center>
          <Center mt={2}>{message}</Center>
          <Center mb={8} mt={8}>
            <Button variant="secondary" mr={3} onClick={handleClose}>
              Dismiss
            </Button>
            <Button
              as={Link}
              href={eSignPath}
              prefetch={false}
              variant="primary"
              onClick={handleGoToEsignButton}
            >
              Go to eSign
            </Button>
          </Center>
        </>
      }
    />
  )
}
