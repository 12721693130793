import { Flex, IconButton, DebugIcon } from 'src/components/designsystem'
import { useRightSidebarStore } from 'src/components/layout/right-sidebar/right-sidebar-store'

export default function AppDebugHeaderButton() {
  const { toggleRightSidebar } = useRightSidebarStore()

  return (
    <Flex>
      <IconButton
        variant="ghostSecondary"
        aria-label="Debug Drawer"
        textColor="red.400"
        icon={<DebugIcon />}
        fontSize="2xl"
        h={8}
        w={8}
        minW={8}
        isRound
        onClick={() => toggleRightSidebar('debug')}
      />
    </Flex>
  )
}
