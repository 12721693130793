import { FarmField } from 'src/types/tickets/FarmField'
import { centreClient } from 'src/api/client'

export const farmFieldsService = {
  getFarmFields,
  createFarmField,
  deleteFarmField,
  editFarmField,
}

async function getFarmFields(): Promise<FarmField[]> {
  const response = await centreClient
    .get(`api/v1/user-defined-farm-fields`)
    .json<{ data: FarmField[] }>()

  return response.data
}

async function createFarmField(name: string): Promise<FarmField> {
  const response = await centreClient
    .post(`api/v1/user-defined-farm-fields`, {
      json: { name },
    })
    .json<{ data: FarmField }>()

  return response.data
}

async function deleteFarmField(id: number): Promise<unknown> {
  return centreClient.delete(`api/v1/user-defined-farm-fields/${id}`)
}

async function editFarmField(id: number, name: string, revision: string): Promise<FarmField> {
  const response = await centreClient
    .put(`api/v1/user-defined-farm-fields/${id}`, {
      json: { name, _revision: revision },
    })
    .json<{ data: FarmField }>()

  return response.data
}
