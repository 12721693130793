import { ReactElement } from 'react'
import { Box, RadioProps, Stack, Text, useRadio, VStack } from '@chakra-ui/react'
import { DownloadCircleIcon, LgCircleCheckIcon } from 'src/components/designsystem'

export function RadioCard({
  title,
  message,
  radioProps,
}: {
  title: string
  message?: string | ReactElement
  radioProps: RadioProps
}) {
  const { getInputProps, getCheckboxProps, state } = useRadio(radioProps)
  const { isChecked } = state

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label" w="100%">
      <input {...input} />
      <VStack
        {...checkbox}
        flexDir="column"
        justify="center"
        cursor="pointer"
        border="1px solid"
        borderColor="gray.300"
        borderRadius="md"
        _checked={{
          bg: 'green.50',
          color: 'green.500',
          borderColor: 'green.500',
        }}
        p={6}
        spacing={4}
      >
        {isChecked ? (
          <LgCircleCheckIcon color="green.500" boxSize={8} borderRadius="100%" />
        ) : (
          <DownloadCircleIcon color="blue.400" boxSize={8} borderRadius="100%" />
        )}
        <Stack align="center">
          <Text fontWeight="bold">{title}</Text>
          {message && (
            <Text fontSize="sm" color={isChecked ? 'green.500' : 'gray.500'} textAlign="center">
              {message}
            </Text>
          )}
        </Stack>
      </VStack>
    </Box>
  )
}
