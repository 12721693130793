import { centreClient } from 'src/api/client'
import {
  APIInvoiceFilterOptions,
  InvoiceFiltersForQuery,
} from 'src/components/invoices/InvoiceFilters'

export type InvoiceResponse = {
  data: Invoice[]
  meta: { pagination: Pagination }
}

export type InvoiceFilterResponse = {
  data: APIInvoiceFilterOptions
}

export type InvoiceReqestBody = {
  filter?: InvoiceFiltersForQuery
}

export const invoiceRequests = {
  invoices: async ({ filter, page }: { page?: number } & InvoiceReqestBody) => {
    let options = {}

    if (page) {
      options = { ...options, searchParams: { page } }
    }

    if (filter && Object.keys(filter).length > 0) {
      options = { ...options, json: { filter } }
    } else {
      options = { ...options, json: { filter: {} } }
    }

    return centreClient.post('api/v1/invoices', options).json<InvoiceResponse>()
  },

  invoiceDetail: async (id: number | string) => {
    const response = await centreClient.get(`api/v1/invoices/${id}`).json<{ data: InvoiceDetail }>()

    return response.data
  },
  invoiceFilterOptions: async () =>
    centreClient.get('api/v1/filters/invoices').json<InvoiceFilterResponse>(),
}
