import { FloatingModal } from 'src/components/designsystem/modals/FloatingModal'
import { FullScreenModal } from 'src/components/designsystem/modals/FullScreenModal'
import { useBreakpoint } from 'src/components/designsystem/utils'

type ContentHeavyModalProps = {
  onBack?: () => void
  onDismiss: () => void
  header: React.ReactNode
  body: React.ReactNode
  footer: React.ReactNode
}

export function ContentHeavyModal({
  onBack,
  onDismiss,
  header,
  body,
  footer,
}: Readonly<ContentHeavyModalProps>) {
  const { isMobile } = useBreakpoint()

  if (isMobile) {
    // The fullscreen modal MUST have a way back. If there isn't anything to "go back" to, it is reasonable to dismiss.
    return <FullScreenModal onBack={onBack ?? onDismiss} {...{ header, body, footer }} />
  } else {
    return <FloatingModal {...{ onBack, onDismiss, header, body, footer }} />
  }
}
