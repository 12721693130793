import { Button, Stack } from 'src/components/designsystem'

export function FeatureList({ items }) {
  return (
    <Stack spacing={4}>
      {items.map((item) => (
        <Button key={item.featureName} variant="outline" w="100%" onClick={item.onClick}>
          {item.ariaLabel}
        </Button>
      ))}
    </Stack>
  )
}
