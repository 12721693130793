import * as api from 'src/api'
import { AppConfig } from 'src/api/centre-service/data-models/app-config'
import { isForceLogoutResponse } from 'src/auth/keycloak'
import { flagr, ENV } from 'src/utils'
import { isTempDeniedSlug } from 'src/utils/temp-denied-slug'

export enum InitStatus {
  OK,
  NO_AUTH,
  NO_COMPANY,
  ERROR,
  FORCE_LOGOUT,
}

const ERROR_MESSAGES = {
  [InitStatus.NO_AUTH]: 'Not Authenticated',
  [InitStatus.NO_COMPANY]: 'Unknown Company',
  [InitStatus.ERROR]: 'There was a problem',
  [InitStatus.FORCE_LOGOUT]: 'Authentication expired, please log in',
}

export interface InitData {
  status: InitStatus
  error: string
  user: Me
  config: AppConfig
  flags: flagr.GlobalFlagsResults
}

interface GetInitDataArgs {
  hasToken?: boolean
  hasAccessCodeBypass?: boolean
  slug: string
}

export async function getInitData({
  hasToken = true,
  hasAccessCodeBypass = false,
  slug,
}: GetInitDataArgs): Promise<InitData> {
  const [
    //
    [user, userError],
    [config, configError],
    [flags, flagrError],
  ] = await fetchInitData({ hasToken, slug })

  const status = await getInitStatus({
    slug,
    hasToken,
    hasAccessCodeBypass,
    userError,
    configError,
    flagrError,
  })

  const error = ERROR_MESSAGES[status]

  return { status, error, user, config, flags }
}

interface GetInitStatusArgs extends GetInitDataArgs {
  userError?: api.HTTPError
  configError?: api.HTTPError
  flagrError?: api.HTTPError
}

async function getInitStatus({
  slug,
  hasToken,
  hasAccessCodeBypass,
  userError,
  configError,
  flagrError,
}: GetInitStatusArgs) {
  if (userError || configError || flagrError) {
    // One or more init requests failed

    if (isUnknownCompany(configError)) {
      // Config request returned 404 - unknown slug
      return InitStatus.NO_COMPANY
    }

    if (hasAuthExpired(hasToken, userError)) {
      // Supplied access token no longer valid
      return InitStatus.NO_AUTH
    }

    const isForceLogout = await hasForceLogoutError(
      [userError?.response, configError?.response].filter(Boolean)
    )

    if (isForceLogout) {
      return InitStatus.FORCE_LOGOUT
    }

    // Generic unexpected error
    return InitStatus.ERROR
  }

  if (hasDeniedSlug(slug, hasAccessCodeBypass)) {
    return InitStatus.NO_COMPANY
  }

  return InitStatus.OK
}

function isUnknownCompany(configError: api.HTTPError) {
  return configError?.response?.status === 404
}

function hasAuthExpired(hasToken: boolean, userError: api.HTTPError) {
  return hasToken && userError?.response?.status === 401
}

/**
 * There are still some slug we want to disallow into the portal
 * We removed the flagr app-enablement check, and we do not respect "web app enabled" company config
 * This is here as a temporary stop-gap until we come up with a long-term proper mechanism
 * See: https://bushelpowered.slack.com/archives/G013T1DFXRA/p1662498159110169
 */
function hasDeniedSlug(slug: string, hasAccessCodeBypass: boolean) {
  return ENV.BUSHEL_ENVIRONMENT === 'prod' && isTempDeniedSlug(slug) && !hasAccessCodeBypass
}

/**
 * This fetches up to three things in parallel, which are needed to set up the app.
 *  - The logged-in user's auth/me call, which validates their access token and account(s)
 *  - The given slug's company config, which validates the slug and supplies app config data
 *  - A Flagr batch request for all globally-known flags the app may use
 *
 * This function should never throw, so we use tuple-style error handling which allows the errors
 * to be returned for examination.
 */
async function fetchInitData({ hasToken, slug }: { hasToken: boolean; slug: string }) {
  return Promise.all([
    hasToken
      ? // Don't try to load auth/me if there's no token
        api.centre
          .me()
          .then((data) => [data])
          .catch((err) => [null, err])
      : [null, null],

    api.centre
      .config()
      .then((data) => [data])
      .catch((err) => [null, err]),

    flagr
      .loadGlobalFlags({ companySlug: slug })
      .then((data) => [data])
      .catch((err) => [null, err]),
  ])
}

async function hasForceLogoutError(responses: any[]) {
  for (const response of responses) {
    if (await isForceLogoutResponse(response)) return true
  }

  return false
}
