import {
  CloseIcon,
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
  InputProps,
  InputRightElement,
  SearchIcon,
  Spinner,
} from 'src/components/designsystem'
import useStaffUserSearchPage from './useStaffUserSearchPage'

type StaffUserSearchInputProps = Partial<InputGroupProps> & { inputProps?: Partial<InputProps> }

export default function StaffUserSearchInput({ inputProps, ...props }: StaffUserSearchInputProps) {
  const { isSearching, searchQuery, onChangeSearchQuery, onClear } = useStaffUserSearchPage()

  return (
    <InputGroup size="lg" {...props}>
      <InputLeftElement>{isSearching ? <Spinner size="sm" /> : <SearchIcon />}</InputLeftElement>
      <Input
        autoFocus
        bgColor="white"
        value={searchQuery}
        placeholder="Search for a customer"
        data-testid="staff-user-search-input"
        _placeholder={{ color: 'gray.300' }}
        onChange={(e) => onChangeSearchQuery(e.currentTarget.value)}
        {...inputProps}
      />
      {!!searchQuery.length && (
        <InputRightElement p={2}>
          <CloseIcon onClick={onClear} cursor="pointer" />
        </InputRightElement>
      )}
    </InputGroup>
  )
}
