import {
  Box,
  Flex,
  CircleCheckIcon,
  MultiValueProgressBar,
  MultiValueProgressBarItem,
} from 'src/components/designsystem'
import * as formatter from 'src/utils/formatter'

function generateDataBites({ contract }: { contract: ContractDetail }) {
  const {
    isClosed,
    remaining,
    filledThreshold,
    hasRemaining,
    submitted,
    total,
    canceled,
    contracted,
    isFilled,
    hasOverage,
    overage,
  } = generateChartFacts(contract)

  let bites: MultiValueProgressBarItem[] = []

  if (isClosed || remaining <= filledThreshold || submitted > contracted) {
    const submittedPct = (hasRemaining ? submitted / total : 100) * 100
    const remainingPct = remaining > filledThreshold ? (remaining / total) * 100 : 0

    bites.push({ value: submittedPct, color: 'green', title: `Qty Delivered: ${submitted}` })

    if (remainingPct > 0) {
      bites.push({ value: remainingPct, color: 'lightgray', title: `Qty Remaining: ${remaining}` })
    }
  } else {
    const canceledPct = Math.round((canceled / contracted) * 100)
    const submittedPct = Math.round((submitted / contracted) * 100)
    const remainingPct = Math.round((remaining / contracted) * 100)

    if (canceledPct) {
      bites.push({ value: canceledPct, color: 'red', title: `Qty Canceled: ${canceled}` })
    }

    if (submittedPct) {
      bites.push({ value: submittedPct, color: 'yellow', title: `Qty Delivered: ${submitted}` })
    }

    if (remainingPct) {
      bites.push({ value: remainingPct, color: 'lightgray', title: `Qty Remaining: ${remaining}` })
    }

    if (sumBites(bites) !== 100) {
      bites = correctPercentages(bites)
    }
  }

  return { bites, isClosed, isFilled, hasRemaining, remaining, hasOverage, overage }
}

function generateChartFacts(contract: ContractDetail) {
  const contracted = contract.quantity_contracted_numeric || 0
  const canceled = contract.quantity_canceled_numeric || 0
  const remaining = contract.quantity_remaining_numeric || 0
  const submitted = contract.quantity_submitted_numeric || 0

  const total = contracted - canceled
  const overage = remaining < 0 ? Math.abs(remaining) : 0
  const isClosed = contract.status === 'Closed'
  const filledThreshold = total * 0.001

  const hasRemaining = remaining > filledThreshold
  const hasOverage = overage > 0 && overage > filledThreshold
  const isFilled = !hasRemaining && !hasOverage

  return {
    isClosed,
    remaining,
    submitted,
    filledThreshold,
    isFilled,
    hasRemaining,
    total,
    canceled,
    contracted,
    hasOverage,
    overage,
  }
}

function correctPercentages(bites: MultiValueProgressBarItem[]) {
  bites = bites.map((bite) => ({ ...bite, value: bite.value < 5 ? 5 : bite.value }))
  const totalSum = sumBites(bites)

  return bites.map((bite) => ({ ...bite, value: (bite.value / totalSum) * 100 }))
}

function sumBites(pieces: MultiValueProgressBarItem[]) {
  return pieces.reduce((acc, { value }) => acc + value, 0)
}

interface ContractProgressBarProps {
  contract: ContractDetail
}

export function ContractProgressBar({ contract }: ContractProgressBarProps) {
  if (!contract) return null

  const { bites, isClosed, isFilled, hasRemaining, remaining, hasOverage, overage } =
    generateDataBites({
      contract,
    })

  return (
    <Box>
      <Flex direction={['row', null, null, 'column']}>
        <MultiValueProgressBar bites={bites} />
      </Flex>
      <Flex
        justify={['right', null, null, 'center']}
        align="center"
        textStyle={['small-bold', null, null, 'small']}
        whiteSpace="nowrap"
        mt={[0, null, null, 1]}
      >
        {isClosed && (
          <CircleCheckIcon
            color="data.green"
            mt="-2px"
            mr={1}
            data-testid={`${contract.display_id} closed`}
          />
        )}

        {isFilled ? (
          <>Filled</>
        ) : (
          <>
            {hasRemaining && (
              <>
                {formatter.formatNumber(remaining)} {contract.quantity_uom} remain
              </>
            )}
            {hasOverage && (
              <>
                +{formatter.formatNumber(overage)} {contract.quantity_uom} over
              </>
            )}
          </>
        )}
      </Flex>
    </Box>
  )
}
