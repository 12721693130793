interface SizeProps {
  children: JSX.Element
  prefersReducedMotion: boolean
  size: number
}

const scaleValue = (base: number, size: number) => (size * base) / 225
const scaleValues = (size: number, scale: number) =>
  `scale(${scaleValue(scale, size)}, ${scaleValue(scale, size)})`

export const CheckAddIn = ({ size, prefersReducedMotion: prm }: Omit<SizeProps, 'children'>) => (
  <g
    id="CompletedCheckMark"
    transform={`translate(${scaleValue((225 - 67) / 2, size)}, ${scaleValue(
      (225 - 67) / 2,
      size
    )}) ${scaleValues(size, 1)}`}
  >
    <circle id="Circle" cx="33.5" cy="33.5" r="33.5" fill="#0FB867" opacity={!prm ? 0 : undefined}>
      {!prm && (
        <animate
          attributeType="CSS"
          attributeName="opacity"
          from="0"
          to="1"
          dur="0.5s"
          begin="0.5s"
          fill="freeze"
        />
      )}
    </circle>

    <path
      id="Line2"
      d="M26.5 45.5L50 22"
      stroke="white"
      strokeWidth="4.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray={prm ? '' : '34'}
      strokeDashoffset={prm ? '' : '34'}
    >
      {!prm && (
        <animate
          attributeType="CSS"
          attributeName="stroke-dashoffset"
          from="34"
          to="0"
          dur="0.1s"
          begin="1.2s"
          fill="freeze"
        />
      )}
    </path>
    <path
      id="Line1"
      d="M17 36L26.5 45.5"
      stroke="white"
      strokeWidth="4.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray={prm ? '' : '13.44'}
      strokeDashoffset={prm ? '' : '13.44'}
    >
      {!prm && (
        <animate
          attributeType="CSS"
          attributeName="stroke-dashoffset"
          from="13.44"
          to="0"
          dur="0.1s"
          begin="1s"
          fill="freeze"
        />
      )}
    </path>
  </g>
)
