import { Text, BoxProps, VStack, Img } from 'src/components/designsystem'
import { Heading } from '@chakra-ui/react'

export interface NotificationNonIdealStateProps extends BoxProps {
  illustration: '404' | 'error-coffee' | 'tumbleweed'
  illustrationAltText?: string
  illustrationWidth?: string | string[]
  size?: 'sm' | 'lg'
  header?: string | JSX.Element
  subHeader?: string | JSX.Element
  displayImage?: string | JSX.Element
  isVerticallyCentered?: boolean
}

export const NotificationNonIdealState: React.FC<NotificationNonIdealStateProps> = ({
  illustration,
  illustrationAltText = '',
  header,
  subHeader,
  isVerticallyCentered = false,
  ...props
}) => {
  return (
    <VStack
      alignItems="center"
      width="100%"
      mt={isVerticallyCentered ? ['15vh', '20vh'] : undefined}
      {...props}
    >
      <Img
        src={`/img/illustrations/${illustration}.svg`}
        width="18.4rem"
        alt={illustrationAltText}
        pb="3rem"
      />

      <Heading fontSize="1.5rem" textAlign="center" pb="1.5rem" mt="0 !important">
        {header}
      </Heading>

      <Text maxW="420px" textAlign="center" size="md" mt="0 !important">
        {subHeader}
      </Text>
    </VStack>
  )
}
