import { useCallback } from 'react'
import {
  useToast as useChakraToast,
  createStandaloneToast,
  UseToastOptions,
} from '@chakra-ui/react'
import { Theme } from 'src/themes'

const { ToastContainer, toast: standaloneToast } = createStandaloneToast({ theme: Theme })

export const useToast = () => {
  const toast = useChakraToast()

  const handleToast = ({
    title,
    description,
    status = 'success',
    position = 'bottom',
    duration = status ? 5000 : 10000,
    isClosable = true,
    ...rest
  }: UseToastOptions) => {
    toast({
      title,
      description,
      status,
      position,
      duration,
      isClosable,
      ...rest,
    })
  }
  return useCallback(handleToast, [toast])
}

export { ToastContainer, standaloneToast }
