import { Box, Popover, PopoverTrigger, PopoverContent, PopoverBody, Portal } from '@chakra-ui/react'
import Header from './Header'
import Months from './Months'
import Presets from './Presets'
import Footer from './Footer'

function DatePicker({ children }) {
  return <Box bg="white">{children}</Box>
}

function DatePickerPopover({ children, isOpen, contentRef, trigger }) {
  return (
    <>
      <Popover
        isLazy
        isOpen={isOpen}
        // initialFocusRef={triggerRef}
        placement="bottom-start"
        closeOnEsc={false}
        closeOnBlur={false}
        returnFocusOnClose={false}
      >
        <PopoverTrigger>{trigger}</PopoverTrigger>

        <Portal>
          <PopoverContent ref={contentRef}>
            <PopoverBody p={0}>{children}</PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </>
  )
}

DatePicker.Popover = DatePickerPopover
DatePicker.Header = Header
DatePicker.Months = Months
DatePicker.Presets = Presets
DatePicker.Footer = Footer

export default DatePicker
