import { useCallback } from 'react'
import { Button, Modal, ModalProps } from 'src/components/designsystem'
import { useDataExport } from 'src/data'
import { trackEvent } from 'src/utils/analytics'

export interface ExportModalProps {
  dataExport: ReturnType<typeof useDataExport>
}

export default function ExportModal({
  dataExport,
  title,
  body,
  ...rest
}: ExportModalProps & Partial<ModalProps> & { title: string }) {
  const { setShowModal, download } = dataExport
  const dataExportHandler = useCallback(() => setShowModal(false), [setShowModal])
  const onClickHandler = useCallback(async () => {
    await download()
    trackEvent(title, `Export/${title}`)
    setShowModal(false)
  }, [setShowModal, download, title])
  return (
    <Modal
      isCentered
      isOpen={dataExport.showModal}
      title={`Export ${title}`}
      body={body}
      onClose={dataExportHandler}
      data-testid={`${title}-export-option`}
      footer={
        <>
          <Button
            variant="secondary"
            mr={3}
            disabled={dataExport.isLoading}
            onClick={dataExportHandler}
            data-testid="cancel-export-button"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            isLoading={dataExport.isLoading}
            onClick={onClickHandler}
            data-testid="confirm-export-button"
          >
            Export
          </Button>
        </>
      }
      {...rest}
    />
  )
}
