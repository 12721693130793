import { CSSProperties, useCallback, useState } from 'react'
import { chakra, Box, BoxProps } from '@chakra-ui/react'
import { RangePreset } from 'src/components/designsystem/date-picker/hooks'
import { ChevronUpIcon, ChevronDownIcon } from 'src/components/designsystem'
import { Dayjs } from 'dayjs'

interface PresetsProps {
  headerTitle?: string
  collapsible?: boolean
  initialShowPresets?: boolean
  presets: RangePreset[]
  setAndFocusDates: (start: Dayjs, end: Dayjs) => void
}

export default function Presets({
  headerTitle = 'Presets',
  collapsible = false,
  initialShowPresets = true,
  presets,
  setAndFocusDates,
}: PresetsProps) {
  const [showPresets, setShowPresets] = useState(initialShowPresets)

  return (
    <>
      <PresetHeader {...{ headerTitle, collapsible, showPresets, setShowPresets }} />

      {showPresets && (
        <>
          {presets.map((preset, i) => {
            return (
              <chakra.button
                key={i}
                display="block"
                width="100%"
                border="none"
                background="transparent"
                padding="14px 0"
                textAlign="center"
                _hover={{ background: 'gray.50' }}
                onClick={() =>
                  setAndFocusDates(
                    ...(typeof preset.value === 'function' ? preset.value() : preset.value)
                  )
                }
              >
                {preset.label}
              </chakra.button>
            )
          })}
        </>
      )}
    </>
  )
}

const headerStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  minHeight: '2.5rem',
  border: 'none',
  color: '#A0AEC0',
  background: 'white',
  fontWeight: 700,
  fontSize: 12,
  textTransform: 'uppercase',
} as BoxProps

function PresetHeader({ headerTitle, collapsible, showPresets, setShowPresets }) {
  const toggleShowPresetsHandler = useCallback(
    () => setShowPresets(!showPresets),
    [setShowPresets, showPresets]
  )
  if (!collapsible) {
    return <Box {...headerStyles}>{headerTitle}</Box>
  }

  return (
    <chakra.button
      sx={{
        ...(headerStyles as CSSProperties),
        borderTop: '1px solid',
        borderBottom: '1px solid',
        borderColor: 'gray.200',
        borderBottomColor: showPresets ? 'gray.200' : 'transparent',
        background: 'gray.50',
      }}
      onClick={toggleShowPresetsHandler}
    >
      {headerTitle}
      <Box as="span" display="block" px="6px" fontSize="1.125rem" lineHeight={0}>
        {showPresets ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </Box>
    </chakra.button>
  )
}
