import { extendTheme } from '@chakra-ui/react'
import { Colors } from './colors'
import { ButtonStyleConfig } from 'src/components/designsystem/Button'
import { Montserrat, Roboto } from 'next/font/google'
import getClient from 'src/utils/clients/get-client'
import { CheckboxStyleConfig } from './checkbox'
import { SwitchStyleConfig } from './switch'
import { SpinnerStyleConfig } from './spinner'

const roboto = Roboto({
  weight: ['300', '400', '500', '700', '900'],
  display: 'swap',
  subsets: ['latin'],
})

const montserrat = Montserrat({
  weight: '700',
  display: 'swap',
  subsets: ['latin'],
})

const fonts = {
  body: roboto.style?.fontFamily ?? 'Roboto, sans-serif',
  heading: montserrat.style?.fontFamily ?? 'Montserrat, sans-serif',
  mono: 'Menlo, monospace',
}
const letterSpacings = {
  heading: '-0.02em',
  preTitle: '.08em',
}

const textHeadings = {
  h1: {
    fontSize: ['5xl', null, '6xl'],
    fontFamily: fonts.heading,
    fontWeight: 'bold',
    letterSpacing: letterSpacings.heading,
  },
  h2: {
    fontSize: ['3xl', null, '5xl'],
    fontFamily: fonts.heading,
    fontWeight: 'bold',
    letterSpacing: letterSpacings.heading,
  },
  h3: {
    fontSize: ['2xl', null, '3xl'],
    fontFamily: fonts.heading,
    fontWeight: 'bold',
    letterSpacing: letterSpacings.heading,
  },
  h4: {
    fontSize: ['xl', null, '2xl'],
    fontFamily: fonts.heading,
    fontWeight: 'bold',
    letterSpacing: letterSpacings.heading,
  },
  h5: {
    fontSize: ['lg', null, 'xl'],
    fontFamily: fonts.heading,
    fontWeight: 'bold',
    letterSpacing: letterSpacings.heading,
  },
  h6: {
    fontSize: ['md', null, 'lg'],
    fontFamily: fonts.heading,
    fontWeight: 'bold',
    letterSpacing: letterSpacings.heading,
  },
}

//  TODO: find a clever way to keep Design System and App Themes apart
const Theme = extendTheme({
  colors: Colors,
  fonts,
  letterSpacings,
  styles: {
    global: {
      body: {
        color: 'gray.700',
        userSelect: getClient().isNativeApp ? 'none' : '',
      },
      a: {
        WebkitTouchCallout: 'none',
      },
      ...Object.fromEntries(
        Object.entries(textHeadings).map(([elementName, styles]) => [
          elementName,
          {
            ...styles,
            fontSize: { base: styles.fontSize[0], lg: styles.fontSize[2] },
          },
        ])
      ),
    },
  },
  textStyles: {
    ...textHeadings,
    body: {
      fontFamily: fonts.body,
      fontSize: 'md',
      fontWeight: 'normal',
    },
    'body-bold': {
      fontFamily: fonts.body,
      fontSize: 'md',
      fontWeight: 'bold',
    },
    small: {
      fontFamily: fonts.body,
      fontSize: 'sm',
      fontWeight: 'normal',
    },
    'small-bold': {
      fontFamily: fonts.body,
      fontSize: 'sm',
      fontWeight: 'bold',
    },
    'extra-small': {
      fontFamily: fonts.body,
      fontSize: 'xs',
      fontWeight: 'normal',
    },
    'extra-small-bold': {
      fontFamily: fonts.body,
      fontSize: 'xs',
      fontWeight: 'bold',
    },
    subtitle: {
      fontFamily: fonts.body,
      fontSize: '2xl',
      fontWeight: 'bold',
    },
    preTitle: {
      fontFamily: fonts.body,
      fontSize: 'xs',
      fontWeight: 'bold',
      letterSpacing: letterSpacings.preTitle,
      textTransform: 'uppercase',
    },
    button: {
      fontFamily: fonts.body,
      fontSize: 'md',
      fontWeight: 'bold',
    },
    link: {
      fontFamily: fonts.body,
      color: 'blue.400',
      fontSize: 'md',
      fontWeight: 'normal',
    },
    'link-bold': {
      fontFamily: fonts.body,
      color: 'blue.400',
      fontSize: 'md',
      fontWeight: 'bold',
    },
  },
  layerStyles: {
    'card-small': {
      boxShadow: 'xs',
      borderRadius: 'lg',
      // hover shadow only on desktop
      _hover: { boxShadow: [null, null, null, 'sm'] },
    },
    'detail-card': {
      bg: 'gray.50',
      borderRadius: 'lg',
      px: [4, null, 6],
      py: 3,
      width: '100%',
    },
    'detail-table': {
      bg: ['unset', null, null, 'gray.50'],
      borderRadius: [0, null, 'lg', null],
      px: [0, null, 4, null],
      py: [0, null, null, 3],
      width: '100%',
    },
  },
  shadows: {
    xs: '0px 2px 3px rgba(45, 55, 72, 0.2), 0px 0px 2px rgba(45, 55, 72, 0.15)',
    sm: '0px 3px 4px rgba(45, 55, 72, 0.2), 0px 0px 3px rgba(45, 55, 72, 0.15)',
    md: '0px 6px 12px rgba(45, 55, 72, 0.22), 0px 2px 6px rgba(45, 55, 72, 0.15)',
    lg: '0px 10px 20px rgba(45, 55, 72, 0.24), 0px 2px 10px rgba(45, 55, 72, 0.17)',
    xl: '0px 16px 32px rgba(45, 55, 72, 0.26), 0px 8px 20px rgba(45, 55, 72, 0.2)',
    button: '0px 3px 4px rgba(74, 144, 226, 0.2), 0px 0px 2px rgba(74, 144, 226, 0.15)',
  },
  zIndices: {
    'detail-modal': 997,
    'above-detail-modal': 998,
  },
  components: {
    // @ts-ignore See: https://github.com/chakra-ui/chakra-ui/pull/3779
    Button: ButtonStyleConfig,

    Input: {
      variants: {
        outline: () => ({
          field: {
            _readOnly: {
              // Overrides readonly inputs to have same box shadow as editable inputs. This shows up if you are trying
              // to have an error state for a read only input. By default, Chakra hides the box shadow for readonly inputs.
              boxShadow: 'initial',
            },
          },
        }),
      },
    },

    // See: https://chakra-ui.com/docs/theming/component-style#styling-multipart-components
    Popover: {
      parts: ['content'],
      baseStyle: {
        content: {
          // Without this, Popovers have a "sm" width by default
          width: 'unset',
        },
      },
    },
    Modal: {
      baseStyle: {
        dialogContainer: {
          '@supports(height: -webkit-fill-available)': {},
        },
      },
    },
    Divider: {
      baseStyle: {
        borderColor: 'gray.300',
        opacity: '1',
      },
    },
    Checkbox: CheckboxStyleConfig,
    Switch: SwitchStyleConfig,
    Spinner: SpinnerStyleConfig,
    Drawer: {
      variants: {
        // Allow background scroll when trapFocus is false for drawers
        alwaysOpen: {
          parts: ['dialog, dialogContainer'],
          dialog: {
            pointerEvents: 'auto',
          },
          dialogContainer: {
            pointerEvents: 'none',
          },
        },
      },
    },
  },
})

export { Theme }
