import { Fragment } from 'react'
import { Box, Text, Grid, useTheme } from 'src/components/designsystem'

export type ThemeColor = 'gray' | 'blue' | 'yellow' | 'navy' | 'red' | 'orange' | 'green' | 'data'

//istanbul ignore
const ColorPalette: React.FC<{ themeColors?: ThemeColor[] }> = ({
  themeColors = ['gray', 'blue', 'yellow', 'navy', 'red', 'orange', 'green', 'data'],
  ...props
}) => {
  const theme = useTheme()
  const returnColorSwatch = ({ colorName, bgColor, bgColorId }) => {
    return (
      <Box key={`${colorName}.${bgColorId}`} p={3}>
        <Box mx="auto" w={16} h={16} bgColor={bgColor} />
        <Text textAlign="center" fontSize="0.75em">
          {colorName}.{bgColorId}
        </Text>
        <Text textAlign="center">{bgColor}</Text>
      </Box>
    )
  }

  const getSwatchForColor = (colors: any, colorName: string) =>
    colors[colorName] ? (
      Object.keys(colors[colorName])
        .map((colorKey) => [colorKey, colors[colorName][colorKey]])
        .map((c) => {
          if (typeof c[1] === 'string') {
            return returnColorSwatch({ colorName, bgColor: c[1], bgColorId: c[0] })
          } else {
            const colorKeyValues = c[1]
            return Object.keys(colorKeyValues).map((ck) =>
              returnColorSwatch({
                colorName,
                bgColor: colorKeyValues[ck],
                bgColorId: `${c[0]}.${ck}`,
              })
            )
          }
        })
        .reverse()
    ) : (
      <Fragment key="empty" />
    )

  return (
    <>
      <Grid templateColumns="repeat(10, 1fr)" gap={3} {...props}>
        {themeColors.filter((c) => c !== 'data').map((c) => getSwatchForColor(theme.colors, c))}
      </Grid>
      {themeColors.indexOf('data') >= 0 ? (
        <Grid
          mt={12}
          bgColor="gray.50"
          borderColor="gray.100"
          templateColumns="repeat(10, 1fr)"
          {...props}
        >
          {themeColors.filter((c) => c === 'data').map((c) => getSwatchForColor(theme.colors, c))}
        </Grid>
      ) : (
        <></>
      )}
    </>
  )
}

export default ColorPalette
