import ky, { AfterResponseHook } from 'ky'
import handleRefreshTokenIfExpired from 'src/api/utils/handleRefreshTokenIfExpired'
import { ENV } from 'src/utils/env'
import { getWindowNextDataProps, getWindowNextDataQuery } from 'src/utils/get-window-next-data'

const { token, slug } = getWindowNextDataProps()

const { userId: queryUserId } = getWindowNextDataQuery()

const defaultHeaders = {
  Authorization: token ? `Bearer ${token}` : undefined,
  'App-Company': (slug as string) ?? undefined,
}

const defaultAfterResponseHook: AfterResponseHook = async (_request, _options, response) => {
  if (!response.ok) response = await handleFailedRequest(response)
  return response
}

const defaultClientOptions = {
  retry: 0,
  timeout: 120000, // 2 mins
  headers: defaultHeaders,
  hooks: {
    beforeRequest: [handleRefreshTokenIfExpired],
    afterResponse: [defaultAfterResponseHook],
  },
}

export let paymentsPaymentsServiceClient = ky.create({
  ...defaultClientOptions,
  prefixUrl: ENV.PAYMENTS_PAYMENTS_API,
  headers: {
    ...defaultHeaders,
    'X-Impersonate-Id': queryUserId,
  },
})

export let paymentsUsersServiceClient = ky.create({
  ...defaultClientOptions,
  prefixUrl: ENV.PAYMENTS_USERS_API,
})

export let paymentsCompanyServiceClient = ky.create({
  ...defaultClientOptions,
  prefixUrl: ENV.PAYMENTS_COMPANY_API,
  hooks: {
    afterResponse: [
      // defaultAfterResponseHook,
      async (request, _options, response) => {
        // Need to first parse the request url to get the pathname due to the endsWith check breaking down when query params are present
        const isCompanyUserRoute = new URL(request.url).pathname.endsWith('v1/company/user')

        // This is technically a success response from the payments backend, so is needs to be hoisted above any control flows that handle errors.
        if (isCompanyUserRoute && response.status === 409) {
          // Response body can only be read once, so we need to clone it here
          response.payload = await response.clone().json()
          return response
        }
        return defaultAfterResponseHook(request, _options, response)
      },
    ],
  },
})

// UTILS
///////////////////////////////////////////////////////////////////////////////////////////////////

export const updatePaymentsClient = (config = {}) => {
  paymentsCompanyServiceClient = paymentsCompanyServiceClient.extend(config)
  paymentsPaymentsServiceClient = paymentsPaymentsServiceClient.extend(config)
  paymentsUsersServiceClient = paymentsUsersServiceClient.extend(config)
}

export const updatePaymentsToken = (token?: string) =>
  updatePaymentsClient({ headers: { Authorization: token ? `Bearer ${token}` : undefined } })

export const updatePaymentsServiceHeaders = ({
  slug,
  token,
  selectedUserId = undefined,
}: {
  slug: string
  token?: string
  selectedUserId?: string
}) =>
  updatePaymentsClient({
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      'App-Company': slug,
      'X-Impersonate-Id': selectedUserId ?? undefined,
    },
  })

export const clearPaymentsServiceHeaderImpersonation = () =>
  updatePaymentsClient({ headers: { 'X-Impersonate-Id': undefined } })

export const updatePaymentsServiceHeader = (key: string, value: string) =>
  updatePaymentsClient({ headers: { [key]: value } })

/**
 * Response passthrough handler for non-200 responses.
 * @param response - Response
 * @returns Response
 */
const handleFailedRequest = async (response: Response) => {
  try {
    // Response body can only be read once, so we clone it here
    const body = await response.clone().text()

    console.error(
      `src/api - api request failed [response.url: ${response?.url}][response.status: ${response?.status}][body: ${body}]`,
      {
        url: response.url,
        status: response.status,
        body,
      }
    )

    try {
      response.payload = JSON.parse(body)
    } catch {}
  } catch (err) {
    console.error('src/api - api request failed (exception)', {
      url: response.url,
      status: response.status,
    })
  }

  return response
}
