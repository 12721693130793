import { useQuery } from '@tanstack/react-query'
import * as api from 'src/api'
import {
  PoYGetExportConfigBySlugResponse,
  TicketApplicationsGetExportConfigBySlugResponse,
  ContractsGetExportConfigBySlugResponse,
} from 'src/api/reporting-service/config-reports'
import { useGlobalFlag, FLAG_KEYS } from 'src/utils/flagr'

export function useProofOfYieldExportConfig({ slug }: { slug: string }) {
  return useQuery<PoYGetExportConfigBySlugResponse, api.HTTPError>({
    queryKey: ['poy-get-export-config', { slug }],
    queryFn: async function () {
      return api.reportingService.poyGetExportConfigBySlug(slug)
    },
  })
}

export function useTicketApplicationsExportConfig({ slug }: { slug: string }) {
  const isTicketApplicationsExportEnabled = useGlobalFlag(
    FLAG_KEYS.WEBAPP_TICKET_APPLICATIONS_EXPORT
  ) as boolean

  return useQuery<TicketApplicationsGetExportConfigBySlugResponse, api.HTTPError>({
    queryKey: ['ticket-applications-export-config', { slug }],
    queryFn: async () => {
      return api.reportingService.ticketApplicationsExportConfigBySlug(slug)
    },
    enabled: isTicketApplicationsExportEnabled,
  })
}

export function useContractsExportConfig({ slug, enabled }: { slug: string; enabled: boolean }) {
  return useQuery<ContractsGetExportConfigBySlugResponse, api.HTTPError>({
    queryKey: ['contracts-export-config', { slug }],
    queryFn: async () => {
      return api.reportingService.contractsExportConfigBySlug(slug)
    },
    enabled,
  })
}
