import { useConfig } from 'src/data/config'

export function useIsAnyPaymentsFeatureEnabled() {
  const { isFeatureEnabled } = useConfig()
  const isPaymentsReceivableFeatureEnabled = isFeatureEnabled('payments_receivable')
  const isPaymentsPayableFeatureEnabled = isFeatureEnabled('payments_payable')
  const isPaymentsDirectDepositFeatureEnabled = isFeatureEnabled('payments_direct_deposit')

  return (
    isPaymentsReceivableFeatureEnabled ||
    isPaymentsPayableFeatureEnabled ||
    isPaymentsDirectDepositFeatureEnabled
  )
}
