import { useMemo } from 'react'
import { CollapsableDetailTable } from 'src/components/designsystem'
import DisplayConfig, { DisplayConfigItem } from 'src/components/designsystem/display-config'
import { DetailGrid, FormattedDate } from 'src/components/resource'

function usePriceAmendmentDisplayConfig({
  currencyLabel = '',
}): DisplayConfigItem<ContractPriceAmendment>[] {
  return useMemo(
    () => [
      {
        label: 'Date',
        key: 'date',
        ValueCellComponent: DisplayConfig.BoldTextValue,
        renderValue: ({ data }) => <FormattedDate date={data.date} localize />,
      },
      {
        label: 'Amount',
        key: 'amount',
        textAlign: 'right',
        ValueCellComponent: DisplayConfig.BoldTextValue,
      },
      {
        label: 'Futures Price',
        textAlign: 'right',
        ValueCellComponent: DisplayConfig.BoldTextValue,
        getValue: ({ data }) => data.fields.find((field) => field.name === 'Futures Price')?.value,
        renderValue: ({ data }) => {
          const value = data.fields.find((field) => field.name === 'Futures Price')?.value
          return `${value} ${value && currencyLabel}`
        },
      },
      {
        label: 'Basis',
        textAlign: 'right',
        ValueCellComponent: DisplayConfig.BoldTextValue,
        getValue: ({ data }) => data.fields.find((field) => field.name.startsWith('Basis'))?.value,
        renderValue: ({ data }) => {
          const value = data.fields.find((field) => field.name.startsWith('Basis'))?.value
          return `${value} ${value && currencyLabel}`
        },
      },
      {
        label: 'Cash Price',
        textAlign: 'right',
        ValueCellComponent: DisplayConfig.BoldTextValue,
        getValue: ({ data }) => data.fields.find((field) => field.name === 'Cash Price')?.value,
        renderValue: ({ data }) => {
          const value = data.fields.find((field) => field.name === 'Cash Price')?.value
          return `${value} ${value && currencyLabel}`
        },
      },
      {
        label: 'Futures Month',
        key: 'futures_month',
        ValueCellComponent: DisplayConfig.BoldTextValue,
      },
    ],
    [currencyLabel]
  )
}

export interface PriceAmendmentsProps {
  priceAmendments: ContractPriceAmendment[]
  currencyLabel?: string
  previewAmount?: number
}

export default function PriceAmendments({
  priceAmendments,
  currencyLabel,
  previewAmount,
}: PriceAmendmentsProps) {
  const displayConfig = usePriceAmendmentDisplayConfig({ currencyLabel })

  return (
    <>
      <DetailGrid.Title>Pricing Amendments</DetailGrid.Title>
      <CollapsableDetailTable
        rowItems={priceAmendments}
        rowItemTitle="Amendments"
        displayConfig={displayConfig}
        previewAmount={previewAmount}
        analyticsCategory="Contracts"
        analyticsAction="Price Amendments List"
      />
    </>
  )
}
