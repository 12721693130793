import { useCallback } from 'react'
import { InputGroup, Input, Text } from 'src/components/designsystem'
import { TextboxFilterOptionItem, BaseFilterProps } from 'src/utils/filters'
import { OverflowStack } from './Filters'

export type TextboxFilterProps = Omit<BaseFilterProps<TextboxFilterOptionItem>, 'values'> & {
  value: TextboxFilterOptionItem
}

export function TextboxFilter({ value, label, onChange }: TextboxFilterProps) {
  const handleChange = useCallback(
    (event) => onChange({ value: event.target.value ?? '' }),
    [onChange]
  )

  return (
    <OverflowStack direction="column" alignItems="flex-start" spacing={4} padding={4}>
      <Text textAlign="left" fontWeight="bold" textTransform="uppercase" fontSize="sm">
        {label}
      </Text>
      <InputGroup size="sm">
        <Input
          w="100%"
          placeholder={label}
          value={typeof value !== 'undefined' ? value?.value : ''}
          onChange={handleChange}
        />
      </InputGroup>
    </OverflowStack>
  )
}
