import { memo, ReactNode } from 'react'
import {
  Box,
  Circle,
  Flex,
  FlexProps,
  Grid,
  Skeleton,
  SkeletonCircle,
  Spacer,
  Square,
  Text,
  TextProps,
} from 'src/components/designsystem'
import { DisplayConfig } from 'src/components/designsystem/display-config'
import { labelToTestId } from 'src/utils/string/label-to-test-id'

type SkeleTextProps = TextProps & {
  isLoaded: boolean
  label?: string
  fontWeight?: string | number
}

export function SkeleText({ isLoaded, label, children, fontWeight, ...props }: SkeleTextProps) {
  return (
    <Skeleton as={Text} aria-label={label} fontWeight={fontWeight} isLoaded={isLoaded} {...props}>
      {children}
    </Skeleton>
  )
}

type LabelWithOptionalAria =
  | {
      label?: string | number
      ariaLabel?: string // Optional, since label is transformable into a string
    }
  | {
      label: React.ReactNode
      ariaLabel: string // For use when label is not transformable into a string ie. JSX
    }

type SkeleKeyValueProps = LabelWithOptionalAria &
  FlexProps & {
    isLoaded: boolean
    dotColor?: string
    fontWeight?: string | number
  }

export function SkeleKeyValue({
  isLoaded,
  label,
  ariaLabel,
  children,
  dotColor,
  fontWeight,
  ...props
}: SkeleKeyValueProps) {
  return (
    <Flex
      w="100%"
      justifyContent="space-between"
      alignItems="center"
      minH="40px"
      py={1}
      {...props}
      data-testid="data-grid-row"
    >
      <SkeleText
        isLoaded={isLoaded}
        pr={2}
        fontWeight={fontWeight}
        data-testid={`detail-data-key-${labelToTestId(label.toString())}`}
      >
        {label}
      </SkeleText>
      <Spacer />
      {dotColor && (
        <Square size={6}>
          <Circle size={2} bg={dotColor} data-testid="color-dot" />
        </Square>
      )}
      <SkeleText
        isLoaded={isLoaded}
        label={ariaLabel ?? (typeof label === 'string' ? label : '')}
        fontWeight={fontWeight ?? 'bold'}
        textAlign="right"
        data-testid={`detail-datapoint-${labelToTestId(label.toString())}`}
      >
        {children}
      </SkeleText>
    </Flex>
  )
}

interface ResourceListItemSkeletonProps<Item> {
  actionMenuConfig: {
    key: string
    render?: (item: Item) => ReactNode
    setMobileActionMenuItem?: (item: any) => void
    onMobileActionMenuClose?: () => void
  }[]
  columnConfig: DisplayConfig<Item>
}

export const ResourceListItemSkeleton = memo(function ResourceListItemSkeleton<Item>({
  actionMenuConfig,
  columnConfig,
}: ResourceListItemSkeletonProps<Item>) {
  return (
    <Box layerStyle="card-small" w="100%" bg="white">
      <Flex w="100%" minH="72px" px={[4, null, 6]} py={[6, null, 4]} align="center">
        <Flex display={['none', null, null, 'flex']} mr={6}>
          <SkeletonCircle size="6" />
        </Flex>

        <Grid
          w="100%"
          templateColumns={[
            '1fr',
            null,
            '1fr 1fr',
            columnConfig.desktopTemplateColumns(columnConfig.items),
          ]}
          alignItems="center"
          rowGap={2}
          columnGap={[12, null, null, 2]}
        >
          {columnConfig.items.map((column, index) => (
            <SkeleText
              key={column.label || column.key || index}
              isLoaded={false}
              label={column.label}
            >
              ph
            </SkeleText>
          ))}
          {actionMenuConfig?.length > 0 && (
            <SkeleText key="actionmenu-skeleton" isLoaded={false} label="Action Menu">
              ph
            </SkeleText>
          )}
        </Grid>
      </Flex>
    </Box>
  )
})
