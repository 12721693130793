import { lazy } from 'react'
import 'react18-json-view/src/style.css'

const LazyReactJson = lazy(() => import('react18-json-view'))

const ExportLazyReactJson = (props) => (
  <LazyReactJson
    {...props}
    displaySize
    enableClipboard={(e: { src: any }) => navigator.clipboard.writeText(JSON.stringify(e.src))}
  />
)

export default ExportLazyReactJson

// Alternate version below:

// import dynamic from 'next/dynamic'
// import importCwd from 'import-cwd'

// // * This `import-cwd` workaround was found here:
// // * https://github.com/cucumber/cucumber-js/blob/93fbb958432583ca5fab74a73041b05f3b2e8832/docs/faq.md#why-am-i-seeing-the-from-argument-must-be-of-type-string-received-type-undefined
// // * While this works within NEXT.js, and within CI based tests, local tests don't want to pass for some reason.
// // * As I was unable to diagnose what was really happening, this seems to work for now.
// const DynamicReactJson = dynamic(importCwd('react-json-view'), { ssr: false })

// // * This wrapper is necessary to have full type inheritance,
// // * otherwise types don't line up when this is called from another component.
// const ExportDynamicReactJson = (props) => <DynamicReactJson {...props} />

// export default ExportDynamicReactJson
