import { StorageKey } from 'src/utils/storage/storage-key'
import { Nullable } from 'src/utils/types/nullable'
import { Optional } from 'src/utils/types/optional'

export const insecureBrowserStorage = {
  get,
  set,
}

function get<T>(key: StorageKey): T | undefined {
  const stringRepresentation = localStorage.getItem(key)

  if (stringRepresentation) {
    return parse(stringRepresentation)
  } else {
    return undefined
  }
}

function parse<T>(stringRepresentation: string): Optional<T> {
  const data = JSON.parse(stringRepresentation).data as Nullable<T>

  return data ?? undefined
}

function set<T>(key: StorageKey, value?: T) {
  if (value !== undefined) {
    saveToLocalStorage(key, value)
  } else {
    localStorage.removeItem(key)
  }
}

function saveToLocalStorage<T>(key: StorageKey, value: T) {
  const stringRepresentation = JSON.stringify({ data: value })
  localStorage.setItem(key, stringRepresentation)
}
