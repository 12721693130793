import { useRouter } from 'next/router'
import { useEffect } from 'react'
import type { SortDirection } from 'src/components/designsystem/display-config'
import create from 'zustand'

export type SortDirectionKeyStore = {
  key: string
  direction: SortDirection
  update: (key: string, direction: SortDirection) => void
  clear: () => void
  handleOnClick: (key: string) => void
}

/**
 * Toggles the sort direction between 'asc', 'desc', and null - in that order
 * @param direction - The current sort direction
 * @returns The new sort direction
 */
const getNextSortDirection = (direction: SortDirection): SortDirection => {
  switch (direction) {
    case 'ASC':
      return 'DESC'
    case 'DESC':
      return null
    default:
      return 'ASC'
  }
}

/**
 * A store for managing sort direction for a singular key in a resource's rendered columns.
 * @returns Zustand store for managing sort direction (can be used with as a hook)
 */
const useColumnSortDirectionKeyStore = create<SortDirectionKeyStore>((set) => ({
  key: undefined,
  direction: null,
  update: (key, direction) => set({ key, direction }),
  clear: () => set({ key: undefined, direction: null }),
  handleOnClick: (key) =>
    set(({ key: keyState, direction }) => {
      const isNewSort = key !== undefined && key !== keyState
      return { key, direction: isNewSort ? 'ASC' : getNextSortDirection(direction) }
    }),
}))

export default function useColumnSortDirection() {
  const { events } = useRouter()
  const store = useColumnSortDirectionKeyStore()

  // Clear the sort direction when we navigate to a new page
  useEffect(() => {
    const handleNonShallowRouteChange = (_url, { shallow }) => !shallow && store.clear()
    events.on('routeChangeStart', handleNonShallowRouteChange)
    return () => {
      events.off('routeChangeStart', handleNonShallowRouteChange)
    }
  }, [events, store])

  return store
}
