import { Optional } from 'src/utils/types/optional'
import { pushNotificationsPluginWrapper } from 'src/utils/clients/native/wrappers/push-notifications-plugin-wrapper'
import { PluginListenerHandle } from '@capacitor/core'
import { useRightSidebarStore } from 'src/components/layout/right-sidebar/right-sidebar-store'
import Router from 'next/router'
import { getSlug } from 'src/utils/get-slug'
import { PushNotificationSchema } from '@capacitor/push-notifications/dist/esm/definitions'
import { ActionPerformed } from '@capacitor/push-notifications'
import { inAppBrowser } from 'src/utils/clients/native/in-app-browser'

let listener: Optional<PluginListenerHandle> = undefined

export async function startRespondingToPushNotificationActions() {
  await removeOldListenerIfNeeded()
  await addNewListener()
}

async function removeOldListenerIfNeeded() {
  await listener?.remove()
}

async function addNewListener() {
  const { openRightSidebar } = useRightSidebarStore.getState()

  listener = await pushNotificationsPluginWrapper.addListener(
    'pushNotificationActionPerformed',
    (action: ActionPerformed) => {
      if (isEsignPushNotification(action.notification)) {
        Router.push(`/${getSlug()}/esign`)
      } else if (isExternalLinkPushNotification(action.notification)) {
        const externalLinkUrl = getExternalLinkUrl(action)
        inAppBrowser.open(externalLinkUrl)
      } else {
        openRightSidebar('notifications')
      }
    }
  )
}

export function isEsignPushNotification(notification: PushNotificationSchema) {
  return isAndroidEsignPushNotification(notification) || isIosEsignPushNotification(notification)
}

function isAndroidEsignPushNotification(notification: PushNotificationSchema): boolean {
  return notification.data?.navigateToFeatureV2 === 'esign'
}

function isIosEsignPushNotification(notification: PushNotificationSchema): boolean {
  return notification.data?.data?.navigateToFeatureV2 === 'esign'
}

function isExternalLinkPushNotification(notification: PushNotificationSchema) {
  return (
    isAndroidExternalLinkPushNotification(notification) ||
    isIosExternalLinkPushNotification(notification)
  )
}

function isAndroidExternalLinkPushNotification(notification: PushNotificationSchema): boolean {
  return notification.data?.navigateToUrl !== undefined
}

function isIosExternalLinkPushNotification(notification: PushNotificationSchema) {
  return notification.data?.data?.navigateToUrl !== undefined
}

function getExternalLinkUrl(action: ActionPerformed): string {
  if (isAndroidExternalLinkPushNotification(action.notification)) {
    return action.notification.data.navigateToUrl
  } else {
    return action.notification.data.data.navigateToUrl
  }
}
