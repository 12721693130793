import Link from 'next/link'
import { BackArrowIcon, Box, Button, Fade, Text, useBreakpoint } from 'src/components/designsystem'
import { useMerchandiser } from 'src/data/merchandiser'
import { useRouter } from 'next/router'
import { FLAG_KEYS, useGlobalFlag } from 'src/utils/flagr'
import { formatPhoneNumber } from 'src/utils'
import { useBackToCrmUrlStore } from 'src/components/admin/crm/customer-overview/CustomerViewButton'
import { useMemo } from 'react'

export const FIXED_BANNER_HEIGHT = 42

export function MerchandiserBanner() {
  const { selectedUser, hasSelectedUser, userId, openSearch } = useMerchandiser()
  const { query } = useRouter()
  const { isDesktop } = useBreakpoint()
  const isStaffFlagEnabled = useGlobalFlag(FLAG_KEYS.WEBAPP_STAFF_ROUTES)
  const { backToUrl, clear: handleUrlClear } = useBackToCrmUrlStore()
  const contactInfo = useMemo(() => {
    const userPhone = selectedUser?.phone
    const userEmail = selectedUser?.idp_id ?? undefined
    const userFirstName = selectedUser?.accounts[0]?.first_name
    const userLastName = selectedUser?.accounts[0]?.last_name
    const userFullName = [userFirstName, userLastName].filter(Boolean).join(' ')
    const userIdentity = userFullName.length > 0 ? userFullName : userEmail

    return [userIdentity, formatPhoneNumber(userPhone)].filter(Boolean).join(', ')
  }, [selectedUser?.accounts, selectedUser?.idp_id, selectedUser?.phone])

  if (isStaffFlagEnabled || userId) {
    return (
      <Box
        h={`${FIXED_BANNER_HEIGHT}px`}
        p={2}
        textAlign="center"
        textColor="white"
        bg="blue.400"
        zIndex="above-detail-modal"
        position="relative"
      >
        {isStaffFlagEnabled && (
          <Box position="absolute" left={isDesktop ? 2 : -1} top={1}>
            <Button
              as={Link}
              href={backToUrl ?? `/${query.company}/staff`}
              leftIcon={<BackArrowIcon fontSize="2xl" color="white" />}
              aria-label="Close Customer View"
              h={8}
              pl={isDesktop ? 2 : 3}
              pr={2}
              zIndex={2}
              onClick={handleUrlClear}
            >
              {isDesktop && <>Leave Customer View</>}
            </Button>
          </Box>
        )}

        {isStaffFlagEnabled && !userId && <>Customer View</>}

        <Fade in={hasSelectedUser}>
          <Text cursor="pointer" noOfLines={1} onClick={openSearch}>
            {isDesktop && 'Viewing Customer:'} <strong>{contactInfo}</strong>
          </Text>
        </Fade>
      </Box>
    )
  }

  return null
}
