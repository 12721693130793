import { useController } from 'react-hook-form'
import { NonScrollableNumberInput } from 'src/components/designsystem'

function createPrecisionRegex(precision: number) {
  const precisionRegex = precision > 0 ? `\\.?\\d{0,${precision}}` : ''
  return new RegExp(`^-?\\d*${precisionRegex}$`)
}

export default function FixedPrecisionInput({
  fieldName,
  precision = 2,
  placeholder = '',
}: Readonly<{ fieldName: string; precision?: number; placeholder?: string }>) {
  const {
    field: { onChange, value, ...field },
  } = useController({
    name: fieldName,
  })
  const step = precision > 0 ? `.${Array.from({ length: precision }).join('0')}1` : '1' // Precision 2 would be a step of 0.01
  return (
    <NonScrollableNumberInput
      step={step}
      placeholder={placeholder}
      value={value ?? ''}
      {...field}
      onChange={(e) => {
        const allowedRegex = createPrecisionRegex(precision)
        const nextValue = e.currentTarget.value
        // Only call onChange if the value is allowed
        if (allowedRegex.test(nextValue)) {
          onChange(nextValue)
        }
      }}
    />
  )
}
