import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { datadogRum } from '@datadog/browser-rum-slim'
import { ENV } from 'src/utils/env'
import { getWindowNextDataProps } from 'src/utils/get-window-next-data'
import logger from 'src/utils/logger'

export const notAllowedBushelOpsSubdomainsRegex =
  /https:\/\/(?!(id)|(configcat)\.)[a-z0-9-]+(\.[a-z0-9-]+)*\.bushelops\.com/

/**
 * Initializes Datadog RUM monitoring
 * !Client-side only
 * !Use `DatadogRumMonitor` instead of directly calling this function
 * @returns
 */
export async function initDatadogRUM() {
  if (!ENV.APP_VERSION) {
    logger.error({ message: '[datadog rum] monitoring is disabled (app version unknown)' })
    return
  }
  if (!ENV.BUSHEL_ENVIRONMENT) {
    logger.error({ message: '[datadog rum] monitoring is disabled (environment unknown)' })
    return
  }
  if (!ENV.DATADOG_RUM_APP_ID) {
    logger.error({ message: '[datadog rum] monitoring is disabled (app id unknown)' })
    return
  }
  if (!ENV.DATADOG_RUM_CLIENT_TOKEN) {
    logger.error({ message: '[datadog rum] monitoring is disabled (client token unknown)' })
    return
  }
  if (!ENV.DATADOG_RUM_SERVICE) {
    logger.error({ message: '[datadog rum] monitoring is disabled (service id unknown)' })
    return
  }

  datadogRum.init({
    applicationId: ENV.DATADOG_RUM_APP_ID,
    clientToken: ENV.DATADOG_RUM_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: ENV.DATADOG_RUM_SERVICE,
    env: ENV.BUSHEL_ENVIRONMENT,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: ENV.APP_VERSION,
    sessionSampleRate: 100,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    silentMultipleInit: true,
    startSessionReplayRecordingManually: true,
    allowFallbackToLocalStorage: true,
    /**
     * Trace on all the 3 major API domains
     * Due to restrictive CORS headers, we cannot trace to Keycloak, or ConfigCat
     */
    allowedTracingUrls: [
      // Allow tracing for bushelops.com, except for subdomains starting with 'id' or 'configcat'
      notAllowedBushelOpsSubdomainsRegex,

      // xyz.bushelpowered.com, except 'id.xyz' subdomains
      /https:\/\/(?!id\.)[a-z0-9-]+\.bushelpowered\.com/,

      // xyz.scaleticket.net, except 'id.xyz' subdomains
      /https:\/\/(?!id\.)[a-z0-9-]+\.scaleticket\.net/,
    ],
  })

  setDatadogUser(getWindowNextDataProps())
  await appendAppVersionIfNative()
}

export function setDatadogUser({ slug, user }: { slug?: string; user?: Me } = {}) {
  if (user) {
    const id = user.id?.toString()
    const name = `${user.first_name ?? ''} ${user.last_name ?? ''}`.trim()
    const phone = user.phone_numbers?.data?.find((phoneObj) => phoneObj.primary)?.phone
    const allPhones = user.phone_numbers?.data
      ?.map((phoneObj) => `${phoneObj.id}: ${phoneObj.phone}`)
      .join(', ')
    const email = user.email_addresses?.data?.find((emailObj) => emailObj.primary)?.email || null

    datadogRum.setUser({
      ...(id && { id }),
      ...(name && { name }),
      ...(phone && { phone }),
      ...(allPhones && { allPhones }),
      ...(email && { email }),
      ...(slug && { slug }),
    })
  }
}

export function clearDatadogUser() {
  datadogRum.clearUser()
}

async function appendAppVersionIfNative() {
  if (!Capacitor.isNativePlatform()) return
  const nativeAppVersion = (await App.getInfo()).version

  if (!nativeAppVersion) return
  datadogRum.setGlobalContextProperty('native_app_version', nativeAppVersion)
}
