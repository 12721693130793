import { useRouter } from 'next/router'
import { useEffect, useMemo } from 'react'
import type { SortDirection } from 'src/components/designsystem/display-config'
import useColumnSortDirection from 'src/components/resource/hooks/useColumnSortDirection'
import { base64DecodeString, base64EncodeString } from 'src/utils/string/base64'

export default function useSyncRouterWithSort() {
  useUpdateQueryParamsFromSort()
  useUpdateQueryParamsOnSortClear()
  useUpdateSortStoreFromQueryParams()
}

/**
 * Updates the sort store when the query params change
 */
function useUpdateSortStoreFromQueryParams() {
  const { update } = useColumnSortDirection()
  const [pathname, queryParams] = useParsedQueryParamsAndPath()

  useEffect(() => {
    if (!queryParams.has('sort')) return

    const sort = queryParams.get('sort')
    const [field, direction] = decodeFieldAndDirection(sort)
    update(field, direction)
  }, [pathname, queryParams, update])
}

/**
 * Updates the query params when the sort changes
 */
function useUpdateQueryParamsFromSort() {
  const { replace } = useRouter()
  const { key: field, direction } = useColumnSortDirection()
  const [pathname, queryParams] = useParsedQueryParamsAndPath()

  useEffect(() => {
    if (!field || !direction) return

    const currentSort = queryParams.get('sort')
    const newSort = encodeFieldAndDirection(field, direction)

    if (currentSort !== newSort) {
      queryParams.set('sort', newSort)
      replace({ pathname, query: queryParams.toString() }, undefined, { shallow: true })
    }
  }, [direction, field, pathname, queryParams, replace])
}

/**
 * Clears the sort query param when the sort is cleared in the UI
 */
function useUpdateQueryParamsOnSortClear() {
  const { replace } = useRouter()
  const { direction } = useColumnSortDirection()
  const [pathname, queryParams] = useParsedQueryParamsAndPath()

  useEffect(() => {
    if (direction) return

    if (!direction && queryParams.has('sort')) {
      queryParams.delete('sort')
      replace({ pathname, query: queryParams.toString() }, undefined, { shallow: true })
    }
  }, [direction, pathname, queryParams, replace])
}

function useParsedQueryParamsAndPath() {
  const { asPath } = useRouter()
  const [pathname, unparsedQueryParams] = asPath.split('?')

  const queryParams = useMemo(() => new URLSearchParams(unparsedQueryParams), [unparsedQueryParams])

  return [pathname, queryParams] as const
}

function encodeFieldAndDirection(field: string, direction: SortDirection) {
  return base64EncodeString(`${field}:${direction}`)
}

function decodeFieldAndDirection(sort: string) {
  return base64DecodeString(sort).split(':') as [string, SortDirection]
}
