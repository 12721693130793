export function generateContractDeliveredFacts(contract: ContractDetailV3) {
  const contracted = contract.quantity_contracted_numeric || 0
  const canceled = contract.quantity_canceled_numeric || 0
  const remaining = contract.quantity_remaining_numeric || 0
  const delivered = contract.quantity_delivered_numeric || 0
  const isClosed =
    contract.quantity_contracted_numeric <=
    contract.quantity_delivered_numeric + contract.quantity_canceled_numeric

  return {
    isClosed,
    remaining,
    delivered,
    canceled,
    contracted,
  }
}

export function generateContractPricedFacts(contract: ContractDetailV3) {
  const priced = contract.quantity_priced_numeric ?? 0
  const canceled = contract.quantity_canceled_numeric ?? 0
  const unpriced = contract.quantity_unpriced_numeric ?? 0

  const total = priced + canceled + unpriced
  const isClosed = priced + canceled >= (contract.quantity_contracted_numeric ?? 0)

  return {
    isClosed,
    unpriced,
    canceled,
    priced,
    total,
  }
}
