import { generateRandomCharacter } from './generate-random-character'

export function generateRandomString(length: number, characterSet: string): string {
  let result = ''

  for (let i = 0; i < length; i++) {
    result += generateRandomCharacter(characterSet)
  }

  return result
}
