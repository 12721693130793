import {
  BushelWalletLogo,
  forwardRef,
  IconButton,
  IconButtonProps,
  WalletIcon,
} from 'src/components/designsystem'
import useGoToBushelWallet from 'src/utils/use-go-to-bushel-wallet'
import { useAuth } from 'src/auth'
import { useRouter } from 'next/router'
import { useConfig } from 'src/data/config'
import { useSessionUserWalletInfo } from 'src/data/walletInfoHooks'
import { NavigateToExternalLink } from 'src/components/utils/NavigateToExternalLink'
import getClient from 'src/utils/clients/get-client'

type BushelWalletButtonProps = Readonly<
  Partial<IconButtonProps> & {
    isExpanded?: boolean
    showBorder?: boolean
  }
>

/**
 * ForwardRef is needed for the IconButton to work with the Tooltip
 * @returns Bushel Wallet Button
 */
const BushelWalletButton = forwardRef<BushelWalletButtonProps, typeof IconButton>((props, ref) => {
  const { slug, isStaff } = useAuth()
  const { isFeatureEnabled } = useConfig()
  const router = useRouter()

  const {
    isLoading: isGoToBushelWalletLoading,
    goToBushelWallet,
    walletUrl,
  } = useGoToBushelWallet({
    isDisabled: props.isDisabled,
  })

  const { isOnboarded: userHasWallet, isLoading: isSessionUserWalletInfoLoading } =
    useSessionUserWalletInfo()

  const isLoading = isGoToBushelWalletLoading || isSessionUserWalletInfoLoading
  const isPaymentsPayableFeatureEnabled = isFeatureEnabled('payments_payable')
  const isPaymentsDirectDepositFeatureEnabled = isFeatureEnabled('payments_direct_deposit')

  const handleClick = () => {
    if (isStaff || userHasWallet) return goToBushelWallet()

    if (isPaymentsPayableFeatureEnabled || isPaymentsDirectDepositFeatureEnabled) {
      return router.push(['', slug, 'settings', 'direct-deposit'].join('/'))
    }

    return router.push(['', slug, 'payments'].join('/'))
  }

  const styleProps: IconButtonProps = {
    minW: 'unset',
    w: props.isExpanded ? '13rem' : 12,
    variant: 'outline',
    borderColor: props.showBorder ? 'gray.300' : 'transparent',
    'aria-label': 'Bushel Wallet Link',
    _hover: { bg: 'gray.100' },
    _focus: { bg: 'gray.300' },
    _active: { bg: 'gray.300' },
    _disabled: {
      _hover: { bg: 'gray.50' },
      cursor: 'not-allowed',
    },
    icon: props.isExpanded ? (
      <BushelWalletLogo w="172px" h="24px" />
    ) : (
      <WalletIcon w="32px" h="24px" />
    ),
  }

  if (isStaff || userHasWallet) {
    if (getClient().isNativeApp) {
      return (
        <IconButton
          ref={ref}
          isLoading={isLoading}
          onClick={handleClick}
          {...styleProps}
          {...props}
        />
      )
    } else {
      return (
        <NavigateToExternalLink href={walletUrl}>
          <IconButton ref={ref} {...styleProps} {...props} />
        </NavigateToExternalLink>
      )
    }
  }

  return (
    <IconButton ref={ref} isLoading={isLoading} onClick={handleClick} {...styleProps} {...props} />
  )
})

export default BushelWalletButton
