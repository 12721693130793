import React, { forwardRef } from 'react'
import { ChakraLink, ChakraLinkProps } from 'src/components/designsystem'
import getClient from 'src/utils/clients/get-client'
import { inAppBrowser } from 'src/utils/clients/native/in-app-browser'

export const NavigateToExternalLink = forwardRef<HTMLAnchorElement, ChakraLinkProps>(
  ({ children, ...rest }, ref) => {
    return (
      <>
        {getClient().isAndroid ? (
          <AndroidExternalLink ref={ref} {...rest}>
            {children}
          </AndroidExternalLink>
        ) : (
          <NavigateToUrlLink ref={ref} {...rest}>
            {children}
          </NavigateToUrlLink>
        )}
      </>
    )
  }
)

const NavigateToUrlLink = forwardRef<HTMLAnchorElement, ChakraLinkProps>(
  ({ children, ...rest }, ref) => {
    return (
      <ChakraLink isExternal ref={ref} {...rest}>
        {children}
      </ChakraLink>
    )
  }
)

const AndroidExternalLink = forwardRef<HTMLAnchorElement, ChakraLinkProps>(
  ({ href, children, ...rest }, ref) => {
    const openExternalLink = async () => {
      await inAppBrowser.open(href)
    }

    return (
      <ChakraLink {...rest} onClick={openExternalLink} ref={ref}>
        {children}
      </ChakraLink>
    )
  }
)
