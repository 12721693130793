import { useState, useEffect } from 'react'
import { DataColors } from 'src/themes/colors'
import { VictoryPie } from 'victory'
import { usePrefersReducedMotion } from '..'
import { CheckAddIn } from './CheckAddIn'

/** All data needed to display a Donut Chart */
export interface DonutChartData {
  /** name of the chart */
  name?: string
  /** flag to show center checkmark icon */
  showInnerCheck?: boolean
  /** chart bites, displayed clockwise from 12 */
  bites: DonutBite[]
}

/** A Bite of the DonutChart */
export interface DonutBite {
  /** optional id */
  id?: string
  /** optional name */
  name?: string
  /** optional title, shown on hover */
  title?: string
  /** value of the bite, 0-100 */
  value: number
  /** color of bite */
  color: keyof typeof DataColors
}

/**
 * Set an initial delay for the donut chart rotation animation.
 * @param ms - timeout in milliseconds
 */
const useAngle = (ms = 1) => {
  const [angle, setAngle] = useState(0)
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setAngle(360)
    }, ms)
    return () => clearTimeout(timeoutId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { angle, setAngle }
}

export interface DonutChartProps {
  data: DonutChartData
  /**
   * Override the system setting for reduceMotion, but only when the system setting is false.
   * If the system setting is true, this property is ignored.
   */
  reduceMotion?: boolean
  /**
   * Both Width and Height of the rendered SVG component.
   */
  size: number
}

export function DonutChart({ data, reduceMotion = false, size }: DonutChartProps) {
  const systemPrefersReducedMotion = usePrefersReducedMotion()
  const prefersReducedMotion = reduceMotion || systemPrefersReducedMotion
  const { angle } = useAngle()

  return (
    <svg
      role="img"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
    >
      {data.showInnerCheck && <CheckAddIn {...{ animation: 'draw', size, prefersReducedMotion }} />}
      <VictoryPie
        standalone={false}
        width={size}
        height={size}
        labels={[]}
        padding={0}
        innerRadius={(55 * size) / 225}
        data={data.bites.map((bite) => ({ x: bite.name, y: bite.value }))}
        colorScale={data.bites.map((bite) => DataColors[bite.color])}
        animate={prefersReducedMotion ? null : { duration: 2000, easing: 'quadInOut' }}
        endAngle={angle}
        padAngle={1}
      />
    </svg>
  )
}
