import { useAuth } from 'src/auth'
import { GenericNonIdealState } from 'src/components/designsystem'
import { ENV } from 'src/utils/env'

export default function BlockStaffAccess({
  children,
  message,
}: Readonly<{ children: React.ReactNode; message: string }>) {
  const { isStaff } = useAuth()

  return isStaff || ENV.MOCK_MERCH_MODE ? (
    <GenericNonIdealState
      isVerticallyCentered
      illustration="tumbleweed"
      header="Sorry, this page is disabled."
      subHeader={message}
    />
  ) : (
    children
  )
}
