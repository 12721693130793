import { useEffect, useState } from 'react'
import { useUserSearch } from 'src/data/queries'
import { useMerchandiser } from 'src/data/merchandiser'
import UserSearchModal from './UserSearchModal'
import { useDebounce } from 'use-debounce'
import { isForceLogoutResponse } from 'src/auth/keycloak'
import { HTTPError } from 'src/api'

export function UserSearchModalWrapper() {
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebounce(search, 500)
  const {
    selectedUser,
    hasSelectedUser,
    closeSearch,
    handleSelectUser,
    handleClearUser,
    isSearching,
  } = useMerchandiser()

  const searchQuery = useUserSearch({ query: debouncedSearch, enabled: debouncedSearch.length > 0 })

  useHandleSearchError({ isOpen: isSearching, error: searchQuery.error, closeSearch })

  return (
    <UserSearchModal
      isOpen={isSearching}
      onClose={closeSearch}
      searchUsers={searchQuery.data?.data}
      isLoading={searchQuery.isFetching}
      isError={searchQuery.isError}
      isSuccess={searchQuery.isSuccess}
      {...{ selectedUser, search, setSearch, hasSelectedUser, handleSelectUser, handleClearUser }}
    />
  )
}

/**
 * If the search request encounters a 401 or 403 force-logout response we need
 * to ensure the modal gets closed. This handles those considerations.
 */
function useHandleSearchError({
  isOpen,
  error,
  closeSearch,
}: {
  isOpen: boolean
  error: HTTPError
  closeSearch: () => void
}) {
  useEffect(() => {
    // Bail if modal isn't open, or no error
    if (!isOpen || !error) return

    // Both of these responses will move the user to a logged-out state, close the modal
    async function checkError() {
      if (error.response.status === 401) {
        closeSearch()
      } else if (await isForceLogoutResponse(error.response)) {
        closeSearch()
      }
    }

    checkError()
  }, [isOpen, error, closeSearch])
}
