import { centreClient } from './client'

export interface CompaniesResponse {
  data: {
    primary: {
      id: number
      name: string
      phone: string
      website: string
      elevators: {
        data: {
          id: number
          name: string
          preferred: boolean
          cqg_name: string
          product_map: {
            name: string
            cash: string
            flat: string
            basis: string
          }[]
        }[]
      }
    }[]

    symbols: {
      display: string
      display_name: string
      crop: string
      contract_symbol: string
      namespaced_symbols: string[]
    }[]

    other: {
      id: string
      name: string
    }[]
  }
}

export const companiesRequests = {
  companies: async () => {
    const response = await centreClient.get('api/v1/companies').json<CompaniesResponse>()

    return response.data
  },
}
