import { CircleCheckIcon, Flex } from 'src/components/designsystem'
import ProgressBar from 'src/components/designsystem/charts/ProgressBar'
import * as formatter from 'src/utils/formatter'

export function SmallProgressBar({ contract }: { contract: Contract }) {
  const contracted = contract.quantity_contracted_numeric || 0
  const canceled = contract.quantity_canceled_numeric || 0
  const remaining = contract.quantity_remaining_numeric || 0
  const submitted = contract.quantity_submitted_numeric || 0
  const total = contracted - canceled
  const progress = (submitted / total) * 100
  const overage = remaining < 0 ? Math.abs(remaining) : 0
  const filledThreshold = total * 0.001

  const isClosed = contract.status === 'Closed'
  const hasRemaining = remaining > filledThreshold
  const hasOverage = overage > filledThreshold
  const isFilled = !hasRemaining && !hasOverage

  return (
    <ProgressBar
      dataColor={isClosed || !hasRemaining ? 'green' : 'yellow'}
      progressValue={progress}
      description={
        <Flex alignItems="center">
          {isClosed && <CircleCheckIcon color="data.green" mr={1} fontSize="md" />}
          {isFilled ? (
            <>Filled</>
          ) : (
            <>
              {hasRemaining && (
                <>
                  {formatter.formatNumber(remaining)} {contract.quantity_uom} remain
                </>
              )}
              {hasOverage && (
                <>
                  +{formatter.formatNumber(overage)} {contract.quantity_uom} over
                </>
              )}
            </>
          )}
        </Flex>
      }
    />
  )
}
