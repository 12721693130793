import { ReactNode } from 'react'
import { Card, CardBody, CardProps } from '@chakra-ui/react'

export default function BannerCard({
  children,
  ...props
}: Readonly<{ children?: ReactNode } & CardProps>) {
  return (
    <Card boxShadow="none" variant="outline" {...props}>
      <CardBody py={4} px={6}>
        {children}
      </CardBody>
    </Card>
  )
}
