import { AlertCircleIcon, CollapsableDetailTable, Tooltip } from 'src/components/designsystem'
import DisplayConfig, { DisplayConfigItem } from 'src/components/designsystem/display-config'
import { DetailGrid, FormattedDate, useStackedDetailModal } from 'src/components/resource'
import { useTicketAssociatedContracts } from 'src/data/queries'
import { AssociatedContract } from 'src/types/tickets/AssociatedContract'

const DISPLAY_CONFIG: DisplayConfigItem<AssociatedContract>[] = [
  {
    label: 'Contract',
    key: 'display_id',
    ValueCellComponent: DisplayConfig.BoldTextValue,
    getValue: ({ data }) => data.id ?? data.display_id,
    renderValue: ({ data }) => {
      if (data.id) return data.display_id

      return (
        <>
          {data.id}
          <Tooltip label="Contract unavailable" hasArrow placement="top">
            <AlertCircleIcon color="red.500" fontSize="lg" ml={1} mb={0.5} />
          </Tooltip>
        </>
      )
    },
  },
  {
    label: 'Application Date',
    key: 'application_date',
    ValueCellComponent: DisplayConfig.BoldTextValue,
    getValue: ({ data }) => (!data.id ? null : data.application_date),
    renderValue: ({ data }) => <FormattedDate date={data.application_date} localize={false} />,
  },
  {
    label: 'Gross Qty',
    key: 'gross_quantity',
    textAlign: 'right',
    ValueCellComponent: DisplayConfig.BoldTextValue,
    getValue: ({ data }) => (!data.id ? null : data.gross_quantity),
    renderValue: ({ data }) => data.gross_quantity,
  },
  {
    label: 'Net Qty',
    key: 'net_quantity',
    textAlign: 'right',
    ValueCellComponent: DisplayConfig.BoldTextValue,
    getValue: ({ data }) => (!data.id ? null : data.net_quantity),
    renderValue: ({ data }) => data.net_quantity,
  },
]

export interface AssociatedTicketsProps {
  ticketId?: number
}

export default function AssociatedContracts({ ticketId }: Readonly<AssociatedTicketsProps>) {
  const { setItem } = useStackedDetailModal()
  const { data: contracts } = useTicketAssociatedContracts({ id: ticketId })

  if (!contracts || contracts.length < 1) return null

  return (
    <>
      <DetailGrid.Title>Associated Contracts</DetailGrid.Title>
      <CollapsableDetailTable
        rowItems={contracts}
        rowItemTitle="Contracts"
        displayConfig={DISPLAY_CONFIG}
        analyticsCategory="Tickets"
        analyticsAction="Associated Contracts List"
        setItem={(item) => setItem({ type: 'contract', item })}
        shouldShowViewButton={(rowItem) => !!rowItem.id}
      />
    </>
  )
}
