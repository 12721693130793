import { useEffect } from 'react'
import { BackStackItem, useBackStack } from 'src/utils/clients/native/android-back-stack'

type BackButtonBehaviorProps = {
  backStackItems: BackStackItem[]
}

export function BackButtonBehavior({ backStackItems }: BackButtonBehaviorProps) {
  const { push, pop } = useBackStack()

  useEffect(() => {
    backStackItems.forEach((item) => {
      push(item)
    })

    return () => {
      backStackItems.forEach(() => {
        pop()
      })
    }
  }, [backStackItems, push, pop])
  return null
}
