import { useEffect, useRef, PropsWithChildren } from 'react'
import {
  Heading,
  HStack,
  ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  useBreakpoint,
  ModalCloseButton,
  useScrollTop,
} from 'src/components/designsystem'
import BackButton from 'src/components/shared/BackButton'

export interface StackingFloatingModalProps {
  isOpen: boolean
  primaryTitle: string
  backAction: () => void
  onClose: () => void
}

export function StackingFloatingModal({
  children,
  isOpen,
  primaryTitle,
  backAction,
  onClose,
}: PropsWithChildren<StackingFloatingModalProps>) {
  const { isDesktop } = useBreakpoint()
  const [modalBodyScrollTop, { onScroll: modalBodyOnScroll }] = useScrollTop()
  const modalBodyRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (modalBodyRef?.current && modalBodyRef.current.scrollTop !== 0) {
      modalBodyRef.current.scrollTop = 0
    }
  }, [children])

  return (
    <ChakraModal
      isOpen={isOpen}
      size="full"
      onClose={onClose}
      scrollBehavior={isDesktop ? 'inside' : 'outside'}
      {...(isDesktop ? { isCentered: true } : { motionPreset: 'none', trapFocus: false })}
      blockScrollOnMount={false}
    >
      <ModalOverlay />

      <ModalContent
        width="95%"
        minHeight="auto !important"
        alignSelf="flex-start"
        p={0}
        my={0}
        mt={12}
        bg="white"
        borderRadius={8}
      >
        <ModalHeader
          p={0}
          {...(modalBodyScrollTop > 0 && { boxShadow: 'xs', zIndex: 1 })}
          {...(isDesktop
            ? { w: '100%' }
            : {
                boxShadow: 'xs',
                bg: 'white',
                borderRadius: 'lg',
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
              })}
        >
          <HStack
            w="100%"
            minH={['64px', null, '72px']}
            justifyContent="space-between"
            alignItems="center"
            px={[4, 8, null, 6]}
          >
            <BackButton onClick={backAction} px={0} my={2} />
          </HStack>

          <ModalCloseButton top={[2, 4]} />
        </ModalHeader>

        <ModalBody
          ref={modalBodyRef}
          pt={0}
          px={[2, null, 8, 12]}
          pb={12}
          bg="white"
          boxShadow="xs"
          borderRadius="lg"
          borderTopRightRadius={0}
          borderTopLeftRadius={0}
          onScroll={modalBodyOnScroll}
        >
          <Heading aria-label="Id" type="h4" ml={[4, null, 0]} my={6}>
            {primaryTitle}
          </Heading>

          {children}
        </ModalBody>
      </ModalContent>
    </ChakraModal>
  )
}
