import { centreClient } from './client'

interface CommodityBalanceResponse {
  data: CommodityBalance[]
  meta: { last_updated: string }
}

interface CommodityBalanceDetailResponse {
  data: CommodityBalanceDetail
  meta: { last_updated: string }
}

export const commodityBalanceRequests = {
  commodityBalances: async () => {
    const response = await centreClient
      .get('api/v2/commodity-balances')
      .json<CommodityBalanceResponse>()

    return response
  },

  commodityBalanceDetail: async ({
    cropSlug,
    remoteUserId,
  }: {
    cropSlug: string
    remoteUserId: string
  }) => {
    const response = await centreClient
      .get(`api/v2/commodity-balances/${encodeURIComponent(cropSlug)}/account/${remoteUserId}`)
      .json<CommodityBalanceDetailResponse>()

    return response.data
  },
}
