import { Dispatch, PropsWithChildren, SetStateAction } from 'react'
import { Modal as ChakraModal, ModalProps as CharkaModalProps } from '@chakra-ui/react'
import {
  Box,
  CircleCheckIcon,
  CloseIcon,
  Divider,
  Flex,
  Grid,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  PersonOutlineIcon,
  SearchIcon,
  Spinner,
  Stack,
  Text,
  TextProps,
  Tooltip,
  VStack,
} from 'src/components/designsystem'
import { ErrorState } from 'src/components/resource'

export interface UserSearchModalProps extends Omit<CharkaModalProps, 'children'> {
  hasSelectedUser: boolean
  selectedUser: SearchUser
  handleSelectUser: (searchUser: SearchUser) => void
  handleClearUser: () => void
  search: string
  searchUsers: SearchUser[]
  setSearch: Dispatch<SetStateAction<string>>
  isLoading?: boolean
  isError?: boolean
  isSuccess?: boolean
}

export default function UserSearchModal({
  isOpen,
  onClose,
  hasSelectedUser,
  selectedUser,
  handleSelectUser,
  handleClearUser,
  search,
  searchUsers = [],
  setSearch,
  isLoading,
  isError,
  isSuccess,
  ...props
}: UserSearchModalProps) {
  const hasSearchUsers = searchUsers && searchUsers.length > 0
  return (
    <>
      <ChakraModal
        isOpen={isOpen}
        onClose={onClose}
        size="3xl"
        scrollBehavior="inside"
        blockScrollOnMount={false}
        {...props}
      >
        <ModalOverlay />
        <ModalContent width={['90%', null, null, '800px']} minH="auto !important">
          <ModalHeader
            py={0}
            px={!hasSelectedUser && !hasSearchUsers && !search ? 0 : 4}
            boxShadow="xs"
            zIndex={1}
          >
            <InputGroup py={1.5}>
              <InputLeftElement pointerEvents="none" top="inherit">
                <SearchIcon />
              </InputLeftElement>
              <Input
                placeholder="Search for a customer"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                border="none"
                _focus={{ border: 'none', boxShadow: 'none' }}
              />
            </InputGroup>
            {(hasSelectedUser || hasSearchUsers || search) && (
              <>
                <Divider mb={[2, null, 0]} />
                <SearchUserHeader display={['none', null, 'grid']} />
              </>
            )}
          </ModalHeader>

          <ModalBody p={!hasSelectedUser && !hasSearchUsers && !search && 0} px={4} pb={4} pt={2}>
            {(hasSelectedUser || hasSearchUsers) && (
              <VStack width="full">
                {hasSelectedUser && (
                  <SearchUserRow
                    searchUser={selectedUser}
                    variant="selected"
                    handleClearUser={handleClearUser}
                  />
                )}
                {hasSearchUsers &&
                  searchUsers.map((user) => {
                    return (
                      <SearchUserRow
                        key={user.idp_id || user.phone}
                        searchUser={user}
                        {...{ handleSelectUser }}
                      />
                    )
                  })}
              </VStack>
            )}
            {isLoading && (
              <Flex my={[8, null, 20]} w="full" justifyContent="center">
                <Spinner />
              </Flex>
            )}
            {isSuccess && !hasSearchUsers && (
              <Flex my={[8, null, 20]} w="full" justifyContent="center">
                No Results
              </Flex>
            )}
            {isError && (
              <ErrorState size="sm" my={6} subHeader="There was an error while retrieving users." />
            )}
          </ModalBody>
        </ModalContent>
      </ChakraModal>
    </>
  )
}

interface SearchUserRowProps {
  searchUser: SearchUser
  variant?: 'base' | 'selected'
  handleClearUser?: () => void
  handleSelectUser?: (user: SearchUser) => void
}

function SearchUserRow({
  searchUser,
  variant = 'base',
  handleClearUser,
  handleSelectUser,
}: SearchUserRowProps) {
  return (
    <Flex
      width="full"
      layerStyle="card-small"
      bg={variant === 'selected' ? 'blue.500' : 'none'}
      onClick={handleSelectUser ? () => handleSelectUser(searchUser) : null}
      cursor={variant !== 'selected' ? 'pointer' : null}
    >
      <Grid
        templateColumns={['minmax(0, 1fr)', null, '40px minmax(0, 1fr) repeat(2, 1fr) 28px']}
        p={4}
        bg={variant === 'base' ? 'gray.50' : 'blue.400'}
        width={[variant === 'selected' ? '90%' : 'full', null, 'full']}
        color={variant === 'base' ? 'gray.700' : 'gray.50'}
        _hover={variant === 'base' ? { bg: 'gray.600', color: 'gray.50' } : null}
        alignItems="flex-start"
        borderRadius="lg"
      >
        {variant === 'base' ? (
          <PersonOutlineIcon
            display={onTabletAndDesktop}
            color="gray.500"
            fontSize="2xl"
            alignSelf="flex-start"
          />
        ) : (
          <CircleCheckIcon display={onTabletAndDesktop} fontSize="2xl" alignSelf="flex-start" />
        )}

        <SearchUserRowColumn name="Phone/Email">
          {searchUser.all_phones?.map((phoneNumber) => (
            <SearchUserRowCell key={phoneNumber} label={phoneNumber} />
          ))}
          {searchUser.all_idp_ids?.map((idpId) => <SearchUserRowCell key={idpId} label={idpId} />)}
        </SearchUserRowColumn>

        <Divider display={onMobile} my={2} />

        <SearchUserRowColumn name="Name">
          {searchUser.accounts.map((user) => {
            const isUnknown = !user.first_name && !user.last_name
            const displayName = isUnknown ? 'Unknown' : [user.first_name, user.last_name].join(' ')

            return (
              <SearchUserRowCell
                key={user.id}
                label={displayName}
                {...(isUnknown
                  ? { fontStyle: 'italic', color: variant === 'selected' ? null : 'gray.500' }
                  : {})}
              />
            )
          })}
        </SearchUserRowColumn>

        <Divider display={onMobile} my={2} />

        <SearchUserRowColumn name="Account ID">
          {searchUser.accounts.map((user) => (
            <SearchUserRowCell key={user.remote_id} label={user.remote_id} />
          ))}
        </SearchUserRowColumn>

        {variant === 'selected' && (
          <IconButton
            display={onTabletAndDesktop}
            onClick={handleClearUser}
            variant="unstyled"
            h={7}
            w={7}
            minW="unset"
            borderRadius="full"
            aria-label="Deselect User"
            _hover={{ bg: 'blue.500' }}
            icon={<CloseIcon fontSize="2xl" />}
          />
        )}
      </Grid>

      {variant === 'selected' && (
        <IconButton
          display={onMobile}
          onClick={handleClearUser}
          alignItems="center"
          variant="unstyled"
          color="white"
          w="10%"
          h="auto"
          minW="unset"
          aria-label="Deselect User"
          _hover={{ bg: 'blue.500' }}
          _focus={{ boxShadow: 'none' }}
          icon={<CloseIcon fontSize="2xl" />}
        />
      )}
    </Flex>
  )
}

function SearchUserHeader(props) {
  return (
    <Grid
      templateColumns="repeat(3, 1fr)"
      p={4}
      pl={14}
      pr="44px" //Padding needed doesn't match scale value
      textStyle="preTitle"
      {...props}
    >
      <Text textStyle="preTitle" color="gray.500">
        Phone/Email
      </Text>
      <Text textStyle="preTitle" color="gray.500">
        Name
      </Text>
      <Text textStyle="preTitle" color="gray.500">
        Account Id
      </Text>
    </Grid>
  )
}

function SearchUserRowColumn({ name, children }: PropsWithChildren & { name: string }) {
  return (
    <Box display="grid" gridTemplateColumns="auto 1fr" gridColumnGap={2}>
      <Text display={onMobile}>{name}</Text>
      <Stack align={['flex-end', null, 'flex-start']} overflow="hidden">
        {children}
      </Stack>
    </Box>
  )
}

function SearchUserRowCell({ label, ...props }: { label: string } & TextProps) {
  return (
    <Tooltip label={label}>
      <Text isTruncated w="fit-content" maxW="full" {...props}>
        {label}
      </Text>
    </Tooltip>
  )
}

// Are these cool or dumb??
const onMobile = ['initial', null, 'none']
const onTabletAndDesktop = ['none', null, 'initial']
