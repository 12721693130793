import { Client } from 'src/utils/clients/client'
import { corePluginWrapper } from 'src/utils/clients/native/wrappers/core-plugin-wrapper'

export default function getClient(): Client {
  return {
    platform: corePluginWrapper.getPlatform(),
    isNativeApp: corePluginWrapper.isNativePlatform(),
    isWebBrowser: corePluginWrapper.getPlatform() === 'web',
    isIos: corePluginWrapper.getPlatform() === 'ios',
    isAndroid: corePluginWrapper.getPlatform() === 'android',
  }
}
