import { browserPluginWrapper } from 'src/utils/clients/native/wrappers/browser-plugin-wrapper'
import { PluginListenerHandle } from '@capacitor/core'
import { type UseQueryResult } from '@tanstack/react-query'
import { useEffect, useRef } from 'react'
import getClient from 'src/utils/clients/get-client'

export const inAppBrowser = {
  open,
  onBrowserClosed,
}

async function open(url: string) {
  return browserPluginWrapper.open({ url })
}

async function onBrowserClosed(behavior: () => void): Promise<PluginListenerHandle> {
  return browserPluginWrapper.addListener('browserFinished', behavior)
}

export function useRefetchOnBrowserClosed(refetch: UseQueryResult['refetch']) {
  const listener = useRef<PluginListenerHandle | undefined>(undefined)

  useEffect(() => {
    ;(async () => {
      const isNativeApp = getClient().isNativeApp
      if (isNativeApp) {
        const isAndroid = getClient().isAndroid
        listener.current = await inAppBrowser.onBrowserClosed(async () => {
          const millisecondsToWait = isAndroid ? 2500 : 0
          setTimeout(async function () {
            await refetch()
          }, millisecondsToWait)
          return
        })
      }
    })()
    return () => {
      if (listener.current) {
        listener.current.remove()
      }
    }
  }, [refetch])
}
