import { centreClient } from './client'

export interface PrepaidResponse {
  data: Prepaid[]
  meta: { pagination: Pagination }
}

export const prepaidRequests = {
  prepaidContracts: async ({ page }: { page?: number } = {}) => {
    let options = {}

    if (page) {
      options = { ...options, searchParams: { page } }
    }
    return centreClient.get(`api/v2/prepaid-contracts`, options).json<PrepaidResponse>()
  },

  prepaid: async (id: number | string) => {
    const response = await centreClient
      .get(`api/v2/prepaid-contracts/${id}`)
      .json<{ data: PrepaidDetail }>()

    return response.data
  },

  prepaidContractsExport: async () => centreClient.get('api/v2/prepaid-contracts/export'),
}
