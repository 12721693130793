import { analyticsServiceClient } from './client'

export interface AnalyticsDateRangeProps {
  companySlug: string
  startDate: string
  endDate?: string
}

export type UniquePageViewsReportResponse = { data: UniquePageViewsReportRow[]; type: string }
export type UniqueVisitorsReportResponse = { data: UniqueVisitorsMonth[]; type: string }

export const analyticsRequests = {
  uniquePageViews: async ({ companySlug, startDate, endDate }: AnalyticsDateRangeProps) => {
    const options = {
      companySlug,
      ...(startDate && { startDate }),
      ...(endDate && { endDate }),
    }

    return analyticsServiceClient
      .post('api/data/analytics/v1/GetPageTitles', {
        json: options,
      })
      .json<UniquePageViewsReportResponse>()
  },

  uniqueVisitors: async ({ companySlug, startDate, endDate }: AnalyticsDateRangeProps) => {
    const options = {
      companySlug,
      ...(startDate && { startDate }),
      ...(endDate && { endDate }),
    }

    return analyticsServiceClient
      .post('api/data/analytics/v1/GetUniqueVisitors', {
        json: options,
      })
      .json<UniqueVisitorsReportResponse>()
  },
}
