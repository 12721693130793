import * as api from 'src/api'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { flattenPages, getNextPageParam } from 'src/data/queries/utils'
import { useMemo } from 'react'
import { useMerchandiser } from 'src/data/merchandiser'

export function useWorkOrders() {
  const { selectedUserIdpId } = useMerchandiser()

  const query = useInfiniteQuery({
    queryKey: ['work-order-list', { selectedUserIdpId }],
    queryFn: ({ pageParam }) => api.centre.workOrders({ page: pageParam }),
    initialPageParam: 1,
    meta: {
      errorMessage: 'Unable to retrieve work orders, please refresh the page to try again.',
    },
    getNextPageParam,
  })

  const mergedPageData = useMemo(() => flattenPages(query.data), [query.data])

  return { query, data: mergedPageData }
}

export function useWorkOrderDetail({ id, enabled }: { id: string | number; enabled: boolean }) {
  return useQuery({
    queryKey: ['work-order-detail', { id }],
    queryFn: async () => (id ? api.centre.workOrder(id) : null),
    enabled: Boolean(enabled && id),
    staleTime: Infinity,
  })
}
