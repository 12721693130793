import { useMemo } from 'react'
import { useQuery, useInfiniteQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { getNextPageParam } from 'src/data/queries/utils'
import { useMerchandiser } from 'src/data/merchandiser'
import * as api from 'src/api'
import { TicketFiltersForQuery } from 'src/components/tickets/TicketFilters'
import { TicketSummary } from 'src/types/tickets/TicketSummary'
import { AssociatedContract } from 'src/types/tickets/AssociatedContract'
import { Ticket } from 'src/types/tickets/Ticket'

export function useTicketFilterOptions() {
  const { selectedUserIdpId } = useMerchandiser()

  return useQuery({
    queryKey: ['ticket-filter-options', { selectedUserIdpId }],
    queryFn: () => {
      return api.centre.ticketFilterOptions()
    },
    staleTime: Infinity,
  })
}

export function useTicketSummaries({ filter }: { filter?: TicketFiltersForQuery }) {
  const { selectedUserIdpId } = useMerchandiser()

  return useQuery<TicketSummary[], api.HTTPError>({
    queryKey: ['ticket-summaries', { selectedUserIdpId, filter }],
    queryFn: () => api.centre.ticketSummaries({ filter }),
    staleTime: Infinity,
  })
}

export function useTickets({ filter }: { filter?: TicketFiltersForQuery } = {}) {
  const { selectedUserIdpId } = useMerchandiser()

  const query = useInfiniteQuery({
    queryKey: ['ticket-list', { selectedUserIdpId, filter }],
    queryFn: ({ pageParam }) =>
      api.centre.tickets({ filter, page: pageParam, useSimplePagination: true }),
    initialPageParam: 1,
    meta: {
      errorMessage: 'Unable to retrieve tickets, please refresh the page to try again.',
    },
    getNextPageParam,
  })

  const mergedPageData = useMemo(() => {
    // Infinite queries contain an array of page data
    // This reduces them down to just one big list of the items
    return (query.data?.pages || []).reduce((acc, page) => [...acc, ...page.data], [])
  }, [query.data?.pages])

  return { query, data: mergedPageData }
}

export function useTicketDetail({ id, enabled }: { id: string | number; enabled: boolean }) {
  const { selectedUserIdpId } = useMerchandiser()
  return useQuery<Ticket, api.HTTPError>({
    queryKey: ['ticket-detail', { id, selectedUserIdpId }],
    queryFn: async () => (id ? api.centre.ticket(id) : null),
    enabled,
    staleTime: Infinity,
  })
}

export function useTicketAssociatedSettlements({ id }: { id: number }) {
  const { selectedUserIdpId } = useMerchandiser()
  return useQuery<AssociatedSettlement[], api.HTTPError>({
    queryKey: ['ticket-associated-settlement', { id, selectedUserIdpId }],
    queryFn: async () => (id ? api.centre.ticketAssociatedSettlements(id) : null),
    staleTime: Infinity,
  })
}

export function useTicketAssociatedContracts({ id }: { id?: number }) {
  const { selectedUserIdpId } = useMerchandiser()
  return useQuery<AssociatedContract[], api.HTTPError>({
    queryKey: ['ticket-associated-contract', { id, selectedUserIdpId }],
    queryFn: () => api.centre.ticketAssociatedContracts(id),
    enabled: id !== undefined,
    staleTime: Infinity,
  })
}

export function useSetTicketFarmFields() {
  const client = useQueryClient()
  const { selectedUserIdpId } = useMerchandiser()

  return useMutation<unknown, api.HTTPError, { ticketId: number; farmFieldIds: number[] }>({
    mutationFn: ({ ticketId, farmFieldIds }) =>
      api.centre.setFieldsForTicket(ticketId, farmFieldIds),
    onSuccess: async (data, variables) => {
      await client.invalidateQueries({
        queryKey: ['ticket-detail', { id: variables.ticketId, selectedUserIdpId }],
      })
    },
  })
}
