import { VStack } from 'src/components/designsystem'
import { HistoricalNotification } from 'src/api/notify-service/models/historical-notification'
import CapacitorPullToRefresh from 'src/utils/capacitor-pull-to-refresh'
import { NotificationCell } from './NotificationCell'

interface NotificationStackProps {
  hasItems: boolean
  data: HistoricalNotification[]
  isHidden: boolean
  handleRefresh: () => void
}

export function NotificationStack({
  hasItems,
  data,
  isHidden,
  handleRefresh,
}: Readonly<NotificationStackProps>) {
  // margin bottom = 56px is from the height for the DrawerFooter. Without it, it covers up the last notification
  return (
    <CapacitorPullToRefresh onRefreshAction={handleRefresh}>
      <VStack p="1rem 0.5rem" mb={14} data-testid="NotificationStack">
        {hasItems &&
          data.map((item, idx, arr) => (
            <NotificationCell
              key={item.id}
              arr={arr}
              idx={idx}
              notification={item}
              isBackToTopButtonShown={!isHidden}
            />
          ))}
      </VStack>
    </CapacitorPullToRefresh>
  )
}
