import { Fragment, useEffect, useState } from 'react'
import * as api from 'src/api'
import { type AuthzPermission } from 'src/api/bidl/iam/authz-permissions'
import { useAuth } from 'src/auth'
import { useMerchandiser } from 'src/data/merchandiser'
import { useHasStaffPermission } from 'src/data/useHasStaffPermission'
import { ErrorState, LoadingState } from 'src/components/resource'
import ENV from 'src/utils/env'
import { CONFIGCAT_FLAGS, configCatSSRClient } from 'src/utils/config-cat'
import { UnselectedPage } from './UnselectedPage'

export function MerchandiserPageCheck({
  children,
  permission,
}: Readonly<{ children: React.ReactNode; permission?: AuthzPermission }>) {
  const { isStaff } = useAuth()
  const configCatSnapshot = configCatSSRClient.snapshot()

  const isAuthzPermissionsFlagEnabled = configCatSnapshot.getValue(
    CONFIGCAT_FLAGS.isAuthzPermissionsEnabled,
    false
  )

  const { hasPermission, isLoading } = useHasStaffPermission(
    // TODO: Remove once isAuthzPermissionsEnabled ConfigCat flag is removed
    // @ts-expect-error Use data_viewer as the permission if the config flag is disabled
    isAuthzPermissionsFlagEnabled ? permission : 'data_viewer'
  )

  const { userId, selectedUserQuery, selectedAccountsQuery, selectedUser, isUnknownId } =
    useMerchandiser()

  const isStaffView = isStaff || ENV.MOCK_MERCH_MODE

  if (isStaffView) {
    if (isLoading || selectedUserQuery.isFetching || selectedAccountsQuery.isFetching) {
      return <LoadingState mt="15vh" />
    }
    if (!hasPermission) {
      return (
        <ErrorState
          isVerticallyCentered
          header="Request Access"
          subHeader="Please reach out to your administrator for access."
        />
      )
    }
    if (!userId || isUnknownId) return <UnselectedPage />
    if (selectedUserQuery.isError || selectedAccountsQuery.isError) return <ErrorState />
  }

  return (
    <PageCheckWithHeader selectedUser={selectedUser} userId={userId} isStaffView={isStaffView}>
      {children}
    </PageCheckWithHeader>
  )
}

interface PageCheckWithHeaderProps {
  children: React.ReactNode
  userId: string
  isStaffView: boolean
  selectedUser: SearchUser
}

function PageCheckWithHeader({
  userId,
  isStaffView,
  selectedUser,
  children,
}: Readonly<PageCheckWithHeaderProps>) {
  const [isHeaderSet, setIsHeaderSet] = useState(!userId)
  const actualUserId = selectedUser?.userId ? String(selectedUser?.userId) : null

  useEffect(() => {
    if (!isStaffView || !actualUserId) return

    api.clearCentreAppHeaderImpersonation()
    api.clearReportingServiceClientHeaderImpersonation()
    api.clearPaymentsServiceHeaderImpersonation()

    api.updateCentreAppHeader('X-Impersonate-Id', actualUserId)
    api.updateReportingServiceClientHeader('X-Impersonate-Id', actualUserId)
    api.updatePaymentsServiceHeader('X-Impersonate-Id', actualUserId)

    setIsHeaderSet(true)
  }, [isStaffView, actualUserId])

  if (!isHeaderSet) return <LoadingState />

  // When the selected user changes, the `key` here will cause the page to un-mount and re-mount
  return <Fragment key={userId || 'grower'}>{children}</Fragment>
}
