import { centreClient } from './client'

export const usersRequests = {
  userSearch: async ({ query, userId }: { query?: string; userId?: string }) => {
    const params = new URLSearchParams()
    // Using .append() handles uri-encoding of query values
    if (query) params.append('q', query)
    if (userId) params.append('user_id', userId)

    return centreClient
      .get(`api/v1/search/user?${params.toString()}`)
      .json<{ data: SearchUserAccount[] }>()
  },
  getAccountsByUserId: async ({ userId }: { userId?: string }) => {
    if (!userId) return null

    return centreClient
      .post('api/v1/get-accounts-by-user-id', {
        json: {
          user_id: userId,
        },
      })
      .json<GetAccountsByUserIdResponse>()
  },
}
