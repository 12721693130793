import { BackArrow, Button, type ButtonProps } from 'src/components/designsystem'

type BackButtonProps = {
  backButtonText?: string
} & ButtonProps

export default function BackButton({
  backButtonText = 'Back',
  ...props
}: Readonly<BackButtonProps>) {
  return (
    <Button
      variant="link"
      data-testid="back-button"
      px={2}
      my={7}
      leftIcon={<BackArrow fontSize="md" />}
      {...props}
    >
      {backButtonText}
    </Button>
  )
}
