import { NextPageContext, GetServerSidePropsContext } from 'next'
import {
  parseCookies,
  setCookie as nookiesSetCookie,
  destroyCookie as nookiesDestroyCookie,
} from 'nookies'
import { ENV } from 'src/utils/env'

const DEFAULT_MAX_AGE = 30 * 24 * 60 * 60
const DEFAULT_PATH = '/'
const DEFAULT_SECURE = ENV.NODE_ENV !== 'development'

export type GetCookieOptions = Parameters<typeof parseCookies>[1]
export type SetCookieOptions = Parameters<typeof nookiesSetCookie>[3]
export type Key = `bushel-web-${string}`

/**
 * Wraps the underlying nookies `setCookie` with opinionated defaults.
 *
 * @param ctx - NextJS page or API context, express context, null or undefined.
 * @param name - The name of your cookie.
 * @param value - The value of your cookie.
 * @param options - Options that we pass down to `cookie` library.
 */
export function setCookie(
  ctx: NextPageContext | GetServerSidePropsContext,
  name: Key,
  value: string,
  options: SetCookieOptions = {}
) {
  return nookiesSetCookie(ctx, name, value, {
    maxAge: DEFAULT_MAX_AGE,
    path: DEFAULT_PATH,
    secure: DEFAULT_SECURE,
    ...options,
  })
}

export function destroyCookie(name: Key) {
  document.cookie = `${name}=; Path=${DEFAULT_PATH}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}

export function ssrDestroyCookie(ctx: NextPageContext | GetServerSidePropsContext, name: string) {
  nookiesDestroyCookie(ctx, name, { path: DEFAULT_PATH })
}

type CookieKey = 'BYPASS_CODE_PREFIX'

export const COOKIE_KEYS: Record<CookieKey, Key> = {
  BYPASS_CODE_PREFIX: 'bushel-web-access-code-',
}

export { parseCookies }
