/* istanbul ignore file */
import { PushNotifications } from '@capacitor/push-notifications'
export type { RegistrationError } from '@capacitor/push-notifications'

export const pushNotificationsPluginWrapper = {
  checkPermissions: PushNotifications.checkPermissions,
  addListener: PushNotifications.addListener,
  requestPermissions: PushNotifications.requestPermissions,
  register: PushNotifications.register,
}
