import { startSyncingWithNotifyWhenAppEntersForeground } from 'src/app/native-app-setup/start-syncing-with-notify-when-app-enters-foreground'
import { syncWithNotify } from 'src/app/native-app-setup/sync-with-notify'
import {
  getAnonymousGroupId,
  setAnonymousGroupId,
} from 'src/utils/push-notifications/group-id/anonymous-group-id'
import { generateGroupId } from 'src/utils/push-notifications/group-id/generate-group-id'
import { Optional } from 'src/utils/types/optional'
import { startRespondingToPushNotificationActions } from 'src/app/native-app-setup/push-notification-tap-listener'
import { nativeApp } from 'src/utils/clients/native/native-app'

// NOTE: Order of operations is important here. Be careful.
export async function setupNativeApp(userGroupId: Optional<string>) {
  await generateAnonymousGroupIdIfNeeded()
  await syncWithNotify(userGroupId)
  await startSyncingWithNotifyWhenAppEntersForeground(userGroupId)
  await startRespondingToPushNotificationActions()
  await nativeApp.lockOrientation()
}

async function generateAnonymousGroupIdIfNeeded() {
  const currentGroupId = await getAnonymousGroupId()

  if (currentGroupId) {
    return
  } else {
    const groupId = generateGroupId()
    await setAnonymousGroupId(groupId)
  }
}
