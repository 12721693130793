import { insecureClientStorage } from 'src/utils/storage/insecure/insecure-client-storage'

export async function migratePhase1AnonymousGroupId() {
  const oldKey = 'bwp-group-id'
  const newKey = 'bwp-anonymous-group-id'
  const phase1AnonymousGroupId: string = await insecureClientStorage.get(oldKey)

  if (phase1AnonymousGroupId) {
    await insecureClientStorage.set(newKey, phase1AnonymousGroupId)
    await insecureClientStorage.set(oldKey, undefined)
  }
}
