import { PropsWithChildren, useMemo } from 'react'
import { BackButtonBehavior } from './BackButtonBehavior'
import { StackingFloatingModal, StackingFloatingModalProps } from './StackingFloatingModal'
import getClient from 'src/utils/clients/get-client'

export function FloatingModalWithBackButtonBehavior({
  onClose,
  backAction,
  children,
  ...rest
}: PropsWithChildren<StackingFloatingModalProps>) {
  const isAndroid = getClient().isAndroid

  const backStackItems = useMemo(() => [{ callback: backAction }], [backAction])

  return (
    <>
      {isAndroid && <BackButtonBehavior backStackItems={backStackItems} />}
      <StackingFloatingModal onClose={onClose} backAction={backAction} {...rest}>
        {children}
      </StackingFloatingModal>
    </>
  )
}
