import { Button, ButtonProps, SearchIcon, Text } from 'src/components/designsystem'

export default function CustomerSearchBarButton(props: Readonly<ButtonProps>) {
  return (
    <Button
      w="100%"
      border="1px"
      bg="gray.50"
      height="40px"
      role="search"
      color="gray.500"
      borderColor="gray.300"
      justifyContent="flex-start"
      aria-label="Search for a customer"
      _hover={{ backgroundColor: 'gray.50' }}
      _active={{ backgroundColor: 'gray.50' }}
      _focus={{ backgroundColor: 'gray.50' }}
      leftIcon={<SearchIcon color="gray.500" />}
      {...props}
    >
      <Text fontSize="sm">Search for a customer</Text>
    </Button>
  )
}
