import { Flex, Img } from 'src/components/designsystem'
import { NavigateToExternalLink } from 'src/components/utils/NavigateToExternalLink'
import { ENV } from 'src/utils/env'

export function SidebarFooter({ isPinned }: { isPinned: boolean }) {
  return (
    <NavigateToExternalLink href={ENV.POWERED_BY_BUSHEL_URL}>
      <Flex
        py={8}
        transitionTimingFunction="ease-in ease-out"
        transitionDuration="fast"
        width="100%"
        justifyContent="center"
      >
        <Img
          src="/img/powered-by-bushel.png"
          alt="Powered By Bushel"
          width="110px"
          position="absolute"
          opacity={isPinned ? '100' : '0'}
          _groupHover={{ opacity: 100 }}
          transitionTimingFunction="ease-in ease-out"
          transitionDuration="fast"
        />
        <Img
          src="/img/bushel-u.png"
          alt="Powered By Bushel"
          width="24px"
          position="relative"
          _groupHover={{ opacity: 0 }}
          opacity={isPinned ? 0 : undefined}
          transitionTimingFunction="ease-in ease-out"
          transitionDuration="fast"
        />
      </Flex>
    </NavigateToExternalLink>
  )
}
