import {
  Box,
  CircleCheckIcon,
  Flex,
  MultiValueProgressBar,
  MultiValueProgressBarItem,
} from 'src/components/designsystem'
import * as formatter from 'src/utils/formatter'
import { generateContractPricedFacts } from './generate-contract-facts'

function generateDataBites({ contract }: { contract: ContractDetailV3 }) {
  const { isClosed, unpriced, canceled, priced, total } = generateContractPricedFacts(contract)

  let bites: MultiValueProgressBarItem[] = []

  const canceledPct = Math.round((canceled / total) * 100)
  const pricedPct = Math.round((priced / total) * 100)
  const unpricedPct = Math.round((unpriced / total) * 100)

  if (unpricedPct || pricedPct || canceledPct) {
    if (pricedPct + canceledPct >= 100) {
      bites.push({ value: 100, color: 'green', title: `Qty Priced: ${priced}` })
    } else {
      canceledPct &&
        bites.push({ value: canceledPct, color: 'red', title: `Qty Canceled: ${canceled}` })
      pricedPct &&
        bites.push({ value: pricedPct, color: 'mediumblue', title: `Qty Priced: ${priced}` })
      unpricedPct &&
        bites.push({ value: unpricedPct, color: 'lightgray', title: `Qty Unpriced: ${unpriced}` })
    }
  } else {
    bites.push({ value: 100, color: 'lightgray', title: `Qty Priced: ${priced}` })
  }

  if (sumBites(bites) !== 100) {
    bites = correctPercentages(bites)
  }

  return { bites, isClosed }
}

function correctPercentages(bites: MultiValueProgressBarItem[]) {
  bites = bites.map((bite) => ({ ...bite, value: bite.value < 5 ? 5 : bite.value }))
  const totalSum = sumBites(bites)

  return bites.map((bite) => ({ ...bite, value: (bite.value / totalSum) * 100 }))
}

function sumBites(pieces: MultiValueProgressBarItem[]) {
  return pieces.reduce((acc, { value }) => acc + value, 0)
}

interface ContractProgressBarProps {
  contract: ContractDetailV3
}

export function ContractProgressBarQtyPriced({ contract }: ContractProgressBarProps) {
  if (!contract) return null

  const { bites, isClosed } = generateDataBites({
    contract,
  })

  return (
    <Box>
      <Flex direction={['row', null, null, 'column']}>
        <MultiValueProgressBar bites={bites} />
      </Flex>
      <Flex
        justify="center"
        align="center"
        textStyle="small"
        whiteSpace="nowrap"
        mt={[0, null, null, 1]}
      >
        {isClosed && (
          <CircleCheckIcon
            color="data.green"
            mt="-2px"
            mr={1}
            data-testid={`${contract.display_id} closed`}
          />
        )}
        <Box>
          {formatter.formatNumber(
            contract.quantity_priced_numeric + contract.quantity_canceled_numeric
          )}
          /{contract.quantity_contracted}
        </Box>
      </Flex>
    </Box>
  )
}
