import { useCallback } from 'react'
import { useAuth } from 'src/auth'
import { logger } from 'src/utils'
import * as api from 'src/api'
import * as ls from 'src/utils/local-storage'

export function useFailedLoginReporting() {
  const { slug, user } = useAuth()
  const phoneNumber = user?.phone_numbers?.data?.[0]?.phone
  const email = user?.email_addresses?.data?.[0]?.email
  const userIdpId = email || phoneNumber
  const userId = user?.id

  return useCallback(
    async (name: string, locationId: number) => {
      const storageValue = makeStorageValue({ slug, userIdpId })
      const hasReportedFailedLogin = ls.getLocalStorageItemByKey(LS_KEY) === storageValue

      if (hasReportedFailedLogin) return

      if (!userIdpId) {
        logger.info({
          message: 'Failed login for account without email or phone number',
          error: null,
          context: { user_id: userId },
        })
        return
      }

      const companies = await api.centre.companies()
      const company = companies.primary?.[0]

      if (!company) return

      try {
        await api.centre.reportFailedLogin({
          company_id: company.id,
          value: userIdpId,
          name: name,
          location_id: locationId,
        })

        setFailedLoginMarker(storageValue)
      } catch (err) {
        logger.error({
          message: 'Failed to report failed login',
          error: err,
          context: { slug, userIdpId },
        })
      }
    },
    [slug, userIdpId, userId]
  )
}

const LS_KEY = 'bwp-failed-login'

export function setFailedLoginMarker(value: string) {
  ls.setLocalStorageItem(LS_KEY, value)
}

export function removeFailedLoginMarker() {
  ls.removeLocalStorageKey(LS_KEY)
}

function makeStorageValue({ slug, userIdpId }: { slug: string; userIdpId: string }) {
  return [slug, userIdpId].join('-')
}
