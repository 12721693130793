import { nativePreferences } from 'src/utils/clients/native/native-preferences'
import { StorageKey } from 'src/utils/storage/storage-key'
import { Nullable } from 'src/utils/types/nullable'

export const insecureNativeStorage = {
  get,
  set,
}

async function get<T>(key: StorageKey): Promise<T | undefined> {
  const stringRepresentation = await nativePreferences.get(key)

  if (stringRepresentation) {
    return parse(stringRepresentation)
  } else {
    return Promise.resolve(undefined)
  }
}

function parse<T>(stringRepresentation: string): Promise<T | undefined> {
  const data = JSON.parse(stringRepresentation).data as Nullable<T>

  if (data !== null) {
    return Promise.resolve(data)
  } else {
    return Promise.resolve(undefined)
  }
}

function set<T>(key: StorageKey, value?: T) {
  if (value !== undefined) {
    return saveToCapacitorPreferences(key, value)
  } else {
    return nativePreferences.set(key, undefined)
  }
}
function saveToCapacitorPreferences<T>(key: StorageKey, value: T) {
  const stringRepresentation = JSON.stringify({ data: value })
  return nativePreferences.set(key, stringRepresentation)
}
