import { useAuth } from 'src/auth'
import useAggregatorConfigCheck from 'src/components/resource/useAggregatorConfigCheck'

export const CRM_CONFIG_KEY = 'crm'
export const PREMIUM_CRM_VERSION = '2.0.0'

export default function useHasCrmAccess() {
  const { hasPermission } = useAuth()
  const hasCrmUserPermission = hasPermission(CRM_CONFIG_KEY)
  const {
    isLoading,
    isConfigEnabled,
    isVersionMatching: premiumVersionMatching,
  } = useAggregatorConfigCheck(CRM_CONFIG_KEY, PREMIUM_CRM_VERSION)
  const hasFullCrmAccess = hasCrmUserPermission && premiumVersionMatching

  return {
    isLoading,
    hasCrmUserPermission,
    hasFullCrmAccess,
    hasPremiumCrm: premiumVersionMatching,
    isCrmConfigEnabled: isConfigEnabled,
  }
}
