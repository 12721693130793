import { useMemo } from 'react'
import { Modal } from 'src/components/designsystem'
import { useStore } from 'src/components/report-issue/helpers'
import {
  StartStep,
  CantLogInStep,
  MissingDataFeatureStep,
  MissingDataSubmitStep,
  IncorrectDataFeatureStep,
  IncorrectDataSubmitStep,
  OtherIssueSubmit,
  SubmitSuccess,
  SubmitError,
} from 'src/components/report-issue/Steps'
import { Step } from './Step'

const STEP_COMPONENTS = {
  [Step.START]: StartStep,

  [Step.MISSING_DATA_FEATURE]: MissingDataFeatureStep,
  [Step.MISSING_DATA_SUBMIT]: MissingDataSubmitStep,

  [Step.INCORRECT_DATA_FEATURE]: IncorrectDataFeatureStep,
  [Step.INCORRECT_DATA_SUBMIT]: IncorrectDataSubmitStep,

  [Step.CANT_LOG_IN]: CantLogInStep,
  [Step.OTHER_ISSUE_SUBMIT]: OtherIssueSubmit,

  [Step.SUBMIT_SUCCESS]: SubmitSuccess,
  [Step.SUBMIT_ERROR]: SubmitError,
}

export function ReportIssueModal() {
  const { isOpen, step, close } = useStore()
  const Component = useMemo(() => STEP_COMPONENTS[step], [step])

  return (
    <Modal
      isOpen={isOpen}
      body={<Component />}
      onClose={close}
      hideCloseButton={Component === SubmitError || Component === SubmitSuccess}
      isCentered
    />
  )
}

export { Step, useStore }
