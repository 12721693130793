import React from 'react'
import {
  Box,
  BoxProps,
  GridProps,
  TextProps,
  StackProps,
  Grid,
  VStack,
  Text,
  Heading,
} from 'src/components/designsystem'
import { labelToTestId } from 'src/utils/string/label-to-test-id'

const DetailGrid = ({ ...props }: GridProps) => (
  <Grid
    templateColumns={['1fr', null, '1fr 1fr', 'repeat(3, 1fr)']}
    w="100%"
    columnGap="4%"
    {...props}
  />
)

function Title({ ...props }) {
  return (
    <Heading
      ml={[4, null, 0]}
      mt={[4, null, null, 8]}
      pt={4}
      pb={6}
      fontSize="lg"
      {...props}
      type="h6"
      data-testid={`${labelToTestId(props.children)}-title`}
    />
  )
}

function TopHeading({ ...props }: TextProps) {
  return (
    <Text
      key={`heading-${props.children}`}
      display="flex"
      alignItems="center"
      textTransform="uppercase"
      textStyle="preTitle"
      w="100%"
      {...props}
      data-testid={`${labelToTestId(props.children?.toString() ?? 'broken')}-top-heading`}
    />
  )
}

function List(props: StackProps) {
  return <VStack w="100%" spacing={{ base: 0, lg: 7 }} {...props} />
}

interface ListCardProps extends BoxProps {
  heading?: React.ReactNode
}

function ListCard({ heading, children, ...props }: ListCardProps) {
  return (
    <Box width="100%" py={2} px={[4, null, 6]} borderRadius={8} {...props}>
      {heading && (
        <DetailGrid.TopHeading minH={['40px', null, '48px']}>{heading}</DetailGrid.TopHeading>
      )}
      {children}
    </Box>
  )
}

DetailGrid.Title = Title
DetailGrid.TopHeading = TopHeading
DetailGrid.List = List
DetailGrid.ListCard = ListCard

export default DetailGrid
