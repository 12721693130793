import { useQuery } from '@tanstack/react-query'
import { centreClient, futuresServiceClient, type HTTPError, futuresService, centre } from 'src/api'
import { useAuth } from 'src/auth'
import { CashBidResponse } from 'src/api/cash-bids/types/CashBidResponse'

export const CASH_BID_REFETCH_INTERVAL = 30000

export function cashBidsQueryKey(version: string | boolean) {
  return ['cash-bids', { version }]
}

export function useCashBids({ version }: { version: string | boolean }) {
  const { token } = useAuth()
  const futuresServiceClientExtended = futuresServiceClient.extend({
    headers: { Authorization: token ? `Bearer ${token}` : undefined },
  })
  const centreClientExtended = centreClient.extend({
    headers: { Authorization: token ? `Bearer ${token}` : undefined },
  })

  return useQuery<CashBidResponse, HTTPError>({
    queryKey: cashBidsQueryKey(version),
    queryFn: async () => {
      let response: CashBidResponse
      switch (version) {
        case '1.0.0':
          response = await centre.cashBids(centreClientExtended)
          break
        case '1.1.0':
          response = await centre.cashBidsV3(centreClientExtended)
          break
        case '2.0.0':
          response = await futuresService.cashBids(undefined, futuresServiceClientExtended)
          break
        default:
          console.error(`Unsupported cash bids version "${version}"`)
          break
      }

      const locationsWithCrops = response.data
        .filter((location) => location.crops.some((crop) => crop.bids.length > 0)) // Find locations with crops that have bids
        .map((location) => ({
          ...location,
          crops: location.crops.filter((crop) => crop.bids.length > 0), // Remove crops with no bids
        }))

      return { data: locationsWithCrops, meta: response.meta }
    },
    meta: {
      errorMessage: 'Unable to retrieve cash bids, please refresh the page to try again.',
    },
    staleTime: CASH_BID_REFETCH_INTERVAL,
    refetchInterval: CASH_BID_REFETCH_INTERVAL,
    refetchOnWindowFocus: true,
  })
}

export function useCashBidsLocations() {
  const { token } = useAuth()
  const centreClientExtended = centreClient.extend({
    headers: { Authorization: token ? `Bearer ${token}` : undefined },
  })
  return useQuery<CashBidResponse, HTTPError>({
    queryKey: ['cash-bid-locations', centreClientExtended],
    queryFn: () => {
      return centre.cashBidsLocations(centreClientExtended)
    },
    staleTime: CASH_BID_REFETCH_INTERVAL,
    refetchInterval: CASH_BID_REFETCH_INTERVAL,
    refetchOnWindowFocus: true,
    meta: {
      errorMessage: 'Unable to retrieve locations, please refresh the page to try again.',
    },
  })
}
