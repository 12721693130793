import Rollbar from 'rollbar'
import ENV, { SERVER_ENV } from './env'
import { HTTPError } from 'ky'

interface RollbarContext {
  user?: Me
}

export function rollbarConfig(context?: RollbarContext): Rollbar.Configuration {
  return ENV.NODE_ENV !== 'test'
    ? {
        enabled: ENV.NODE_ENV !== 'development',
        accessToken:
          typeof window === 'undefined'
            ? SERVER_ENV.ROLLBAR_SERVER_TOKEN
            : ENV.ROLLBAR_CLIENT_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,
        reportLevel: ENV.LOG_REPORT_LEVEL,
        payload: {
          environment: ENV.BUSHEL_ENVIRONMENT,
          person: context?.user,
        },
        //TODO: add scrub for keycloak tokens in case they get included in a log accidentally
        //TODO: Do we need to worry about GDPR and deleting user information in logs if requested? See https://docs.rollbar.com/docs/javascript#managing-sensitive-data
      }
    : {}
}

interface loggerProps {
  message: string
  error?: Error | HTTPError['response']
  context?: Record<string, any>
}

export const logger = {
  debug: ({ message, error, context }: loggerProps) => {
    console.debug(message, JSON.stringify({ error, ...context }))
  },
  info: ({ message, error, context }: loggerProps) => {
    console.info(message, JSON.stringify({ error, ...context }))
  },
  warn: ({ message, error, context }: loggerProps) => {
    console.warn(message, JSON.stringify({ error, ...context }))
  },
  error: ({ message, error, context }: loggerProps) => {
    console.error(message, JSON.stringify({ error, ...context }))

    if (!ENV.MOCK_MODE) {
      const rollbar = new Rollbar(rollbarConfig())
      rollbar.error(message, error, context)
    }
  },
}

export default logger
