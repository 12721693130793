import { preferencesPluginWrapper } from 'src/utils/clients/native/wrappers/preferences-plugin-wrapper'

export const nativePreferences = {
  get,
  set,
}

async function get(key: string): Promise<string | undefined> {
  const result = await preferencesPluginWrapper.get({ key })
  const value = result.value

  if (value) {
    return value
  } else {
    return undefined
  }
}

function set(key: string, value?: string) {
  if (value) {
    return preferencesPluginWrapper.set({ key, value })
  } else {
    return preferencesPluginWrapper.remove({ key })
  }
}
