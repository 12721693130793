import { defineStyleConfig } from '@chakra-ui/react'

export const SpinnerStyleConfig = defineStyleConfig({
  defaultProps: {
    size: 'xl',
  },
  baseStyle: {
    color: 'blue.400',
    emptyColor: 'gray.200',
    thickness: '4px',
    speed: '.8s',
  },
})
