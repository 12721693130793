import { useTicketAssociatedSettlements } from 'src/data/queries'
import { DisplayConfigItem } from 'src/components/designsystem/display-config'
import { DetailGrid, FormattedDate, useStackedDetailModal } from 'src/components/resource'
import { CollapsableDetailTable } from 'src/components/designsystem'

const DISPLAY_CONFIG: DisplayConfigItem<AssociatedSettlement>[] = [
  {
    label: 'Settlement',
    key: 'display_id',
  },
  {
    label: 'Date',
    key: 'application_date',
    renderValue: ({ data }) => <FormattedDate date={data.application_date} localize={false} />,
  },
  {
    label: 'Gross Quantity',
    key: 'gross_quantity',
  },
  {
    label: 'Net Quantity',
    key: 'net_quantity',
  },
]

interface AssociatedSettlementsProps {
  ticketId: number
}

export default function AssociatedSettlements({ ticketId }: AssociatedSettlementsProps) {
  const { data: associatedSettlements } = useTicketAssociatedSettlements({ id: ticketId })
  const { setItem } = useStackedDetailModal()

  if (!associatedSettlements || associatedSettlements.length === 0) return null
  return (
    <>
      <DetailGrid.Title>Associated Settlements</DetailGrid.Title>
      <CollapsableDetailTable
        rowItems={associatedSettlements}
        rowItemTitle="Settlements"
        displayConfig={DISPLAY_CONFIG}
        analyticsCategory="Tickets"
        analyticsAction="Ticket Associated Settlements List"
        setItem={(item) => setItem({ type: 'settlement', item })}
        shouldShowViewButton={(ticket) => !!ticket.id}
      />
    </>
  )
}
