import { bidlClient } from 'src/api/bidl/client'

export const accountPayableBaseUrl = 'api/aggregator/accountpayablebalance'

export const accountPayableBalanceRequests = {
  listAccountPayableBalances: (options?: ListAccountPayableBalancesOptions) => {
    return bidlClient
      .post(`${accountPayableBaseUrl}/v1/GetAccountPayableBalances`, {
        json: options,
      })
      .json<AccountPayableBalanceResponse>()
  },
}
