import { Session } from 'next-auth'
import { createContext, useContext } from 'react'

export interface AuthProvidersProps {
  session?: Session
  cookies?: { [key: string]: string } // @deprecated, delete soon
  authContext: AuthContextValue
}

export interface AuthContextValue {
  initToken: string // @deprecated, delete soon
  slug: string
  user: Me
  setUser: (u: Me) => void
}

export const AuthContext = createContext<AuthContextValue | null>(null)

export const useAuthContext = (): AuthContextValue => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuthContext must be used within an AuthProvider')
  }
  return context
}
