import { useCallback, useMemo, ReactNode } from 'react'
import {
  Box,
  Button,
  Collapse,
  Divider,
  Stack,
  Text,
  useDisclosure,
  useBreakpoint,
} from 'src/components/designsystem'
import DisplayConfig, { DisplayConfigItem } from 'src/components/designsystem/display-config'
import { showDivider } from 'src/components/resource'
import { trackEvent } from 'src/utils/analytics'
import { labelToTestId } from 'src/utils/string/label-to-test-id'

export interface CollapsableDetailTableProps<Item> {
  rowItems: Item[]
  displayConfig: DisplayConfigItem<Item>[]
  analyticsCategory: string
  analyticsAction: string
  rowItemTitle?: string
  previewAmount?: number
  setItem?: (item: Item) => void
  detailModal?: (item: Item, onClose: () => void) => ReactNode
  shouldShowViewButton?: boolean | ((item: Item) => boolean)
}

export function CollapsableDetailTable<Item>({
  rowItems,
  displayConfig,
  analyticsCategory,
  analyticsAction,
  rowItemTitle = 'Items',
  previewAmount = 5,
  setItem,
  shouldShowViewButton,
}: CollapsableDetailTableProps<Item>) {
  const { isOpen, onToggle } = useDisclosure()

  const collapsedRowItems = [...rowItems]
  const previewRowItems = collapsedRowItems.splice(0, previewAmount)

  const hasSetItem = !!setItem && !!shouldShowViewButton

  const desktopTemplateColumns = useMemo(
    () => generateTemplateColumns(displayConfig, hasSetItem),
    [displayConfig, hasSetItem]
  )

  return (
    <Box width="100%">
      <DesktopTableHeader
        displayConfig={displayConfig}
        desktopTemplateColumns={desktopTemplateColumns}
      />

      <Box
        borderRadius=" 0 0 0.75rem 0.75rem"
        width="100%"
        border={['none', null, null, '1px solid']}
        // Workaround. chakra's being weird and won't apply a non-responsive version of these props
        borderTop={['none', 'none', 'none', 'none']}
        borderColor={['gray.300', 'gray.300', 'gray.300', 'gray.300']}
      >
        <TableRows
          rowItems={previewRowItems}
          displayConfig={displayConfig}
          setItem={setItem}
          shouldShowViewButton={shouldShowViewButton}
          desktopTemplateColumns={desktopTemplateColumns}
        />

        <Collapse in={isOpen} unmountOnExit>
          <Divider display={['none', null, null, 'block']} />
          <Box w="100%" mt={[2, null, null, 0]} />

          <TableRows
            rowItems={collapsedRowItems}
            displayConfig={displayConfig}
            setItem={setItem}
            shouldShowViewButton={shouldShowViewButton}
            desktopTemplateColumns={desktopTemplateColumns}
          />
        </Collapse>
      </Box>

      {rowItems.length > previewAmount && (
        <ShowHideButton
          {...{
            isOpen,
            rowItemTitle,
            analyticsCategory,
            analyticsAction,
            onToggle,
            rowItems,
            previewAmount,
          }}
        />
      )}
    </Box>
  )
}

function generateTemplateColumns<Item>(
  displayConfig: DisplayConfigItem<Item>[],
  hasSetItem: boolean
): string {
  const templateColumns = displayConfig.map((item) => item.columnWidth ?? '1fr').join(' ')
  return hasSetItem ? `${templateColumns} 78px` : templateColumns
}

interface DesktopTableHeaderProps<Item> {
  displayConfig: DisplayConfigItem<Item>[]
  desktopTemplateColumns: string
}

function DesktopTableHeader<Item>({
  displayConfig,
  desktopTemplateColumns,
}: DesktopTableHeaderProps<Item>) {
  return (
    <Box
      bg="gray.50"
      borderRadius="0.75rem 0.75rem 0 0"
      border="1px solid"
      borderColor="gray.300"
      px="4"
      py="3"
      width="100%"
      display={['none', null, null, 'block']}
    >
      <DisplayConfig.HeaderRow
        columnGap={2}
        fontSize="xs"
        fontWeight={700}
        templateColumns={desktopTemplateColumns}
        textAlign="left"
        w="100%"
      >
        {displayConfig.map((config) => (
          <Text
            key={config.label}
            textStyle="preTitle"
            textAlign={config.textAlign}
            pr={[0, null, null, 2, 8]}
            data-testid={`column-header-${labelToTestId(config.label)}`}
          >
            {config.label}
          </Text>
        ))}
      </DisplayConfig.HeaderRow>
    </Box>
  )
}

interface TableRowsProps<Item> {
  rowItems: Item[]
  displayConfig: DisplayConfigItem<Item>[]
  setItem: (item: Item) => void
  shouldShowViewButton?: boolean | ((item: Item) => boolean)
  desktopTemplateColumns: string
}

function TableRows<Item>({
  rowItems,
  displayConfig,
  setItem,
  shouldShowViewButton,
  desktopTemplateColumns,
}: TableRowsProps<Item>) {
  const { breakpoint } = useBreakpoint()
  const hasSetItem = !!setItem
  const configItemLength = displayConfig.length

  const showDividerFn = useCallback(
    ({ index }) => showDivider({ index, breakpoint, configItemLength }),
    [breakpoint, configItemLength]
  )

  return (
    <Stack spacing={[2, null, null, 0]}>
      {rowItems.map((rowItem, rowItemIndex) => {
        const showViewButton = deriveShowViewButton({ hasSetItem, shouldShowViewButton, rowItem })
        const isNotLast = rowItemIndex < rowItems.length - 1

        return (
          <Box
            key={`rowItem-${rowItemIndex}`}
            py={[4, null, null, 0]}
            // This is the tablet/mobile card full border
            border={['1px solid', null, null, 'none']}
            // This is the desktop table row bottom border
            borderBottom={isNotLast && [null, null, null, '1px solid']}
            // Workaround, again
            borderColor={['gray.300', 'gray.300', 'gray.300', 'gray.300']}
            borderRadius={['lg', null, null, 0]}
          >
            <DisplayConfig.BodyRow
              w="100%"
              px={4}
              templateColumns={['1fr', null, '1fr 1fr', desktopTemplateColumns]}
              rowGap={[1, null, null, 2]}
              columnGap={['4%', null, null, 2]}
              alignItems="center"
              alignContent="center"
            >
              <DisplayConfig.RowRenderer
                displayConfig={displayConfig}
                item={rowItem}
                showDivider={showDividerFn}
              />

              {showViewButton && <DesktopViewButton onClick={() => setItem(rowItem)} />}
            </DisplayConfig.BodyRow>
            {showViewButton && <MobileViewButton onClick={() => setItem(rowItem)} />}
          </Box>
        )
      })}
    </Stack>
  )
}

function deriveShowViewButton({ hasSetItem, shouldShowViewButton, rowItem }): boolean {
  if (!hasSetItem) return false
  if (typeof shouldShowViewButton === 'function') return shouldShowViewButton(rowItem)
  if (typeof shouldShowViewButton === 'boolean') return shouldShowViewButton

  // If unset, default value is `true`
  return typeof shouldShowViewButton === 'undefined'
}

function DesktopViewButton({ onClick }) {
  return (
    <Button
      variant="ghost"
      size="sm"
      display={['none', null, null, 'initial']}
      onClick={onClick}
      data-testid="view-button"
    >
      View
    </Button>
  )
}

function MobileViewButton({ onClick }) {
  return (
    <Box
      mt={[4, null, null, 0]}
      px={4}
      textAlign={['center', null, 'right', null]}
      display={[null, null, null, 'none']}
    >
      <Button
        size="sm"
        variant="outline"
        w="100px"
        onClick={onClick}
        data-testid="view-details-button"
      >
        View Details
      </Button>
    </Box>
  )
}

interface ShowHideButtonProps<Item> {
  isOpen: boolean
  rowItemTitle?: string
  analyticsCategory: string
  analyticsAction: string
  onToggle: () => void
  rowItems: Item[]
  previewAmount?: number
}

function ShowHideButton<Item>({
  isOpen,
  rowItemTitle,
  analyticsCategory,
  analyticsAction,
  onToggle,
  rowItems,
  previewAmount,
}: ShowHideButtonProps<Item>) {
  return (
    <Box fontWeight={700} pt={4} textAlign="center" width="100%">
      <Button
        aria-label={isOpen ? `Hide ${rowItemTitle}` : `Show All ${rowItemTitle}`}
        variant="ghost"
        onClick={() => {
          trackEvent(analyticsCategory, `${analyticsAction} ${isOpen ? 'Collapse' : 'Expand'}`)
          onToggle()
        }}
      >
        {isOpen
          ? `Hide (${rowItems.length - previewAmount}) ${rowItemTitle}`
          : `Show All (${rowItems.length}) ${rowItemTitle}`}
      </Button>
    </Box>
  )
}
