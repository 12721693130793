import { Fragment } from 'react'
import { Theme } from 'src/themes'
import { Grid, Box, Text } from '..'

const Measurements = () => {
  return (
    <Grid templateColumns="auto auto auto 1fr" gap={4}>
      {Object.keys(Theme.space)
        .map((s) => [s, Theme.space[s]])
        .map((s, i) => (
          <Fragment key={`measurement-${i}`}>
            <Text>{s[0]}</Text>
            <Text>
              {s[0] !== '0' && s[0] !== 'px' ? `${parseFloat(s[1].slice(0, -3)) * 16}px` : ''}
            </Text>
            <Text>{s[1]}</Text>
            <Box h="100%" w={s[1]} bgColor="blue.200" />
          </Fragment>
        ))}
    </Grid>
  )
}

export default Measurements
