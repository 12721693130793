import { Text, Box, Button } from 'src/components/designsystem'
interface BackToTopFooterProps {
  scrollToTop: () => void
}

export function BackToTopFooter({ scrollToTop }: Readonly<BackToTopFooterProps>) {
  return (
    <Box
      data-testid="notification-list-inner-box"
      width="100%"
      pb={4}
      pt={2}
      position="fixed"
      bottom={0}
      display="block"
      background="white"
      borderTopWidth={1}
    >
      <Text fontWeight={600} textAlign="center">
        <Button
          onClick={scrollToTop}
          color="blue.400"
          backgroundColor="transparent"
          border="none"
          variant="unstyled"
        >
          Back to top
        </Button>
      </Text>
    </Box>
  )
}
