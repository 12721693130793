import {
  Box,
  Tooltip as ChakraTooltip,
  useDisclosure,
  type BoxProps,
  type TooltipProps as ChakraTooltipProps,
} from '@chakra-ui/react'
import { memo } from 'react'

type TooltipProps = Readonly<
  ChakraTooltipProps & {
    innerProps?: BoxProps
  }
>

const MobileTooltip = ({ children, innerProps = {}, ...props }: TooltipProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  if (props?.isDisabled) {
    return children
  }

  return (
    <ChakraTooltip isOpen={isOpen} {...props}>
      <Box onClick={onOpen} onMouseEnter={onOpen} onMouseLeave={onClose} {...innerProps}>
        {children}
      </Box>
    </ChakraTooltip>
  )
}

export default memo(MobileTooltip)
