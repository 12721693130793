/* istanbul ignore file */
import { Capacitor } from '@capacitor/core'

export const corePluginWrapper = {
  getPlatform,
  isNativePlatform,
}

// NOTE: These must be explicit functions or emulateNativeApp will not work.
function getPlatform(): string {
  return Capacitor.getPlatform()
}

function isNativePlatform(): boolean {
  return Capacitor.isNativePlatform()
}
